import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import img1 from "../../../images/technicalSolutions/eqhv_1.png"
import img2 from "../../../images/technicalSolutions/eqhv_2.png"
import img3 from "../../../images/technicalSolutions/eqhv_3.png"
import img4 from "../../../images/technicalSolutions/eqhv_4.png"

const functions = [
  { id: 1, title: "Возможность заполнения, редактирования и отображения данных по первичному оборудованию в удобных для чтения карточках" },
  { id: 2, title: "Создание структуры подстанций (распределительных устройств, присоединений, силовых трансформаторов и наличия коммутационного оборудования)" },
  { id: 3, title: "Возможность ведения типового оборудования и ассоциации с ним заведенного в базу данных первичного оборудования, что позволяет ускорить процесс заполнения базы данных и быстрого редактирования общей для объектов информации" },
  { id: 4, title: "Возможность вывода сводной информации в табличном виде для наглядности" },
  { id: 5, title: "Описание структуры сети: заведение ЛЭП с указанием подключенных к ним подстанций" }
];

const images = [
  {
    img: img1,
    type: "image",
  },
  {
    type: "image",
    img: img2,
  },
  {
    type: "image",
    img: img3,
  },
  {
    type: "image",
    img: img4,
  },
];

function Eqhv() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    route={window.location.pathname}
    title="Реестр ЛЭП и электросетевого оборудования напряжением 110—750 кВ"
    description="Решение предназначено для создания иерархической базы объектов,
    содержащей электросетевое оборудования напряжением 110—750 кВ. С её
    помощью можно описывать структуры различных энергообъектов и
    отображать их в удобном для просмотра виде"
    images={images}
  />
  );
}

export default Eqhv;
