import React from "react";
import "./Header.scss";
import logoNPT from "../../images/logoNPT.svg";
import {
  AppRoute,
  implementationExamples,
  pluginsLinks,
  technicalSolutionsLinks,
} from "../../constants";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function Header() {
  return (
    // <header className="header">
    //   <Navbar
    //     collapseOnSelect
    //     expand="lg"
    //     className="bg-body-black header__container"
    //     data-bs-theme="dark"
    //   >
    //     <Navbar.Brand

    //       href="/"
    //       style={{ margin: "0 40px 0 0", padding: 0, width: "10%" }}
    //     >
    //       <img
    //         style={{ width: "100%", minWidth: "50px" }}
    //         src={logoNPT}
    //         alt="логотип NPT платформ"
    //       />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav" className="nav">
    //       <Nav className="me-auto nav-item_color">
    //         <NavDropdown
    //           title="Стандартные расширения"
    //           id="basic-nav-dropdown"
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           {pluginsLinks.map((i) => (
    //             <NavDropdown.Item href={i.link} key={i.title} className="">
    //               {i.title}
    //             </NavDropdown.Item>
    //           ))}
    //         </NavDropdown>

    //         <NavDropdown
    //           title="Технические решения"
    //           id="basic-nav-dropdown"
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           {technicalSolutionsLinks.map((i) => (
    //             <NavDropdown.Item
    //               //href={i.link}
    //               key={i.title}
    //               className="nav-item"
    //             >
    //               <Link className="nav-links" to={i.link}>
    //               {i.title}
    //               </Link>

    //             </NavDropdown.Item>
    //           ))}
    //         </NavDropdown>
    //         <NavDropdown
    //           title="Примеры внедрения"
    //           id="basic-nav-dropdown"
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           {implementationExamples.map((i) => (
    //             <NavDropdown.Item
    //               href={i.link}
    //               key={i.title}
    //               className="nav-item"
    //             >
    //               {i.title}
    //             </NavDropdown.Item>
    //           ))}
    //         </NavDropdown>
    //         {/* <Nav.Link
    //           href={AppRoute.Partners}
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           Партнерская сеть
    //         </Nav.Link> */}
    //         <Nav.Link

    //           href={AppRoute.News}
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           Новости
    //         </Nav.Link>
    //       </Nav>
    //       {/* <Nav>
    //         <Nav.Link href={AppRoute.DemoForm} className="m-0 nav-item_color">
    //           Получить демо-версию
    //         </Nav.Link>
    //       </Nav> */}
    //     </Navbar.Collapse>
    //   </Navbar>
    // </header>

    <header className="header">
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-black header__container"
        data-bs-theme="dark"
      >
        <LinkContainer to="/" style={{ margin: "0 40px 0 0", padding: 0, width: "10%" }}>
          <Navbar.Brand
          >
            <img
              style={{ width: "100%", minWidth: "50px" }}
              src={logoNPT}
              alt="логотип NPT платформ"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="nav">
          <Nav className="me-auto nav-item_color">
            <NavDropdown
              title="Стандартные расширения"
              id="basic-nav-dropdown"
              style={{
                "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
                fontSize: "14px",
              }}
            >
              {pluginsLinks.map((i) => (
                <LinkContainer to={i.link} key={i.title}>
                  <NavDropdown.Item>{i.title}</NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>

            <NavDropdown
              title="Технические решения"
              id="basic-nav-dropdown"
              style={{
                "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
                fontSize: "14px",
              }}
            >
              {technicalSolutionsLinks.map((i) => (
                <LinkContainer to={i.link} key={i.title}>
                  <NavDropdown.Item>{i.title}</NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>

            <NavDropdown
              title="Примеры внедрения"
              id="basic-nav-dropdown"
              style={{
                "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
                fontSize: "14px",
              }}
            >
              {implementationExamples.map((i) => (
                <LinkContainer to={i.link} key={i.title}>
                  <NavDropdown.Item>{i.title}</NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>

                    {/* <Nav.Link
    //           href={AppRoute.Partners}
    //           style={{
    //             "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
    //             fontSize: "14px",
    //           }}
    //         >
    //           Партнерская сеть
    //         </Nav.Link> */}
            <LinkContainer to={AppRoute.News}                 style={{
                  "--bs-nav-link-color": "RGBA(255,255,255,0.75)",
                  fontSize: "14px",
                }}>
              <Nav.Link
              >
                Новости
              </Nav.Link>
            </LinkContainer>

                   {/* <Nav>
    //         <Nav.Link href={AppRoute.DemoForm} className="m-0 nav-item_color">
    //           Получить демо-версию
    //         </Nav.Link>
    //       </Nav> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;

//   <header className="header">
//   <Navbar collapseOnSelect expand="lg" className="bg-body-black header__container" data-bs-theme="dark">
//     <LinkContainer to="/">
//       <Navbar.Brand style={{ margin: "0 40px 0 0", padding: 0, width: "10%" }}>
//         <img style={{ width: "100%", minWidth: "50px" }} src={logoNPT} alt="логотип NPT платформ" />
//       </Navbar.Brand>
//     </LinkContainer>
//     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//     <Navbar.Collapse id="responsive-navbar-nav" className="nav">
//       <Nav className="me-auto nav-item_color">
//         <NavDropdown title="Стандартные расширения" id="basic-nav-dropdown" style={{ "--bs-nav-link-color": "RGBA(255,255,255,0.75)", fontSize: "14px" }}>
//           {pluginsLinks.map((i) => (
//             <LinkContainer to={i.link} key={i.title}>
//               <NavDropdown.Item>{i.title}</NavDropdown.Item>
//             </LinkContainer>
//           ))}
//         </NavDropdown>

//         <NavDropdown title="Технические решения" id="basic-nav-dropdown" style={{ "--bs-nav-link-color": "RGBA(255,255,255,0.75)", fontSize: "14px" }}>
//           {technicalSolutionsLinks.map((i) => (
//             <LinkContainer to={i.link} key={i.title}>
//               <NavDropdown.Item>{i.title}</NavDropdown.Item>
//             </LinkContainer>
//           ))}
//         </NavDropdown>

//         <NavDropdown title="Примеры внедрения" id="basic-nav-dropdown" style={{ "--bs-nav-link-color": "RGBA(255,255,255,0.75)", fontSize: "14px" }}>
//           {implementationExamples.map((i) => (
//             <LinkContainer to={i.link} key={i.title}>
//               <NavDropdown.Item>{i.title}</NavDropdown.Item>
//             </LinkContainer>
//           ))}
//         </NavDropdown>

//         <LinkContainer to={AppRoute.News}>
//           <Nav.Link style={{ "--bs-nav-link-color": "RGBA(255,255,255,0.75)", fontSize: "14px" }}>Новости</Nav.Link>
//         </LinkContainer>
//       </Nav>
//     </Navbar.Collapse>
//   </Navbar>
// </header>
