import React from "react";
import title from "../../../images/rza/title.png";
import Parameters from "../../../components/Parameters/Parameters";
import image1 from "../../../images/pdf/img/rza0.png";
import image2 from "../../../images/pdf/img/rza1.png";
import image3 from "../../../images/pdf/img/rza2.png";
import image4 from "../../../images/pdf/img/rza3.png";
import Layout from "../../../components/Layout/Layout";

const descriptionLi = [
  "Сбор данных РАС на технологический сервер НПС, конвертация собранных данных в формат COMTRADE",
  "Ретрансляция информации на уровень РНУ в формате COMTRADE",
  "Ретрансляция информации на уровень ОСТ (МН) в формате COMTRADE",
  "Ретрансляция информации в ДЦ ПАО «Транснефть» (ДЦК ВЭС) в формате COMTRADE",
];

function RZA({ openPopup }) {
  const images = [image1, image2, image3, image4];
  return (
    <Layout>
      <main className="main">
        <Parameters
          title={`Сбор и анализ аварийной информации РЗА`}
          image={title}
          images={images}
          openPopup={openPopup}
          popupHeight={true}
          descriptionTitle="Основные функции:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default RZA;
