import React from "react";

const description = ['Первая редакция проекта национального стандарта ГОСТ Р «Единая энергетическая система и изолированно работающие энергосистемы. Информационная модель электроэнергетики. Профиль информационной модели устройств релейной защиты и автоматики», разработанная Департаментом релейной защиты, метрологии и автоматизированных систем управления технологическими процессами ПАО «Россети» и ГК «ЭнергопромАвтоматизация», представлена для публичного обсуждения в Технический комитет по стандартизации «ЭЛЕКТРОЭНЕРГЕТИКА» (ТК 016).', 
        'Проект стандарта разработан по плану работ подкомитета ПК-7 «Интеллектуальные технологии в электроэнергетике» и предназначен для обмена информацией по устройствам РЗА в формате общей информационной модели CIM между субъектами электроэнергетики, потребителями электрической энергии, проектными и научными организациями.  CIM модель обеспечивает однозначную интерпретацию передаваемых и получаемых данных всеми участниками информационного обмена.',
        ];

function Article5() {
  return (
  <div className="mt-4 mb-4">
    {description.map(p => (
      <p key={p}>{p}</p>
    ))}
  </div>
  );
}
export default Article5;
