import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import img from '../../../images/technicalSolutions/eqgen_1.png'
import img2 from "../../../images/technicalSolutions/eqgen_2.png"
import img3 from "../../../images/technicalSolutions/eqgen_3.png"
import img4 from "../../../images/technicalSolutions/eqgen_4.png"

const functions = [
  { id: 1, title: "Возможность заполнения, редактирования и отображения данных по генерирующему оборудованию в удобных для чтения карточках" },
  { id: 2, title: "Описание структуры генерирующего предприятия состоящего из энергоблоков и содержащегося в нем оборудования: синхронных и асинхронных машин, их первичных двигателей-турбин, а также связанных с ними котлов" },
  { id: 3, title: "Возможность ведения типового оборудования и ассоциации с ним заведенного в базу данных оборудования, что позволяет ускорить процесс заполнения базы данных и быстрого редактирования общей для объектов информации" },
  { id: 4, title: "Возможность введения у типового оборудования различных технических характеристик, например моментно-скоростных" },
  { id: 5, title: "Возможность вывода сводной информации в табличном виде для наглядности" }
];

const images = [
  {
    img: img,
    type: "image",
  },
  {
    img: img2,
    type: "image",
  },
  {
    img: img3,
    type: "image",
  },
  {
    img: img4,
    type: "image",
  },
];

function Eqgen() {
  return (
    <TechnicalSolutionsContent
    route={window.location.pathname}
    functions={functions}
    title="Реестр генерирующего оборудования"
    images={images}
    description="Решение предназначено для создания базы генерирующего оборудования и его отображения в иерархическом дереве предприятия"
  />
  );
}

export default Eqgen;