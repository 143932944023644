import React from "react";
import title from "../../../images/Transneft/title.png";
import Parameters from "../../../components/Parameters/Parameters";
import Layout from "../../../components/Layout/Layout";
// import image1 from "../../..//images/Rosseti/RossetiTransneft_page-0001.jpg";
// import image2 from "../../..//images/Rosseti/RossetiTransneft_page-0002.jpg";
// import image3 from "../../..//images/Rosseti/RossetiTransneft_page-0003.jpg";
// import image4 from "../../..//images/Rosseti/RossetiTransneft_page-0004.jpg";
// import image5 from "../../..//images/Rosseti/RossetiTransneft_page-0005.jpg";
// import image6 from "../../..//images/Rosseti/RossetiTransneft_page-0006.jpg";
// import image7 from "../../..//images/Rosseti/RossetiTransneft_page-0007.jpg";
// import image8 from "../../..//images/Rosseti/RossetiTransneft_page-0008.jpg";

import image1 from "../../../images/pdf/img/transneft0.png";
import image2 from "../../../images/pdf/img/transneft1.png";
import image3 from "../../../images/pdf/img/transneft2.png";
import image4 from "../../../images/pdf/img/transneft3.png";
import image5 from "../../../images/pdf/img/transneft4.png";
import image6 from "../../../images/pdf/img/transneft5.png";
import image7 from "../../../images/pdf/img/transneft6.png";
import image8 from "../../../images/pdf/img/transneft7.png";

const descriptionLi = [
  "Формирование и ведение реестра энергетического оборудования",
  "Формирование, ведение и контроль диагностики энергетического оборудования",
  "Организация и контроль устранения замечаний, дефектов и нарушений, выявленных по результатам диагностики энергетического оборудования",
  "Формирование отчетной документации по подготовке и выполнению работ по диагностике, анализу замечаний, дефектов и нарушений",
];

function Transneft({ openPopup }) {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
  ];
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="КИАС ДЭО (ПАО «Транснефть»)"
          image={title}
          openPopup={openPopup}
          images={images}
          descriptionTitle="Основные автоматизируемые бизнес-процессы:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default Transneft;
