import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import img1 from '../../../images/technicalSolutions/eqmv-1.png'
import img2 from '../../../images/technicalSolutions/eqmv-2.png'
import img3 from '../../../images/technicalSolutions/eqmv-3.png'

const functions = [
  { id: 1, title: "Возможность заполнения, редактирования и отображения данных по первичному оборудованию в удобных для чтения карточках" },
  { id: 2, title: "Описание структур подстанции низкого напряжения (собственные нужды, двигатели и т.д.)" },
  { id: 3, title: "Возможность ведения типового оборудования и ассоциации с ним заведенного в базу данных первичного оборудования, что позволяет ускорить процесс заполнения базы данных и быстрого редактирования общей для объектов информации" },
  { id: 4, title: "Возможность вывода сводной информации в табличном виде для наглядности" },
  { id: 5, title: "Ведение и учет линий, фидеров и связанного с ними оборудования, соединяющего распределительные устройства питающей подстанции с распределительными устройствами трансформаторных подстанций потребителей" },
  { id: 6, title: "Построение структуры реестра в соответствии с моделью ГОСТ 58651 упрощает передачу занесенных данных при необходимости интеграции со сторонними приложениями" }
];

const images = [
  {
    type: "image",
    img: img1,
  },
  {
    type: "image",
    img: img2,
  },
  {
    type: "image",
    img: img3,
  },
];

function Eqmv() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    route={window.location.pathname}
    images={images}
    title="Реестр ЛЭП и электросетевого оборудования напряжением 0,4—35 кВ"
    description="Решение предназначено для создания иерархической базы объектов в виде дерева, содержащее электросетевое оборудования напряжением 0,4—35 кВ. С её помощью можно описывать структуры различных энергообъектов и отображать их в удобном для просмотра виде"
  />
  );
}

export default Eqmv;
