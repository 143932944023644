import { AppRoute } from "../../../constants";
import scadaImg from "../../../images/standardExtensions/scada-plugin.png";
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";

const descriptionLi = [
  "сбор сигналов самодиагностики с микропроцессорных устройств РЗА, АСУ ТП, СИ для выполнения оценки состояния устройств и осуществлении перехода на обслуживание по состоянию",
  "сбор осциллограмм с устройств РЗА для анализа правильности работы устройств РЗА",
];

const descriptionLinks = [
  { link: AppRoute.Dm, title: "Витрина данных реального времени" },
  { link: AppRoute.Rpa, title: "Техническое решение по АСМ РЗА" },
  {
    link: AppRoute.Se,
    title:
      "Техническое решение по оцениванию состояния установившегося режима сети",
  },
];

const description = [
  "Расширение scada-plugin предназначено для интеграции в автоматизированную систему на базе NPT Platform данных реального времени, поступающих по промышленным протоколам автоматизации МЭК 61850, МЭК 60870-5-104, Modbus и др.",
];

function ScadaPlugin({openPopup}) {
  const route = window.location.pathname;
  return (
    <StandardExtensionsDescription
    route={route} 
    title={'scada-plugin'} 
    titleRu={'Отображение данных реального времени'}
    description={description}
    list={descriptionLinks}
    descriptionLi={descriptionLi}
    listTitle={'Технические решения с применением  scada-plugin:'}
    image={scadaImg}
    descriptionLiTitle={'Примеры применения scada-plugin:'}
    openPopup={openPopup}
  />
  );
}

export default ScadaPlugin;
