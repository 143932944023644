import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ModalContent.scss";

export default function ModalContent({ onClose, modalData }) {
  const { modalName, top, left, right } = modalData;
  useEffect(() => {
    function handelEscape(evt) {
      if (evt.key === "Escape") {
        onClose();
      }
    }

    function handleClosePopups(evt) {
      if (!evt.target.classList.contains("open")) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClosePopups);
    document.addEventListener("keydown", handelEscape);

    return () => {
      document.removeEventListener("keydown", handelEscape);
      document.removeEventListener("mousedown", handleClosePopups);
    };
  }, [onClose]);

  return (
    <div
      className="modal-content dropdown-menu open"
      data-bs-theme="dark"
      style={{ top: top, left: left, right: right }}
    >
      <button
        onClick={onClose}
        className="modal__close-icon"
        aria-label="закрыть"
        type="button"
      />
      {modalName === "Uml" ? (
        <ul className="mb-4 description__list" style={{color: 'var(--white)'}}>
          <li className="mb-1 description__circle-li mb-3" >
            <span style={{ fontWeight: 500 }}>
              UML (англ. Unified Modeling Language — унифицированный язык
              моделирования)
            </span>{" "}
            — язык графического описания для объектного моделирования в области
            разработки программного обеспечения, для моделирования
            бизнес-процессов, системного проектирования и отображения
            организационных структур
          </li>
          <li className="mb-1 description__circle-li">
            <span style={{ fontWeight: 500 }}>UML-диаграмма</span> — это схема,
            нарисованная с применением символов UML. Она может содержать
            множество элементов и соединений между ними. Полное описание профиля
            информационной модели может состоять из нескольких UML-диаграмм,
            связанных или не связанных между собой
          </li>
        </ul>
      ) : (
        modalName.map((i) => (
          <Link to={i.link} key={i.title} className="dropdown-item open">
            {i.title}
          </Link>
        ))
      )}
    </div>
  );
}
