import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";

const list = [
  {
    id: 1,
    title: "Определение места и сроков оказания Услуг",
  },
  {
    id: 2,
    title: "Определение цели и результатов оказания Услуг",
  },
  {
    id: 3,
    title: "Определение требований к качеству Услуг",
  },
  {
    id: 4,
    title: "Определение зоны охвата и состава объекта Услуг",
  },
  {
    id: 5,
    title: "Определение основных стандартов оказания Услуг",
  },
  {
    id: 6,
    title: "Определение ограничений в части оказания Услуг",
  },
  {
    id: 7,
    title:
      "Определение требований к организации удалённого доступа	 к объекту Услуг",
  },
  {
    id: 8,
    title: "Определение перечня и порядка оказания Услуг",
  },
  {
    id: 9,
    title:
      "Разработка технического задания на оказания Услуги и/или регламента оказания услуг",
  },
  {
    id: 10,
    title: "Оказание Услуг",
  },
  {
    id: 11,
    title:
      "Формирование отчетности с демонстрацией количественных показателей и расчетом показателей качества оказания Услуги",
  },
];

function TechnicalSupport() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4">Техническая поддержка</h1>
        <div className="align-start">
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link className="text-links" to={AppRoute.Partners}>
              компаний партнеров,
            </Link>{" "}
            а также богатый опыт внедрений и оказания технической поддержки
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально эффективно и качественно осуществлять сопровождение
            автоматизированных систем на базе NPT Platform.
          </p> */}
                    <p>
            Компетенция специалистов нашей компании и компаний партнеров,
            а также богатый опыт внедрений и оказания технической поддержки
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально эффективно и качественно осуществлять сопровождение
            автоматизированных систем на базе NPT Platform.
          </p>
          <h5 className="description__title">
            Техническая поддержка (далее – Услуга) предоставляется по договору
            на оказание Услуг, и включает в себя следующие этапы:
          </h5>
          <ul className="description__list">
            {list.map((i) => (
              <li className="mb-3 description__number-li" key={i.id}>
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default TechnicalSupport;
