import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { news } from "../../constants";
import "./News.scss";

function News() {
  return (
    <Layout>
      <main className="main">
        <h2>Новости</h2>
        <div>
          {news.map((i) => (
            <Link
              to={`/news/${i.id}`}
              className="row mt-4 link news__item"
              key={i.id}
            >
              <p className="news__item-date">{i.data}</p>
              {/* <img className="col news__item-image" src={i.image} alt={i.alt}/> */}
              {/* <h6 className="news__item-description">{i.title}</h6> */}
              <div className="col news__item-text">
                <img
                  className="col news__item-image"
                  src={i.image}
                  alt={i.alt}
                />
                <h6 className="news__item-description">{i.title}</h6>
              </div>
              {/* <div className="col news__item-text">
            <h6 className="news__item-description">{i.title}</h6>
            <p style={{minWidth: '145px'}} className="news__item-date" >{i.data}</p>
            </div> */}
            </Link>
          ))}
        </div>
      </main>
    </Layout>
  );
}

export default News;
