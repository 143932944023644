import React from "react";
import image from "../../images/News/1.webp";
import image2 from "../../images/News/2.webp";
const description = [
  "В рамках НИОКР, выполненного по заказу ПАО «ФСК ЕЭС», консорциумом в составе: ГК «ЭнергопромАвтоматизация», АО «НТЦ ФСК ЕЭС» и ООО «Релематика», была разработана корпоративная система автоматической диагностики и повышения эффективности обслуживания устройств РЗА, АСУ ТП и средств измерений ПС – ПТК «Эксплуатация».",
  "Целью создания ПТК «Эксплуатация» является цифровизация жизненного цикла работы устройств РЗА, АСУ ТП и средств измерений. Внедрение корпоративной системы обеспечивает автоматизацию труда персонала, эксплуатирующего устройства и системы РЗА, АСУТП и СИ, а также формирование доступного персоналу ПС, ПМЭС, МЭС и ИА ФСК ЕЭС централизованного электронного хранилища информации об оборудовании, обеспечивающего последующее внедрение механизмов автоматического анализа его функционирования. На подстанциях пилотной зоны, где установлена система автоматической диагностики, оценка состояния устройств осуществляется в автоматическом режиме, что позволяет при условии изменения регламентов перейти на обслуживание устройств по состоянию.",
  "Повышение эффективности достигается за счет того, что распределенная база данных ПТК «Эксплуатация» хранит и накапливает в систематизированном виде всю информацию по оборудованию РЗА, АСУ ТП и СИ (от этапа проектирования до демонтажа) и является цифровым двойником для такого оборудования. Это позволяет исключить влияние человеческого фактора при принятии решений по обслуживанию устройств. Накопленная информация является идеальным источником для последующего анализа с использованием методов анализа больших данных, искусственного интеллекта и других современных математических методов.",
  "В рамках ПТК «Эксплуатация» реализовано три вида мониторинга работы оборудования: непрерывный, периодический и ситуационный.",
];

const list = [
  "по сигналам самодиагностики в процессе непрерывного мониторинга однозначно определить возникающие неисправности и нарушения в работе",
  "по диагностическим сигналам устройств осуществлять их анализ и классификацию, а также формировать события и оповещения персонала в зависимости от категории диагностических сигналов",
  "по измерительным сигналам от устройств РЗА и АСУ ТП определить возникающие отклонения",
  "выполнить расчет показателей, таких как индекс готовности субъектов электроэнергетики к работе в отопительный сезон, индекс технического состояния оборудования, ключевых показателей эффективности и других статистических показателей",
  "на основании всей доступной информации сделать вывод о текущем состоянии устройства и необходимости его технического обслуживания",
  "с помощью ситуационного мониторинга могут быть выявлены неисправности, обнаружение которых невозможно в нормальном режиме работы",
];

const list2 = [
  "Фиксирует событие срабатывания или пуска и максимально быстро информирует о нем",
  "Определяет границы аварийного процесса",
  "Получает осциллограммы, относящиеся к аварийному процессу",
  "Получает осциллограммы, относящиеся к аварийному процессу.",
  "Выполняет анализ правильности работы РЗА",
  "Предоставляет пользователям на всех уровнях системы информацию о происшедшем событии в виде отчетов",
];

function Article2() {
  return (
    <div className="mt-4 mb-4 d-flex flex-column align-items-center">
      {description.map((p) => (
        <p key={p}>{p}</p>
      ))}
      <img
        src={image}
        alt="Мониторинг работы устройств"
        className="m-4"
        style={{ width: "fit-content" }}
      />
      <p>
        Используя комплексный подход с тремя видами мониторинга, корпоративная
        система позволяет решить следующие задачи:
      </p>

      <ul className="article__list w-100">
        {list.map((li) => (
          <li key={li}>{li}</li>
        ))}
      </ul>
      <p>
        В рамках ситуационного мониторинга, при срабатываниях и пусках устройств
        РЗА, ПТК «Эксплуатация» выполняет следующие действия:
      </p>
      <ul className="w-100">
        {list2.map((li) => (
          <li className="article__list_sec" key={li}>
            {li}
          </li>
        ))}
      </ul>
      <figure className="d-flex flex-column align-items-center">
        <img
          src={image2}
          className="w-100 m-4"
          alt="Структура ПТК «Эксплуатация»"
        />
        <figcaption>Структура ПТК «Эксплуатация»</figcaption>
      </figure>
      <p>
        Таким образом, с разработкой корпоративной системы ПТК «Эксплуатация»
        был сделан важный шаг к переходу на обслуживание оборудования по
        состоянию. Функционал корпоративной системы позволяет выполнять
        типизацию единиц оборудования, накапливать статистическую информацию об
        оборудовании и в случае необходимости формировать решение о проведении
        технического обслуживания за счет применения аналитической обработки
        данных и комплексного подхода к мониторингу работы оборудования.
      </p>
    </div>
  );
}
export default Article2;
