import React, { useState } from "react";
import Slider from "react-slick";
import "./TechnicalSolutionSlider.scss";
import { VideoSide } from "../Popup/VideoSide";

function TechnicalSolutionSlider({images,centerHeight, video}) {
  const settings = {
    dots: true,
    infinite: video ? false : true,
  };

  const [isPlayButton, setIsPlayButton] = useState(true);
  const [active, setActive] = useState(0);

  return(
        <div className="mb-5 technicalSolutionSlider__slider">
          <Slider {...settings} afterChange={(idx) => setActive(idx)}>
            {images.map((i) => (
              // <div key={i.id} className={centerHeight && 'popup-slider__container'} >
              <div key={i.img}>
                <div >
                  {i.title && <h5 className="mb-4 popup-slider__image-title">{i.title}</h5>}
                  {i.type === "video" ? (
                  <VideoSide
                    isOpen={true}
                    active={active === i.id}
                    vidRef={i.vidRef}
                    image={i.image}
                    setIsPlayButton={setIsPlayButton}
                    isPlayButton={isPlayButton}
                    width={"100%"}
                  />
                ) : (
                  <img
                    className="popup-slider__image"
                    src={i.img}
                    alt="пример применения"
                  />
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
  )
}

export default TechnicalSolutionSlider;