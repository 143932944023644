import React, { useEffect, useRef } from "react";
import Title from "../Title/Title";
import "./Automation.scss";
import blueIcon from "../../images/icons/station.svg";
import darkBlueIcon from "../../images/icons/industry.svg";
import yellowIcon from "../../images/icons/Min-industry.svg";
import { AppRoute } from "../../constants";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";


const automationColumns = [
  {
    title: "Электроэнергетика",
    paragraph:
      "Автоматизация бизнес-процессов, связанных с генерацией, передачей, распределением и потреблением электроэнергии",
    color: "blue",
    image: blueIcon,
    link: AppRoute.ElectricPower,
  },
  {
    title: "Добывающая промышленность",
    paragraph:
      "Автоматизация бизнес-процессов, связанных с добычей и транспортировкой сырья и топлива",
    color: "yellow",
    image: yellowIcon,
    link: AppRoute.MiningIndustry,
  },
  {
    title: "Обрабатывающая промышленность",
    paragraph:
      "Автоматизация бизнес-процессов, связанных с обработкой добытого сырья",
    color: "dark-blue",
    image: darkBlueIcon,
    link: AppRoute.ManufacturingIndustry,
  },
];

function Automation() {
const navigate = useNavigate();
const automationColumnRef = useRef(null);
const location = useLocation();

const handleClick =(i) => {
  navigate(i.link)
}

useEffect(() => {
  if (automationColumnRef.current && location.state?.fromAutomationProcesses) {
    automationColumnRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
    navigate(location.pathname, { state: { ...location.state, fromAutomationProcesses: false }, replace: true });
  }
}, [location, automationColumnRef, navigate]);

  return (
    <section className="automation" ref={automationColumnRef}>
      <div className="automation__container pt-5">
        <Title
          title={"Автоматизация для различных отраслей бизнеса"}
          paragraph={
            "Оперативно приступайте к работе в NPT Platform с тем набором модулей, который подойдет Вашим потребностям"
          }
        />
        <div className="container flex-row" >
          <div className="row automation__row">
            {automationColumns.map((i) => (
              <div
                className="col automation__columns"
                key={i.title}
                style={{ borderTop: `4px solid var(--${i.color})` }}
                onClick={() => handleClick(i)}
              >
                <img
                  className="automation__img"
                  src={i.image}
                  alt="иконка, которая иллюстрирует промышленность"
                />
                <h3 className="mt-3" style={{ minHeight: "3.5rem" }}>
                  {i.title}
                </h3>
                <p
                  className="mt-3"
                  style={{ color: "var(--grey)", maxWidth: "282px" }}
                >
                  {i.paragraph}
                </p>
                <p
                  //to={i.link}
                  style={{ color: `var(--${i.color})` }}
                 // className="link"
                >
                  {"Посмотреть варианты использования ->"}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Automation;
