import React from "react";
import { useParams } from "react-router-dom";
import { AppRoute, news } from "../../constants";
import { Link } from "react-router-dom";
import "./NewsItem.scss";
import NotFound from "../NotFound/NotFound";
import Layout from "../../components/Layout/Layout"; 

function NewsItem() {
  const id = useParams().id;
  const newsId = id.toString();
  const item = news.find((i) => i.id.toString() === newsId);


  if (item === undefined) {
    return <NotFound />;
  }
 
  return (
    <Layout>
      <main className="main">
        <Link
          to={AppRoute.News}
          className="link m-2 mb-4"
          style={{ alignSelf: "flex-start" }}
        >
          Новости
        </Link>
        <h3 className="mt-4">{item.title}</h3>
        {item.page}
        <div className="news-item_links">
          {item.id !== 1 ? (
            <Link
              to={`/news/${item.id - 1}`}
              className="link m-4 news-item_link"
            >
              Предыдущая
            </Link>
          ) : (
            <div className="m-4 news-item_link news-item__link_disabled">
              Предыдушая
            </div>
          )}
          {item.id !== news.length ? (
            <Link
              to={`/news/${item.id + 1}`}
              className="link m-4 news-item_link"
            >
              Следующая
            </Link>
          ) : (
            <div className="m-4 news-item_link news-item__link_disabled">
              Следующая
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
}
export default NewsItem;
