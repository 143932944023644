import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import map from '../../../images/technicalSolutions/gis-map.png'

const functions = [
  { id: 1, title: "Отображение объектов БД на географической карте в зависимости от их координат" },
  { id: 2, title: "Возможность анализа объектов на картах различных типов (на топографической основе, температурной или административной)" },
  { id: 3, title: "Визуализация различных структур, например электросетей, административно-территориальных подразделений или предприятий" },
  { id: 4, title: "Возможность интеграции функционала БД с географической картой, что позволяет расширять возможности платформы" }
];

const extensions = [
  {
    link: AppRoute.MapPlugin,
    title: "map-plugin",
  },
];

const images = [
  {
    type: "image",
    img: map,
  },
];


function Gis() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    extensions={extensions}
    route={window.location.pathname}
    title="Отображение объектов на ГИС"
    description="Решение предназначено для управления и анализа объектов, заведенных в базу,
    с учетом их географической/территориальной привязки"
    images={images}
  />
  );
}

export default Gis;
