import React from "react";

const description = ['В сентябре 2016 года заключен договор на выполнение работ  по титулу «Разработка корпоративной информационно-аналитической системы по диагностике энергетического оборудования» для нужд ПАО «Транснефть». Система призвана оптимизировать процессы технического диагностирования находящегося на балансе оборудования, обеспечить автоматизированное планирование и распределение работ и позволить в перспективе перейти к новым регламентам обслуживания основных средств. Корпоративная информационная система строится на базе  - программного обеспечения разработки ООО «ЭнергопромАвтоматизация».', 
        'КИАС ДЭО строится с учетом современного подхода к реализации систем подобного класса – на базе клиент-серверной архитектуры с применением WEB-технологий, поддержкой мобильных клиентов и широкими функциональными возможностями. Возможности системы учитывают большое количество пользователей и территориальную распределенность филиалов ПАО «Транснефть».',
        'Результатом внедрения КИАС ДЭО станет полная автоматизация процесса планирования и контроля выполнения работ по диагностике энергетического оборудования ПАО «Транснефть», что позволит принимать обоснованные решения об увеличении срока службы оборудования и снизить расходы на техническое обслуживание и ремонт, а также прогнозировать и предупреждать аварийные ситуации.'];

function Article1() {
  return (
  <div className="mt-4 mb-4">
    {description.map(p => (
      <p key={p}>{p}</p>
    ))}
  </div>
  );
}
export default Article1;
