import { AppRoute } from "../../../constants";
import video from "../../../images/ImagePopup/visualInformation/substationEditFast.webm";
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";

const descriptionLi = [
  { link: AppRoute.Rpa, title: "Техническое решение по АСМ РЗА" },
  {
    link: AppRoute.Opt,
    title: "Риск-ориентированная система учета телекоммуникационных активов",
  },
  { link: AppRoute.Sc, title: "Техническое решение по расчету ТКЗ" },
  {
    link: AppRoute.Pf,
    title: "Техническое решение по расчету параметров установившегося режима",
  },
  {
    link: AppRoute.Se,
    title:
      "Техническое решение по оцениванию состояния установившегося режима сети",
  },
  {
    link: AppRoute.Uml,
    title:
      "Создание диаграмм классов информационной модели с использованием UML",
  },
];
const description = [
  "Расширение vector-plugin предназначено для создания топологий элементов, представленных векторными изображениями (SVG, HTML): схем электрических соединений, UML диаграмм, мнемосхем",
  "Пример применения vector-plugin: редактор однолинейной схемы подстанции с привязкой к базе оборудования",
];

// poster
// Указывает адрес картинки, которая будет отображаться, пока видео не доступно или не воспроизводится
function VectorPlugin() {
  const route = window.location.pathname;

  return (
    <StandardExtensionsDescription
      route={route}
      title={"vector-plugin"}
      titleRu={"Создание интерактивных схем"}
      description={description}
      list={descriptionLi}
      video={video}
      listTitle={"Технические решения с применением vector-plugin:"}
    />
  );
}

export default VectorPlugin;
