import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from '../../../images/technicalSolutions/tkzEditor1.png'
import img2 from '../../../images/technicalSolutions/tkzEditor2.png'
import img3 from '../../../images/technicalSolutions/tkzEditor3.png'
import img4 from '../../../images/technicalSolutions/tkzEditor4.png'
import img5 from '../../../images/technicalSolutions/tkzEditor5.png'

const images = [
  {
    img: img1,
    type: "image",
  },
  {
    type: "image",
    img: img2,
  },
  {
    type: "image",
    img: img3,
  },
  {
    type: "image",
    img: img4,
  },
  {
    type: "image",
    img: img5,
  },
];

const functions = [
  {"id": 1, "title": "Ведение первичного оборудования в древовидной структуре"},
  {"id": 2, "title": "Ведение линий электропередачи и их участков в древовидной структуре"},
  {"id": 3, "title": "Привязка участков линий электропередачи к географическим координатам и отображение участков на географической карте"},
  {"id": 4, "title": "Создание, редактирование, графическая визуализация схем любых объектов ЭЭС (подстанций, электрических станций и др.) с указанием силового оборудования"},
  {"id": 5, "title": "Ведение каталога схем объектов ЭЭС"},
  {"id": 6, "title": "Ведение справочников для формирования параметров первичного оборудования: асинхронные и синхронные машины, продольные и поперечные батареи статических конденсаторов, реакторы заземления нейтрали, силовые трансформаторы, токоограничивающие и шунтирующие реакторы, оборудование линий электропередачи и др."},
  {"id": 7, "title": "Создание и ведение коридоров взаимоиндукции как на географической карте, так и вручную"},
  {"id": 8, "title": "Создание схем сети с привязкой к любому объекту ЭЭС"},
  {"id": 9, "title": "Загрузка моделей из сторонних программных комплексов для добавления в схему сети как в формате CIM RDF/XML, так и в любом другом (RastrWin, АРМ СРЗА и др.)"},
  {"id": 10, "title": "Хранение как исходных файлов моделей из сторонних источников, так и преобразованных в формат CIM RDF/XML"},
  {"id": 11, "title": "Добавление эквивалентных ветвей, генераторов, нагрузки и шунтов вручную в схеме сети"},
  {"id": 12, 
  "title": "Гибкое формирование схем сети для расчета токов короткого замыкания, позволяющее включать в схему следующие компоненты",
    description: [
      'предварительно созданные в системе модели объектов (подстанции, ЛЭП, станции и др.)',
      'элементы, созданные вручную (эквивалентные ветви, шунты, генерация/нагрузка, узлы)',
      'модели, загруженные извне (RastrWin, АРМ СРЗА и др.)'
    ],
},
  {"id": 13, "title": "Возможность создания графической визуализации схемы сети с указанием силового оборудования, ЛЭП, эквивалентов и внешних связей"},
  {"id": 14, "title": "Ведение каталога схем для расчета токов короткого замыкания"},
  {"id": 15, "title": "Выбор и отображение точек короткого замыкания на схеме для расчета"},
  {"id": 16, "title": "Выбор и настройка режимов для расчета токов короткого замыкания (указание вида повреждения, установка положений коммутационных аппаратов, отключение линий электропередачи и ветвей схемы, заземление линий электропередачи и ветвей схемы, отключения узлов схемы)"},
  {"id": 17, "title": "Расчет токов короткого замыкания (величины токов в ветвях и точках короткого замыкания, напряжение в узлах схемы сети)"},
  {"id": 18, "title": "Расчет максимального тока качаний между системами"},
  {"id": 19, "title": "Отображение результатов расчета токов короткого замыкания (величины токов в ветвях и точках короткого замыкания, напряжение в узлах схемы сети)"},
  {"id": 20, "title": "Отображение результатов расчета максимального тока качаний"},
  {"id": 21, "title": "Эквивалентирование схемы относительно выбранных узлов"},
  {"id": 22, "title": "Автоматический пересчет параметров эквивалентов при изменении схемы"}
]
const extensions = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

function Sc() {
  return (
      <TechnicalSolutionsContent
      functions={functions}
      extensions={extensions}
      route={window.location.pathname}
      images={images}
      title="Техническое решение по расчету ТКЗ"
      description="Решение предназначено для создания произвольной электрической сети и последующего расчета токов короткого замыкания и других параметров"
      listTitle="Основные функции:"
    />
  );
}

export default Sc;
