import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import img1 from '../../../images/technicalSolutions/docs-1.png';
import img2 from '../../../images/technicalSolutions/docs-2.png';

const functions = [
  { id: 1, title: "Загрузка, хранение и возможность скачивания любого документа" },
  { id: 2, title: "Создание дерева каталога с возможностью добавления дополнительных уровней для реализации углубленной сортировки" },
  { id: 3, title: "Табличное отображение общего перечня с возможностью сортировки и поиска документов" },
  { id: 4, title: "Возможность ограничения в редактировании объектов для безопасности данных" }
];

const images = [
  {
    type: "image",
    img: img1,
  },
  {
    type: "image",
    img: img2,
  },
];

function Docs() {
  return (
    <TechnicalSolutionsContent
    route={window.location.pathname}
      functions={functions}
      images={images}
      title="Техническое решение по ведению НТД"
      description="Решение предназначено для каталогизации и хранения нормативно-технической документация в древовидном или табличном видах, что позволяет быстро находить необходимый документ"
    />
   
  );
}

export default Docs;
