import React from "react";
import Layout from "../../../components/Layout/Layout";
import Parameters from "../../../components/Parameters/Parameters";
import title from "../../../images/telecommunication/title.png";
import image1 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem0.png";
import image2 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem1.png";
import image3 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem2.png";
import image4 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem3.png";
import image5 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem4.png";
import image6 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem5.png";
import image7 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem6.png";
import image8 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem7.png";
import image9 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem8.png";
import image10 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem9.png";
import image11 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem10.png";
import image12 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem11.png";
import image13 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem12.png";
import image14 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem13.png";
import image15 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem14.png";
import image16 from "../../../images/pdf/img/telecommunicationAsetsAccountingSystem15.png";

const descriptionLi = [
  "Ввод и отображение энергообъектов (ПС, ТП, РТП), телефонных колодцев, с привязкой к местности",
  "Ввод и отображение кабельных трасс с указанием конфигурации каналов и привязкой к местности (в том числе указание коллекторов, мостовых переходов)",
  "Ввод и отображение оконечных устройств и оптических муфт",
  "Ввод и отображение данных по ВОЛС из исполнительной документации с привязкой к местности, в том числе паспортов ВОЛС",
  "Указание и контроль каналов кабельных трасс, в которых проложена ВОЛС с формированием полного маршрута следования ВОЛС",
  "Ввод паспортов оптических кабелей с указанием оптических волокон и привязка их к проложенным ВОЛС",
  "Построение и отображение линейных трактов с привязкой к местности",
  "Отображение использования оптических волокон ВОЛС",
];

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
];

function Telecommunication({ openPopup }) {
  return (
    <Layout>
      <main className="main">
        <Parameters
          openPopup={openPopup}
          title="Система учета телекоммуникационных активов"
          image={title}
          images={images}
          descriptionTitle="Основные функции:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default Telecommunication;
