import React, { useEffect } from "react";
// import title from "../../images/lenenergo/title.png";
// import Parameters from "../../components/Parameters/Parameters";
// import image1 from "../../images/lenenergo/lenenergo_page-0001.jpg";
// import image2 from "../../images/lenenergo/lenenergo_page-0002.jpg";
import Layout from "../../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../constants";

const descriptionLi = [
  "Генерирующие компании оптового рынка",
  "Электросетевые компании",
  "Энергосбытовые компании",
  "Компании, осуществляющие управление режимами единой энергосистемы России",
  "Компании, отвечающие за развитие и функционирование коммерческой инфраструктуры рынка (ОРЭМ и розничных рынков)",
  "Организации, осуществляющие контроль и регулирование в отрасли",
  "Потребители электрической энергии, мелкие производители электрической энергии",
];

const descriptionList = [
  "компании, которые уже успели автоматизировать некоторые бизнес-процессы, настраивают интеграции между разнородными программными продуктами или внедряют их заново",
  "компании, которые еще не начали процесс автоматизации, выбирают единую платформу, на основе которой планируют автоматизировать большинство бизнес-процессов",
];

function ElectricPower({ openPopup }) {
  //const images = [image1, image2 ];
  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/", { state: { fromAutomationProcesses: true } });
    };
  }, []);

  const descriptionLinks = [
    { link: AppRoute.Eqgen, id: 1, title: "генерация электроэнергии" },
    {
      link: AppRoute.Se,
      id: 2,
      title:
        "управление транспортом электроэнергии с оценкой состояния режима сети",
    },
    {
      link: AppRoute.Metering,
      id: 3,
      title: "управление технологическим присоединением",
    },
    {
      link: AppRoute.Metering,
      id: 4,
      title: "управление сбытом и закупками электроэнергии",
    },
    {
      link: AppRoute.Build,
      id: 5,
      title: "управление тендерными и закупочными процедурами",
    },
    { link: AppRoute.Metering, id: 6, title: "ведение НСИ полезного отпуска" },
    { link: AppRoute.Eqhv, id: 7, title: "учет сетевого оборудования" },
    {
      link: AppRoute.Maintanance,
      id: 8,
      title:
        "учет метрологических работ и работ по техническому обслуживанию вторичного оборудования",
    },
  ];

  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4 text-center">
          Технические решения для Электронергетики
        </h1>
        <div className="align-start d-flex flex-column">
          <h5 className="mb-3 mt-3">
            На сегодняшний день в электроэнергетике можно выделить следующие
            группы компаний:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <p>
            Данные компании в части бизнес-процессов можно охарактеризовать, как
            имеющие сложные структуры бизнес-процессов, а также имеющие большое
            количество взаимосвязей как между внутренними бизнес-процессами, так
            и бизнес-процессами, охватывающими взаимодействие указанных групп
            компаний
          </p>
          <h5 className="mb-3 mt-3">
            В связи с этим, естественным процессом является рост степени
            автоматизации бизнес-процессов. При этом можно отметить следующие
            тенденции автоматизации:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionList.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <p>
            Если попробовать отобразить взаимосвязи между бизнес-процессами
            электросетевых компаний, то такая схема будет похожа на запутанный
            клубок, так как взаимосвязей между бизнес-процессами критично много
          </p>
          <p>
            NPT Platfrom позволяет выполнить автоматизацию бизнес-процессов
            субъектов электроэнергетики, а также может выступать в качестве
            единого информационного окна с установленными четкими взаимосвязями
            с имеющимися информационными системами
          </p>
          <h5 className="mb-3 mt-3">
            Решения на базе NPT Platform автоматизируют следующие основные
            бизнес – процессы:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionLinks.map((i) => (
              <li className="mb-1 description__circle-li" key={i.id}>
                <Link to={i.link} className="text-links">
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link to={AppRoute.Implementation} className="text-links">
            Подробную информацию по внедрению решений по комплексной
            автоматизации бизнес-процессов субъектов электроэнергетики можно
            посмотреть в разделе «Внедрение»
          </Link>
        </div>
        {/* <button onClick={() => navigate('/', { state: { fromAutomationProcesses: true } })}>
        Go back and scroll
      </button> */}
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
        {/* <Parameters
          title="Технические решения для Электронергетики"
          image={title}
          images={images}
          openPopup={openPopup}
        >
          <h5 className="mb-3">Основные функции:</h5>
          <ul className="description__list">
            {descriptionLi.map(i => (
              <li className="mb-1 description__number-li" key={i}>{i}</li>
            ))}
          </ul>
        </Parameters> */}
      </main>
    </Layout>
  );
}

export default ElectricPower;
