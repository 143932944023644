import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { InView } from "react-intersection-observer";
import {
  code,
  code2,
  code3,
  code4,
  code5,
  code6,
  code7,
} from "../../constants";
import Typewriter from "../../components/typewriter/Typewriter";
import cardToolbar from "../../images/ImagePopup/Editor/cardToolbar.png";
import cardStructure from "../../images/ImagePopup/Editor/cardStructure.png";
import tableRequest from "../../images/ImagePopup/Editor/tableRequest.png";
import tableStructure from "../../images/ImagePopup/Editor/tableStructure.png";
import tree from "../../images/ImagePopup/Editor/tree.png";
import RDFfile from "../../images/ImagePopup/Editor/RDFfile.png";
import XMLFileLoad from "../../images/ImagePopup/Editor/XMLFileLoad.png";
import XSDScheme from "../../images/ImagePopup/Editor/XSDScheme.png";
import video from "../../images/standardExtensions/developerPlugin/menuEditor.webm";
import CIMPopup from "../../components/Popup/CIMPopup";
import { VideoSide } from "../../components/Popup/VideoSide";
import "./DescriptionDeveloperPlugin.scss";

const editorProfileLi = [
  "Создание классов",
  "Редактирование классов",
  "Создание атрибутов, ассоциаций",
  "Редактирование атрибутов, ассоциаций",
  "Экспорт профиля (целиком или по пакетам классов)",
  "Импорт профиля из сторонних систем для проектирования профилей информационных моделей (CIM Tool, Enterprise Architect и т.п.)",
];

const editorMenuLi = [
  { id: 1, title: "Добавить пункт/подпункт меню" },
  { id: 2, title: "Редактировать пункт меню: " },
  {
    id: 3,
    title:
      "Перейти в редактор кода: открыть в редакторе отображения скрипт меню и выделить строчку ответственную за данный пункт",
  },
];

const editorTreeLi = [
  { id: 1, title: "Добавить уровень/подуровень дерева" },
  { id: 2, title: "Редактировать уровень дерева: " },
  {
    id: 3,
    title:
      "Перейти в редактор кода: открыть в редакторе отображения скрипт дерева и выделить строчку ответственную за данный уровень",
  },
  {
    id: 4,
    title:
      "указать права доступа, которыми должен обладать пользователь, чтобы иметь возможность видеть данные в дереве",
  },
];

const editorTableLi = [
  { id: 1, title: "Добавить колонки таблицы" },
  { id: 2, title: "Редактировать колонки таблицы:" },
  {
    id: 3,
    title:
      "Перейти в редактор кода: открыть в редакторе отображения скрипт таблицы и выделить строчку ответственную за данную колонку",
  },
  {
    id: 4,
    title:
      "Назначить права доступа, которыми должен обладать пользователь, чтобы иметь возможность видеть данные в таблице",
  },
  { id: 5, title: "Сформировать запрос данных для таблицы" },
];

function DescriptionDeveloperPlugin({
  scripts,
  view,
  profile,
  menuEd,
  refTree,
  table,
}) {
  const vidRef = useRef(null);
  const [isPlayButton, setIsPlayButton] = useState(true);
  const [active, setActive] = useState(0);
  const [activeSecond, setActiveSecond] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [isFinishSecond, setIsFinishSecond] = useState(false);
  const [isFinish4, setIsFinish4] = useState(false);
  const [isFinish3, setIsFinish3] = useState(false);
  const [isFinish5, setIsFinish5] = useState(false);
  const [isFinish6, setIsFinish6] = useState(false);
  const [isFinish7, setIsFinish7] = useState(false);
  const [inView, setInView] = useState(false);
  const [inView2, setInView2] = useState(false);
  const settings = {
    dots: true,
    customPaging: (i) => <div className="custom-slick-dots">{i + 1}</div>,
  };

  const editorSlides = [
    {
      title: "Запрос данных для таблицы",
      id: 0,
      code: code2,
      img: tableRequest,
      finish: isFinishSecond,
      setFinish: setIsFinishSecond,
      classNameImg: "",
      language: "javascript",
      height: "",
      textHeight: "220px",
      textWidth: "",
    },
    {
      title: "Экспорт RDF модели",
      id: 1,
      code: code6,
      img: RDFfile,
      finish: isFinish6,
      setFinish: setIsFinish6,
      classNameImg: "description-developer-slider__img",
      language: "javascript",
      height: "650px",
      textHeight: "273px",
      textWidth: "266px",
    },
    {
      title: "Формирование XML сообщения",
      id: 2,
      code: code7,
      img: XMLFileLoad,
      imgFirst: XSDScheme,
      finish: isFinish7,
      setFinish: setIsFinish7,
      classNameImg: "",
      language: "javascript",
      height: "",
      textHeight: "189px",
      textWidth: "",
    },
  ];

  const editorSlides2 = [
    {
      title: "Добавление таблиц",
      id: 0,
      code: code,
      img: tableStructure,
      finish: isFinish,
      setFinish: setIsFinish,
      classNameImg: "",
      language: "xml",
      height: "484px",
      textHeight: "307px",
      textWidth: "",
    },
    {
      title: "Добавление дерева объектов",
      id: 1,
      code: code3,
      img: tree,
      finish: isFinish3,
      setFinish: setIsFinish3,
      classNameImg: "",
      language: "xml",
      height: "",
      textHeight: "210px",
      textWidth: "",
    },
    {
      title: "Добавление карточки объекта",
      id: 2,
      code: code5,
      img: cardStructure,
      finish: isFinish5,
      setFinish: setIsFinish5,
      classNameImg: "description-developer-slider__img",
      language: "json",
      height: "",
      textHeight: "483px",
      textWidth: "400px",
    },
    {
      title: "Добавление кнопок действий в карточку объекта",
      id: 3,
      code: code4,
      img: cardToolbar,
      finish: isFinish4,
      setFinish: setIsFinish4,
      classNameImg: "description-developer-slider__img",
      language: "json",
      height: "",
      textHeight: "483px",
      textWidth: "400px",
    },
  ];

  return (
    <>
      <div className="description-developer__container">
        <div ref={scripts} className="description-developer-container__ref" />
        <h2>Редактор скриптов</h2>
        <p>
          Редактор скриптов предназначен для создания алгоритмов (далее -
          скриптов), исполняемых на серверной стороне автоматизированной
          системы.
        </p>
        <p>
          Скрипты предназначены для логической обработки данных
          автоматизированной системы. Они позволяют производить с ними различные
          операции, такие как - создание, удаление, фильтрация, редактирование и
          т.д. Скрипты позволяют создавать или обновлять объекты по событиям,
          поступающим из других программных модулей.
        </p>
        <p>Языком создания скриптов является ECMA Script 5 (JavaScript).</p>

        <InView
          onChange={setInView}
          style={{ padding: "0 40px 40px", width: "100%" }}
        >
          <Slider
            afterChange={(idx) => setActive(idx)}
            {...settings}
            className="description-developer-slider"
          >
            {editorSlides.map((i) => (
              <div key={i.id}>
                <h5
                  className="mt-2"
                  style={{ alignSelf: "center", color: "var(--white)" }}
                >
                  {i.title}
                </h5>
                {i.id === 2 ? (
                  <div className="editor__slider-container_2img">
                    <img
                      src={i.imgFirst}
                      alt="пример кода для заголовка"
                      style={{
                        width: "52%",
                        height: "fit-content",
                        marginRight: "10px",
                      }}
                    />
                    <div className="">
                      <Typewriter
                        text={i.code}
                        active={active === i.id}
                        delay={10}
                        isOpen={inView}
                        setIsFinish={i.setFinish}
                        textHeight={i.textHeight}
                        textWidth={i.textWidth}
                        language={i.language}
                      />
                      <img
                        src={i.img}
                        alt="картинка иллюстрирует процесс из заголовка"
                        style={{ maxHeight: i.height }}
                        className={`editor-popup__image ${i.classNameImg} ${
                          i.finish && "editor-popup__image_open"
                        } `}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`editor__slider-container ${
                      i.id === 1 && "editor__slider-container_row"
                    }`}
                  >
                    <Typewriter
                      text={i.code}
                      active={active === i.id}
                      delay={10}
                      isOpen={inView}
                      setIsFinish={i.setFinish}
                      textHeight={i.textHeight}
                      textWidth={i.textWidth}
                      language={i.language}
                    />
                    <img
                      src={i.img}
                      alt="картинка иллюстрирует процесс из заголовка"
                      style={{ maxHeight: i.height }}
                      className={`editor-popup__image ${i.classNameImg} ${
                        i.finish && "editor-popup__image_open"
                      } `}
                    />
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </InView>
      </div>

      <div className="description-developer__container">
        <div ref={view} className="description-developer-container__ref" />
        <h2>Редактор отображения</h2>
        <p>
          Редактор отображения предназначен для создания скриптов, исполняемых
          на клиентской стороне автоматизированной системы, предназначенных в
          основном для настройки интерфейса автоматизированной системы. Он
          предоставляет возможность создания и редактирования меню, различных
          форм представления информации, карточек объектов, построения деревьев
          объектов и прочего.
        </p>
        <p>
          Языками создания скриптов в редакторе отображения являются
          HTML/XHTML/SVG/JavaScript.
        </p>
        <InView
          onChange={setInView2}
          style={{ padding: "0 40px 40px", width: "100%" }}
        >
          <Slider
            afterChange={(idx) => setActiveSecond(idx)}
            {...settings}
            className="description-developer-slider"
          >
            {editorSlides2.map((i) => (
              <div key={i.id}>
                <h5
                  className="mb-2"
                  style={{ alignSelf: "center", color: "var(--white)" }}
                >
                  {i.title}
                </h5>
                <div
                  className={`editor__slider-container ${
                    (i.id === 2 || i.id === 3) && "editor__slider-container_row"
                  }`}
                >
                  <Typewriter
                    text={i.code}
                    active={activeSecond === i.id}
                    delay={10}
                    isOpen={inView2}
                    setIsFinish={i.setFinish}
                    textHeight={i.textHeight}
                    textWidth={i.textWidth}
                    language={i.language}
                  />

                  <img
                    src={i.img}
                    alt="картинка иллюстрирует процесс из заголовка"
                    style={{ maxHeight: i.height }}
                    className={`editor-popup__image ${i.classNameImg} ${
                      i.finish && "editor-popup__image_open"
                    } `}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </InView>
      </div>

      <div className="description-developer__container">
        <div ref={profile} className="description-developer-container__ref" />
        <h2>Редактор профиля</h2>
        <p>
          Редактор профиля предназначен для формирования информационной модели
          разрабатываемой информационной системы. Редактор профиля NPT Platfrom
          поддерживает работу с профилем информационной модели в соответствии с
          серией ГОСТ Р 58651 и CIM 61968 и 61970.
        </p>
        <h5 className="mb-3">
          Редактор профиля позволяет выполнять следующие операции:
        </h5>
        <ul className="description__list">
          {editorProfileLi.map((i) => (
            <li className="mb-1 description__number-li" key={i}>
              {i}
            </li>
          ))}
        </ul>
        {
          <CIMPopup
            isOpen={true}
            width={"100%"}
            padding={"0 40px 40px"}
            descriptionDeveloper={"description-developer-popup__container"}
          />
        }
      </div>
      <div className="description-developer__block">
        <div ref={menuEd} className="description-developer-container__ref" />
        <h2>Редактор меню</h2>
        <p>
          Редактор меню позволяет выполнить настройку пунктов главного меню, а
          также настройку пунктов меню раздела «Безопасность», который отвечает
          за ведение журналов информационной безопасности.
        </p>
        <p>
          Редактор пунктов главного меню позволяет редактировать функционал
          пунктов главного меню: их отображение, свойства, добавлять подпункты и
          т.д.
        </p>
        <p>
          Редактор пунктов меню раздела «Безопасность» позволяет редактировать
          отображение перечня журналов информационной безопасности.
        </p>
        <h5 className="mb-3">
          Редактор меню предоставляет следующие возможности:
        </h5>
        <ul className="description__list">
          {editorMenuLi.map((i) => (
            <li className="mb-1 description__number-li" key={i.id}>
              {i.title}
              {i.id === 2 && (
                <ol>
                  <li className="description__circle-li">
                    изменять наименование заголовка пункта меню в зависимости от
                    языка системы
                  </li>
                  <li className="description__circle-li">
                    указать права доступа, которыми должен обладать
                    пользователь, чтобы иметь возможность видеть данный пункт
                    меню
                  </li>
                  <li className="description__circle-li">
                    назначать иконку для пункта меню.
                  </li>
                </ol>
              )}
            </li>
          ))}
        </ul>
        <VideoSide
          isOpen={true}
          active={true}
          vidRef={vidRef}
          image={video}
          setIsPlayButton={setIsPlayButton}
          isPlayButton={isPlayButton}
        />
      </div>
      <div className="description-developer__block">
        <div ref={refTree} className="description-developer-container__ref" />
        <h2>Редактор деревьев</h2>
        <p>
          Редактор деревьев позволяет выполнить настройку иерархических структур
          данных (деревьев), используемых в автоматизированной системе.
        </p>
        <h5 className="mb-3">
          Редактор деревьев предоставляет следующие возможности:
        </h5>
        <ul className="description__list">
          {editorTreeLi.map((i) => (
            <li className="mb-1 description__number-li" key={i.id}>
              {i.title}
              {i.id === 2 && (
                <ol>
                  <li className="description__circle-li">
                    изменять наименование уровня дерева в зависимости от языка
                    системы
                  </li>
                  <li className="description__circle-li">
                    назначать иконку для уровня дерева
                  </li>
                  <li className="description__circle-li">
                    указать правила отображения/скрытия уровня дерева
                  </li>
                  <li className="description__circle-li">
                    назначать правила копирования/удаления уровня дерева
                  </li>
                </ol>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="description-developer__block">
        <div ref={table} className="description-developer-container__ref" />
        <h2>Редактор таблиц</h2>
        <p>
          Редактор деревьев позволяет выполнить настройку табличных структур
          данных, используемых в автоматизированной системе.
        </p>
        <h5 className="mb-3">
          Редактор таблиц предоставляет следующие возможности:
        </h5>
        <ul className="description__list">
          {editorTableLi.map((i) => (
            <li className="mb-1 description__number-li" key={i.id}>
              {i.title}
              {i.id === 2 && (
                <ol>
                  <li className="description__circle-li">
                    изменять наименование колонки в зависимости от языка системы
                  </li>
                  <li className="description__circle-li">
                    указать свойства колонки: скрытая, ширина, наличие фильтра,
                    формат данных в колонке
                  </li>
                  <li className="description__circle-li">
                    назначать ссылки на объекты системы
                  </li>
                  <li className="description__circle-li">
                    назначать исходные параметры: сортировку, фильтр
                  </li>
                </ol>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default DescriptionDeveloperPlugin;
