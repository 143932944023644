import React from "react";
import title from "../../../images/Lenenergo/title.png";
import Parameters from "../../../components/Parameters/Parameters";
import image1 from "../../../images/pdf/img/lenenergo0.png";
import image2 from "../../../images/pdf/img/lenenergo1.png";
import image3 from "../../../images/pdf/img/lenenergo2.png";
import image4 from "../../../images/pdf/img/lenenergo3.png";
import image5 from "../../../images/pdf/img/lenenergo4.png";
import image6 from "../../../images/pdf/img/lenenergo5.png";
import image7 from "../../../images/pdf/img/lenenergo6.png";
import image8 from "../../../images/pdf/img/lenenergo7.png";
import image9 from "../../../images/pdf/img/lenenergo8.png";
import Layout from "../../../components/Layout/Layout";

const descriptionLi = [
  "Формирование и ведение каталогов оборудования ЦРЭС",
  "Формирование и ведение перечня мультибрендовых решений для ЦРЭС с учетом коэффициентов стоимости и надежности",
  "Ранжирование функциональных единиц типовых элементов объектов ЦРЭС",
  "Формирование и ведение объектов ЦРЭС",
  "Формирование и ведение модернизируемых объектов ЦРЭС",
  "Ранжирование мультибрендовых технических решений для типового элемента объекта ЦРЭС",
  "Формирование спецификации спроектированных объектов ЦРЭС",
  "Расчет капитальных и операционных затрат на реализацию сформированного проекта",
];
const linkDiploma = {
  text: "Диплом за создание электронного каталога типовых решений для построения высокоавтоматизированных районов электрических сетей (РЭС)",
  link: "https://rosseti-lenenergo.ru/press/lenenergo/130918.html",
  color: "var(--red)",
};

function Lenenergo({ openPopup }) {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="ЭК для ЦРЭС (ПАО «Ленэнерго»)"
          image={title}
          images={images}
          linkUnderPhoto={linkDiploma}
          openPopup={openPopup}
          descriptionTitle="Основные функции:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default Lenenergo;
