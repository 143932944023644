import React from "react";
import "./Article.scss";
const description = [
  "С 9 по 11 февраля 2022 года в Сочи АО «СО ЕЭС» провело вторую общеотраслевую конференцию «CIM в России и мире», посвященную унификации информационного обмена в электроэнергетике.",
  "Более двухсот профессионалов в области унификации информационного обмена в электроэнергетике на основе CIM собрались в Сочи очно и в онлайн-пространстве, чтобы обменяться опытом. Среди участников – представители ПАО «Россети», ПАО «РусГидро», ПАО «Интер РАО», других крупнейших энергокомпаний, разработчиков и профессиональных ассоциаций, зарубежные эксперты.",
  "На конференции обсуждались вопросы стандартизации информационного обмена в электроэнергетике, в том числе инициативы Системного оператора, как основного центра компетенций по внедрению CIM в России, в части совершенствования нормативно-правового фундамента в этой сфере.",
  "Технический директор ООО «НТЦ «ЭнергопромАвтоматизация» (ГК «ЭнергопромАвтоматизация») Олег Владимирович Кириенко совместно с начальником Департамента релейной защиты, метрологии и автоматизированных систем управления технологическими процессами ПАО «Россети» Салёновым Александром Владимировичем представили доклад. В докладе рассмотрен опыт внедрения NPT Platform в рамках проекта ПТК «Эксплуатация» для цифровизации жизненного цикла устройств РЗА, а также опыт применения серии стандартов ГОСТ Р 58651 и международных стандартов при решении задач расчета токов короткого замыкания и выбора параметров срабатывания устройств РЗА. Цифровизация жизненного цикла устройств РЗА обеспечивает хранение, передачу и обработку информации на всех этапах жизненного цикла устройств РЗА в цифровом виде.  Результатом цифровизации жизненного цикла РЗА является:",
];

const list = [
  "создание цифрового паспорта устройств и комплексов РЗА",
  "создание единого информационного пространства между различными программными комплексами - персонал работает в одном программном комплексе",
  "обеспечение контроля исполнения работ по ТОиР",
  "сокращение времени принятия решений",
  "сокращение ошибок при передаче информации",
  "сокращение времени анализа неисправностей и аварийных нарушений",
  "переход на обслуживание по состоянию",
];

function Article6() {
  return (
    <div className="mt-4 mb-4">
      {description.map((p) => (
        <p key={p}>{p}</p>
      ))}
      <ul className="article__list">
        {list.map((li) => (
          <li key={li}>{li}</li>
        ))}
      </ul>
    </div>
  );
}
export default Article6;
