import React, { useRef } from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from "../../../images/ImagePopup/visualInformation/10.png";
import oscilloscope from "../../../images/ImagePopup/visualInformation/oscilloscope.webm";

const functions = [
  { id: 1, title: "Ведение первичного оборудования в древовидной структуре" },
  { id: 2, title: "Ведение вторичного оборудования в древовидной структуре" },
  {
    id: 3,
    title:
      "Ведение функций регистрации аварийных событий, связанных с первичным и вторичным оборудованием",
  },
  { id: 4, title: "Ведение технологических нарушений" },
  {
    id: 5,
    title:
      "Ведение осциллограмм технологических нарушений, каналы которых регистрируются функциями регистрации аварийных событий",
  },
  {
    id: 6,
    title:
      "Ведение каналов, включенных в наборы параметров осциллограмм технологических нарушений",
  },
  {
    id: 7,
    title:
      "Отображение осциллограмм в интерфейсе системы с возможностью автоматической и ручной синхронизации, объединения осциллограмм, настройки амплитуды, группировки по различным параметрам и др.",
  },
];

const extensions = [
  {
    link: AppRoute.OscilloscopePlugin,
    title: "oscilloscope-plugin",
  },
];

function Oscl() {
  const vidRef = useRef(null);

  const images = [
    {
      id: 0,
      type: "image",
      img: img1,
    },
    {
      id: 1,
      type: "video",
      image: oscilloscope,
      vidRef: vidRef,
      title: "Просмотр осциллограмм во встроенном осциллографе",
    },
  ];

  return (
    <TechnicalSolutionsContent
      functions={functions}
      extensions={extensions}
      images={images}
      video={true}
      centerHeight={true}
      route={window.location.pathname}
      title="Техническое решение по отображению осциллограмм"
      description="Решение предназначено для добавления, хранения и отображения
    осциллограмм технологических нарушений, произошедших на объектах
    электроэнергетики: подстанциях, электрических станциях, линиях
    электропередачи и др. Решение также связывает первичное и вторичное
    оборудование с функциями регистрации аварийных событий, которые в
    свою очередь связаны с регистрируемыми осциллограммами и их
    аналоговыми и дискретными каналами"
    />
  );
}

export default Oscl;
