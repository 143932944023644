import React from "react";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";
import { Link } from "react-router-dom";

const descriptionLi = [
  {
    title:
      " с составлением отчета о предпроектном обследовании или изучение существующего отчета о предпроектном обследовании",
    link: AppRoute.SurveyReport,
    linkText: "Проведение предпроектного обследования",
  },
  {
    title:
      " или изучение существующего технического задания на создание системы",
    link: AppRoute.TechnicalRequirements,
    linkText: "Разработка технического задания на создание системы",
  },
  {
    title:
      "Формирование решений по информационно-вычислительной инфраструктуры внедряемой системы",
  },
  {
    title: "Формирование решений по архитектуре и структуре внедряемой системы",
  },
  {
    title: "Формирование решений по модулям внедряемой системы",
  },
  {
    title: "Формирование решений по информационной модели внедряемой системы",
  },
  {
    title: "Формирование решений по администрированию внедряемой системы",
  },
  {
    title:
      "Формирование решений по информационной безопасности внедряемой системы",
  },
  {
    title:
      "Формирование спецификации форматов импортируемых и экспортируемых данных",
  },
  {
    title: "Формирование решений по нормативно-справочной информации",
  },
  {
    title:
      "Формирование решений по интеграции с внешними информационными системами",
  },
  {
    title:
      "Формирование графических форм для отображения информации во внедряемой системе",
  },
  {
    title:
      "Формирование решений по отчетной документации, формируемой внедряемой системой",
  },
  {
    title:
      "Формирование решений по уведомлениям и оповещениям, формируемых внедряемой системой",
  },
  {
    title: "Разработка технического проекта на внедряемую систему",
  },
  {
    title:
      "Разработка технических решений по информационно-вычислительной инфраструктуре внедряемой системы",
  },
];

function ProjectDocumentation() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-5">Разработка проектной документации</h1>
        <div className="align-start">
          <p>
            Компетенция специалистов нашей компании и компаний партнеров, а
            также богатый{" "}
            <Link className="text-links" to={AppRoute.Implementation}>
              опыт внедрений
            </Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно определять требования к техническим
            решениям по внедряемой автоматизированной системе и осуществлять
            разработку технического проекта на автоматизированную систему.
          </p>
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link className="text-links" to={AppRoute.Partners}>компаний партнеров</Link>, а также
            богатый <Link className="text-links" to={AppRoute.Implementation}>опыт внедрений</Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно определять требования к техническим
            решениям по внедряемой автоматизированной системе и осуществлять
            разработку технического проекта на автоматизированную систему.
          </p> */}
          <p>
            Разработка технического проекта на автоматизированную систему
            осуществляется в соответствии с ГОСТ Р 59795-2021 Информационные
            технологии. Комплекс стандартов на автоматизированные системы.
            Автоматизированные системы. Требования к содержанию документов.
          </p>
          <p className="description__title">
            Разработка технического проекта на автоматизированную систему (далее
            – Услуга) осуществляется в рамках договора на внедрение
            информационной системы или по отдельному договору на оказание
            Услуги, и включает в себя следующие этапы:
          </p>
          {/* <h5 className="mb-3 mt-3">
            Внедрение автоматизированных систем на базе NPT Platform (далее –
            систем) условно можно разделить на следующие этапы:
          </h5> */}
          <ul className="description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                {i.link && (
                  <Link className="text-links" to={i.link}>
                    {i.linkText}
                  </Link>
                )}
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default ProjectDocumentation;
