import React, { useState } from "react";
import cardToolbar from "../../images/ImagePopup/Editor/cardToolbar.png";
import cardStructure from "../../images/ImagePopup/Editor/cardStructure.png";
import tableRequest from "../../images/ImagePopup/Editor/tableRequest.png";
import tableStructure from "../../images/ImagePopup/Editor/tableStructure.png";
import RDFfile from "../../images/ImagePopup/Editor/RDFfile.png";
import tree from "../../images/ImagePopup/Editor/tree.png";
import XMLFileLoad from "../../images/ImagePopup/Editor/XMLFileLoad.png";
import XSDScheme from "../../images/ImagePopup/Editor/XSDScheme.png";
import "./EditorPopup.scss";
import Typewriter from "../../components/typewriter/Typewriter";
import Slider from "react-slick";
import {
  code,
  code2,
  code3,
  code4,
  code5,
  code6,
  code7,
} from "../../constants";

function EditorPopup({ isOpen }) {
  const [isFinish, setIsFinish] = useState(false);
  const [active, setActive] = useState(0);
  const [isFinishSecond, setIsFinishSecond] = useState(false);
  const [isFinish4, setIsFinish4] = useState(false);
  const [isFinish3, setIsFinish3] = useState(false);
  const [isFinish5, setIsFinish5] = useState(false);
  const [isFinish6, setIsFinish6] = useState(false);
  const [isFinish7, setIsFinish7] = useState(false);

  const settings = {
    dots: true,
    customPaging: (i) => <div className="custom-slick-dots">{i + 1}</div>,
  };

  const editorSlides = [
    {
      title: "Добавление таблиц",
      id: 0,
      code: code,
      img: tableStructure,
      finish: isFinish,
      setFinish: setIsFinish,
      classNameImg: "",
      language: "xml",
      height: "531px",
      textHeight: "295px",
      textWidth: "",
    },
    {
      title: "Запрос данных для таблицы",
      id: 1,
      code: code2,
      img: tableRequest,
      finish: isFinishSecond,
      setFinish: setIsFinishSecond,
      classNameImg: "",
      language: "javascript",
      height: "",
      textHeight: "220px",
      textWidth: "",
    },
    {
      title: "Добавление дерева объектов",
      id: 2,
      code: code3,
      img: tree,
      finish: isFinish3,
      setFinish: setIsFinish3,
      classNameImg: "",
      language: "xml",
      height: "",
      textHeight: "210px",
      textWidth: "",
    },
    {
      title: "Добавление карточки объекта",
      id: 3,
      code: code5,
      img: cardStructure,
      finish: isFinish5,
      setFinish: setIsFinish5,
      classNameImg: "editor-popup__img",
      language: "json",
      height: "",
      textHeight: "483px",
      textWidth: "400px",
    },
    {
      title: "Добавление кнопок действий в карточку объекта",
      id: 4,
      code: code4,
      img: cardToolbar,
      finish: isFinish4,
      setFinish: setIsFinish4,
      classNameImg: "editor-popup__img",
      language: "json",
      height: "",
      textHeight: "483px",
      textWidth: "400px",
    },
    {
      title: "Экспорт RDF модели",
      id: 5,
      code: code6,
      img: RDFfile,
      finish: isFinish6,
      setFinish: setIsFinish6,
      classNameImg: "editor-popup__img",
      language: "javascript",
      height: "650px",
      textHeight: "273px",
      textWidth: "400px",
    },
    {
      title: "Формирование XML сообщения",
      id: 6,
      code: code7,
      img: XMLFileLoad,
      imgFirst: XSDScheme,
      finish: isFinish7,
      setFinish: setIsFinish7,
      classNameImg: "",
      language: "javascript",
      height: "",
      textHeight: "189px",
      textWidth: "",
    },
  ];

  // function filterBy(data, field, value) {
  //   return data.filter(item => item[field] === value);
  // }
  // const notebooks = filterBy(editorSlides, 1);

  return (
    <div style={{ padding: "0 40px 20px", width: "100%" }}>
      <Slider afterChange={(idx) => setActive(idx)} {...settings}>
        {editorSlides.map((i) => (
          <div className={`editor__slider`} key={i.id}>
            <h3
              className="mb-2"
              style={{ alignSelf: "center", color: "var(--white)" }}
            >
              {i.title}
            </h3>
            {i.id === 6 ? (
              <div className="editor__slider-container_2img">
                <img
                  src={i.imgFirst}
                  alt="пример кода"
                  style={{
                    width: "52%",
                    height: "fit-content",
                    marginRight: "10px",
                  }}
                />
                <div className="">
                  <Typewriter
                    text={i.code}
                    active={active === i.id}
                    delay={10}
                    isOpen={isOpen}
                    setIsFinish={i.setFinish}
                    textHeight={i.textHeight}
                    textWidth={i.textWidth}
                    language={i.language}
                  />
                  <img
                    src={i.img}
                    alt="изображение, как пример работы из заголовка"
                    style={{ maxHeight: i.height }}
                    className={`editor-popup__image ${i.classNameImg} ${
                      i.finish && "editor-popup__image_open"
                    } `}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`editor__slider-container ${
                  (i.id === 3 || i.id === 4 || i.id === 5) &&
                  "editor__slider-container_row"
                }`}
              >
                <Typewriter
                  text={i.code}
                  active={active === i.id}
                  delay={10}
                  isOpen={isOpen}
                  setIsFinish={i.setFinish}
                  textHeight={i.textHeight}
                  textWidth={i.textWidth}
                  language={i.language}
                />
                <img
                  src={i.img}
                  alt="изображение, как пример работы из заголовка"
                  style={{ maxHeight: i.height }}
                  className={`editor-popup__image ${i.classNameImg} ${
                    i.finish && "editor-popup__image_open"
                  } `}
                />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default EditorPopup;
