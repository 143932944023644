import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import graph from '../../../images/technicalSolutions/build-graph.png'

const extensions = [
  {
    link: AppRoute.MessagePlugin,
    title: "messages-plugin",
  },
  {
    link: AppRoute.DashboardPlugin,
    title: "dashboard-plugin",
  },
];


const functions = [
  { id: 1, title: "ведение реестра проектируемого оборудования ВЭС" },
  { id: 2, title: "ведение проектов (титулов) реконструкции/строительства ВЭС" },
  { id: 3, title: "ведение справочника подразделений, подрядных организаций и сотрудников" },
  { id: 4, title: "выполнение подрядными организациями (поставщиками) отметки выполнения этапов работ с возможностью загрузки официальных писем" },
  { id: 5, title: "осуществление мобильного доступа к системе и получения информации об оборудовании с помощью QR-кодов" },
  { id: 6, title: "осуществление контроля и анализа инцидентов, возникающих в рамках реконструкции/строительства ВЭС" },
  { id: 7, title: "ведение договоров и официальной переписки с привязкой к проекту (титулу) реконструкции/строительства ВЭС" },
  { id: 8, title: "формирование финансовой и проектной отчетности по проектам (титулам) реконструкции/строительства ВЭС" }
];

const images = [
  {
    type: "image",
    img: graph,
  },
];

function Build() {
  return (
    <TechnicalSolutionsContent
      functions={functions}
      route={window.location.pathname}
      extensions={extensions}
      images={images}
      title="Техническое решение по ведению проектов строительства и реконструкции объектов ветроэнергетики"
      description={`Решение предназначено для автоматизации процессов проектирования, строительства и реконструкции ветряных электростанций (ВЭС)/${'\u00A0'}ветропарков различной величины`}
      
      />
  )
}

export default Build;