

import React from "react";
import Circle from "../Circle/Circle";
import './IntegrateBusinessProcesses.scss'

function IntegrateBusinessProcesses() {
  return (
    <section className="information-environment">
      <div className="d-flex w-100 justify-content-lg-between" style={{padding: "0 10px"}}>
      <h4 className="business-processes__title" style={{marginRight: '10px'}}>Интегрируйте NPT Platform в Ваши бизнес-процессы</h4>
      <h4 className="business-processes__title" style={{maxWidth: '460px'}}>Интегрируйте NPT Platform с Вашими информационными системами</h4>
      </div>
     
      {/* <Title
        color={"orange"}
        title={"Единая информационная среда"}
        paragraph={
          "Ускорьте Ваши бизнес-процессы с готовыми решениями по автоматизации"
        }
      /> */}
      <Circle />
        {/* <div className="row justify-content-center"  > 
        <Circle />
        </div> */}

    </section>
  );
}

export default IntegrateBusinessProcesses;