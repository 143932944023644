import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import plot from '../../../images/technicalSolutions/dm-plot.png'

const extensions = [
  {
    link: AppRoute.ScadaPlugin,
    title: "scada-plugin",
  },
  {
    link: AppRoute.DashboardPlugin,
    title: "dashboard-plugin",
  },
];

const functions = [
  { id: 1, title: "Сбор и обработка данных в реальном времени" },
  { id: 2, title: "Отслеживание состояния техники и хода работы" },
  { id: 3, title: "Отображение мнемосхемы технологического процесса" },
  { id: 4, title: "Отображение отслеживаемых параметров технологического процесса в виде графиков, диаграмм и таблиц" },
  { id: 5, title: "Возможность выгрузки отчетной информации в стандартных форматах" }
];

const images = [
  {
    type: "image",
    img: plot,
  },
];

function Dm() {
  return (
    <TechnicalSolutionsContent
      route={window.location.pathname}
      functions={functions}
      extensions={extensions}
      title="Витрина данных реального времени"
      images={images}
      description="Решение предназначено для организации сбора данных в реальном времени, непрерывного мониторинга и контроля работы автоматизированных объектов в различных отраслях промышленности"
    />
  );
}

export default Dm;
