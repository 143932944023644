import React from "react";
import Advantages from "../Advantages/Advantages";
import "./MainPage.scss";
import IntegrateBusinessProcesses from "../integrateBusinessProcesses/IntegrateBusinessProcesses";
import InformationEnvironment from "../InformationEnvironment/InformationEnvironment";
// import Introducing from "../introducing/Introducing";
//import Trusted from "../trusted/Trusted";
//import Sponsors from "../sponsors/Sponsors";
import Automation from "../Automation/Automation";
import Layout from "../Layout/Layout";
import Intro from "../intro/Intro";


function MainPage({ openPopup }) {
  return (
    <Layout>
    <main className="main-page">
      <Intro />
      <Advantages openPopup={openPopup} />
      <InformationEnvironment />
      <IntegrateBusinessProcesses />
      {/* <Introducing /> */}
      <Automation />
      {/* <Trusted /> */}
      {/* <Sponsors /> */}
    </main>
    </Layout>

  );
}

export default MainPage;
