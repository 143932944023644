import Zoom from "../../images/icons/zoom";


function PictureWithZoom({zoomColor, image, openPopup, popupHeight}) {


  return (
<div
style={{ maxWidth: "650px", marginBottom: "20px" }}
className="parameters__image-container"
>
<img
  className="col"
  alt="главная страница сайта "
  src={image}
  style={{ width: "100%" }}
/>
<button
  className="parameters__zoom-button link"
  onClick={() =>
    openPopup(
      popupHeight === true
        ? { image: image, height: "90%" }
        : { image: image }
    )
  }
>
  <Zoom
    style={{ width: "100%" }}
    zoomColor={zoomColor ? zoomColor : "black"}
  />
</button>
</div>
  );
}

export default PictureWithZoom;