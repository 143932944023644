import React from "react";
import Layout from "../../../components/Layout/Layout";
import title from "../../../images/production/title.png";
import Parameters from "../../../components/Parameters/Parameters";

const descriptionLi = [
  "Ведение проектов по поставке готовой продукции заказчикам",
  "Складской учет комплектующих для производства изделий, произведенных изделий, а также изделий готовых к отправке с привязкой к проектам",
  "Оценка количества плат (комплектующих), находящихся на складе производства, для сборки необходимого количества изделий",
  "Выполнение учёта поступающих на производство плат",
  "Выполнение учёта фактического статуса комплектующих и изделий на производстве",
  "Контроль числа прошедших входной контроль комплектующих из поступивших на производство",
  "Ведение информации по программному обеспечению изделия (прошивке)",
  "Отображение актуальной информации по количеству комплектующих, готовой продукции и их статусе",
  "Формирование отчетов по партиям комплектующих, времени сборки продукции и дефектовки",
  "Формирование паспортов изделий",
];

function Production({ openPopup }) {
  return (
    <Layout>
      <main className="main">
        <Parameters
          title={'ПТК "Производство"'}
          image={title}
          openPopup={openPopup}
          descriptionTitle="Комплекс предназначен для решения следующих задач:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default Production;
