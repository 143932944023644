import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";

const list = [
  {
    id: 1,
    title:
      " с составлением отчета о предпроектном обследовании или изучение существующего отчета о предпроектном обследовании",
    link: (
      <Link to={AppRoute.SurveyReport} className="text-links">
        Проведение предпроектного обследования
      </Link>
    ),
  },
  {
    id: 2,
    title: "Формирование характеристики объектов автоматизации",
  },
  {
    id: 3,
    title:
      "Формирование требований к структуре и функционированию внедряемой системы",
  },
  {
    id: 4,
    title:
      "Формирование требований к функциям, выполняемым внедряемой системой",
  },
  {
    id: 5,
    title:
      "Формирование требования к численности, квалификации и режиму работы персонала",
  },
  {
    id: 6,
    title:
      "Формирование требований к показателям назначения внедряемой системы",
  },
  {
    id: 7,
    title: "Формирование требований к надежности внедряемой системы",
  },
  {
    id: 8,
    title: "Формирование требований к безопасности внедряемой системы",
  },
  {
    id: 9,
    title: "Формирование требований к эргономике и технической эстетике",
  },
  {
    id: 10,
    title:
      "Формирование требований по сохранности информации во внедряемой системе",
  },
  {
    id: 11,
    title:
      "Формирование требований к защите информации от несанкционированного доступа во внедряемой системе",
  },
  {
    id: 12,
    title:
      "Формирование требований к эксплуатации, техническому обслуживанию, ремонту и хранению компонентов внедряемой системы ",
  },
  {
    id: 13,
    title: "Формирование требований по стандартизации и унификации",
  },
  {
    id: 14,
    title: "Формирование требований к доступности внедряемой системы",
  },
  {
    id: 15,
    title:
      "Формирование требований к патентной чистоте и лицензионной чистоте внедряемой системы",
  },
  {
    id: 16,
    title:
      "Формирование требований к защите от влияния внешних воздействий на внедряемую систему",
  },
  {
    id: 17,
    title: "Формирование требований к видам обеспечения внедряемой системы",
  },
  {
    id: 18,
    title:
      "Формирование требований к составу и содержанию работ по созданию внедряемой системы",
  },
  {
    id: 19,
    title: "Определение порядка контроля и приемки внедряемой системы",
  },
  {
    id: 20,
    title:
      "Определение состава и содержание работ по подготовке объекта автоматизации",
  },
  {
    id: 21,
    title: "Формирование требований к документированию",
  },
  {
    id: 22,
    title:
      "Разработка технических требований и/или технического задания на создание внедряемой системы",
  },
  {
    id: 23,
    title:
      "Разработка спецификации для закупки программного и аппаратного обеспечения, необходимого для реализации внедряемой системы",
  },
];

function TechnicalRequirements() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4">Разработка технических требований</h1>
        <div className="align-start">
          {/* <p >
        Компетенция специалистов нашей компании и <Link className="text-links" to={AppRoute.Partners}>компаний партнеров,</Link> 
         а также богатый опыт внедрений автоматизированных систем на базе NPT Platform, дает возможность максимально эффективно определять 
         технические требования к внедряемой автоматизированной системе и осуществлять разработку технических требований 
         и/или технического задания на создание автоматизированной системы.            
        </p> */}
          <p>
            Компетенция специалистов нашей компании и компаний партнеров, а
            также богатый опыт внедрений автоматизированных систем на базе NPT
            Platform, дает возможность максимально эффективно определять
            технические требования к внедряемой автоматизированной системе и
            осуществлять разработку технических требований и/или технического
            задания на создание автоматизированной системы.
          </p>
          <p>
            Разработка технического задания на создание автоматизированной
            системы осуществляется в соответствии с{" "}
            <b>
              ГОСТ 34.602-2020 Информационные технологии. Комплекс стандартов на
              автоматизированные системы. Техническое задание на создание
              автоматизированной системы.
            </b>
          </p>
          <h5 className="description__title">
            Разработка технических требований и/или технического задания на
            создание автоматизированной системы (далее – Услуга) осуществляется
            в рамках договора на внедрение информационной системы или по
            отдельному договору на оказание Услуги, и включает в себя следующие
            этапы:
          </h5>
          <ul className="description__list">
            {list.map((i) => (
              <li className="mb-3 description__number-li" key={i.id}>
                {i.link && i.link}
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default TechnicalRequirements;
