import { AppRoute } from "../../constants";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./Parameters.scss";
import PictureWithZoom from "../pictureWithZoom/PictureWithZoom";

function Parameters(props) {
  const {
    zoomColor,
    title,
    image,
    images,
    linkUnderPhoto,
    logo,
    openPopup,
    popupHeight,
    descriptionTitle,
    descriptionList,
  } = props;

  const settings = {
    dots: true,
  };

  return (
    <>
      {logo ? (
        <div className="row w-100 mb-4 align-items-center">
          <h1 className="w-70 text-center col">{title}</h1>
          <Link
            to="https://reestr.digital.gov.ru/reestr/1578353/"
            target="_blank"
            rel="noopener noreferrer"
            className="col parameters__logo link"
          >
            <img
              src={logo}
              className="parameters__logo-img"
              alt="логотип Минкомсвязи"
            />
            <p className="parameters__logo-p">Внесено в реестр Минкомсвязи</p>
          </Link>
        </div>
      ) : (
        <h1 className="mb-5 w-70 text-center">{title}</h1>
      )}
      <div className="mb-5 row mt-3">
        <div className="col">
          <h5 className="mb-3">{descriptionTitle}</h5>
          <ul className="description__list">
            {descriptionList.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
        </div>
        <div className="col">
            <PictureWithZoom zoomColor={zoomColor} image={image} openPopup={openPopup} popupHeight={popupHeight}/>
          {linkUnderPhoto && (
            <a
              href={linkUnderPhoto.link}
              rel="noopener noreferrer"
              target="_blank"
              className="link"
              style={{ color: linkUnderPhoto.color }}
            >
              {linkUnderPhoto.img ? (
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={linkUnderPhoto.img}
                    alt="ссылка на сайт"
                    style={{ width: "8%", marginRight: "10px" }}
                  />
                  <h5 className="m-0">{linkUnderPhoto.text}</h5>
                </div>
              ) : (
                linkUnderPhoto.text
              )}
            </a>
          )}
        </div>
      </div>
      {images && (
        <div className="mb-5 parameters__container">
          <Slider {...settings}>
            {images.map((i) => (
              <img
                src={i}
                alt="слайды презентаций"
                key={i}
                className="parameters__image"
              />
            ))}
          </Slider>
        </div>
      )}
      <div className="w-100 d-flex flex-column align-items-center">
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </div>
    </>
  );
}

export default Parameters;
