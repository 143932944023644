import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img from "../../../images/telecommunication/title.png"
const extensions = [
  {
    link: AppRoute.MapPlugin,
    title: "map-plugin",
  },
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];
const functions = [
    { id: 1, title: "ведение данных по энергообъектам и отображение энергообъектов (ПС, ТП, РТП), телефонных колодцев, с привязкой к местности" },
    { id: 2, title:  `ведение данных по кабельным трассам и отображение кабельных трасс с указанием конфигурации каналов и привязкой к местности (в${'\u00A0'}том числе указание коллекторов, мостовых переходов)`},
    { id: 3, title: "ведение данных по оконечным устройствам и отображение оконечных устройств и оптических муфт" },
    { id: 4, title: "ведение данных по ВОЛС и отображение данных по ВОЛС из исполнительной документации с привязкой к местности, в том числе паспортов ВОЛС" },
    { id: 5, title: "указание и контроль каналов кабельных трасс, в которых проложена ВОЛС с формированием полного маршрута следования ВОЛС" },
    { id: 6, title: "ведение паспортов оптических кабелей с указанием оптических волокон и привязка их к проложенным ВОЛС" },
    { id: 7, title: "построение и отображение линейных трактов с привязкой к местности" },
    { id: 8, title: "отображение использования оптических волокон ВОЛС" },
    { id: 9, title: "нахождение оптимальных путей для организации линейных трактов по различным условиям (минимальное затухание, не использование одинаковых каналов, кабелей, узлов и т.п.)" },
    { id: 10, title: "расчет расстояния по оптическому волокну до произвольной точки на ВОЛС" },
    { id: 11, title: "отображение точки на ВОЛС по указанному расстоянию оптического волокна" }
];

const images = [
  {
    type: "image",
    img: img,
  },
];

function Opt() {
  return (
    <TechnicalSolutionsContent
      functions={functions}
      extensions={extensions}
      route={window.location.pathname}
      title="Риск-ориентированная система учета телекоммуникационных активов"
      description="Решение предназначено для автоматизации процессов проектирования, учета, и эксплуатации телекоммуникационных систем"
      images={images}
    />
  )
}

export default Opt;