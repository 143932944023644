import React from "react";

function Article3() {
  return (
  <div className="mt-4 mb-4">
    <p>Автоматизированная информационная система NPT Platform и система управления и внесена в единый реестр российских программ для электронных вычислительных машин и баз данных Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации.</p>
    <a href="https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748"  rel="noopener noreferrer" target="_blank" className="text-links">Реестровая запись №7803 от 14.12.2020</a>
  </div>
  );
}
export default Article3;
