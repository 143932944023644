import React from "react";
import Layout from "../../../components/Layout/Layout";
import title from "../../../images/energyFacilities/title.png";
import Parameters from "../../../components/Parameters/Parameters";

const descriptionLi = [
  "Ведение реестра проектируемого оборудования ВЭС",
  "Ведение проектов (титулов) реконструкции/строительства ВЭС",
  "Ведение справочника подразделений, подрядных организаций и сотрудников",
  "Выполнение подрядными организациями (поставщиками) отметки выполнения этапов работ с возможностью загрузки официальных писем",
  "Осуществление мобильного доступа к системе и получения информации об оборудовании с помощью QR-кодов",
  "Осуществление контроля и анализа инцидентов, возникающих в рамках реконструкции/строительства ВЭС",
  "Ведение договоров и официальной переписки с привязкой к проекту (титулу) реконструкции/строительства ВЭС",
  "Формирование финансовой и проектной отчетности по проектам (титулам) реконструкции/строительства ВЭС",
];

function EnergyFacilities({ openPopup }) {
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="Система автоматизации проектирования объектов ветроэнергетики"
          image={title}
          openPopup={openPopup}
          descriptionTitle="Основные функции системы:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default EnergyFacilities;
