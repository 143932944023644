

import React from "react";
import { AppRoute } from "../../../constants";
import image from '../../../images/standardExtensions/message.png'
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";

const descriptionLi = ['приближение сроков технического обслуживания', 'изменение оценки технического состояния устройств',
'регистрация технологического нарушения и т.д.'];

const descriptionLinks = [
  { link: AppRoute.Rpa, title: "Техническое решение по АСМ РЗА" },
  { link: AppRoute.Maintanance, title: "Техническое решение по управлению мероприятиями ТОиР первичного оборудования" },
  {
    link: AppRoute.Build,
    title:
      "Техническое решение по ведению проектов строительства и реконструкции объектов ветроэнергетики",
  },
];
const description = [
  'Расширение message-plugin предназначено для отображения и настройки оповещений о событиях, возникающих в рамках автоматизируемых бизнес процессов',
]

function MessagePlugin({openPopup}) {
  const route = window.location.pathname;
  return (
    <StandardExtensionsDescription 
      route={route} 
      title={'message-plugin'} 
      titleRu={'Формирование автоматических уведомлений'}
      description={description}
      list={descriptionLinks}
      descriptionLi={descriptionLi}
      listTitle={'Технические решения с применением message-plugin: '}
      image={image}
      descriptionLiTitle={'Примером событий для формирования оповещения может быть:'}
      openPopup={openPopup}
    />
  );
}

export default MessagePlugin;
