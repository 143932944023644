import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { VideoSide } from "../Popup/VideoSide";
import PrevAndNextButtons from "../prevAndNextButtons/PrevAndNextButtons";
import { AppRoute, pluginsLinks } from "../../constants";
import Zoom from "../../images/icons/zoom";

function StandardExtensionsDescription({
  list,
  title,
  description,
  listTitle,
  route,
  video,
  image,
  openPopup,
  descriptionLi,
  descriptionLiTitle,
  children,
  titleRu
}) {
  const vidRef = useRef(null);
  const [isPlayButton, setIsPlayButton] = useState(true);
  return (
    <Layout>
      <main className="main">
        <h1 style={{textAlign: 'center'}}>{titleRu}</h1>
        <h4>{title}</h4>
        <div className="align-start mt-4">
          {description.map((i) => (
            <p key={i} style={{ fontSize: "20px" }}>
              {i}
            </p>
          ))}

          {descriptionLi && (
            <>
              <h5 className="mb-3 mt-3">{descriptionLiTitle}</h5>
              <ul className="mb-4 description__list">
                {descriptionLi.map((i) => (
                  <li className="mb-1 description__circle-li" key={i}>
                    {i}
                  </li>
                ))}
              </ul>
            </>
          )}
          {video && (
            <VideoSide
              isOpen={true}
              active={true}
              vidRef={vidRef}
              image={video}
              setIsPlayButton={setIsPlayButton}
              isPlayButton={isPlayButton}
              width={"100%"}
            />
          )}
          {image && (
            <div
              className="parameters__image-container"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <img
                src={image}
                alt="Образец работы"
                style={{ width: "100%" }}
              />
              <button
                className="parameters__zoom-button link"
                onClick={() => openPopup({ image: image })}
              >
                <Zoom style={{ width: "100%" }} zoomColor={"black"} />
              </button>
            </div>
          )}
          {children}
          <h5 className="mb-3 mt-3">{listTitle}</h5>
          <ul className="mb-4 description__list">
            {list.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                <Link to={i.link} className="link text-links">
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <PrevAndNextButtons route={route} links={pluginsLinks} buttonName="расширению"/>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default StandardExtensionsDescription;
