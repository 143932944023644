import React from "react";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";
import { Link } from "react-router-dom";

const descriptionLi = [
  {
    title:
      " с составлением отчета о предпроектном обследовании или изучение существующего отчета о предпроектном обследовании",
    link: AppRoute.SurveyReport,
    linkText: "Проведение предпроектного обследования",
  },
  {
    title:
      " или изучение существующего технического задания на создание системы",
    link: AppRoute.TechnicalRequirements,
    linkText: "Разработка технического задания на создание системы",
  },
  {
    title: " или изучение существующего технического проекта на систему",
    link: AppRoute.ProjectDocumentation,
    linkText: "Разработка технического проекта на систему",
  },
  {
    title: "Описание интерфейса пользователя внедряемой системы",
  },
  {
    title: "Определение операций пользователей во внедряемой системе",
  },
  {
    title: "Определение операций администраторов во внедряемой системе",
  },
  {
    title:
      "Определение операций куратора информационной безопасности во внедряемой системе",
  },
  {
    title: "Описание действий в аварийных ситуациях",
  },
  {
    title: "Описание действий по установке и настройке внедряемой системы",
  },
  {
    title: "Разработка руководства пользователя внедряемой системы",
  },
  {
    title: "Разработка руководства администратора внедряемой системы",
  },
  {
    title:
      "Разработка руководства куратора информационной безопасности внедряемой системы",
  },
  {
    title: "Разработка описания матрицы ролей и полномочий внедряемой системы",
  },
  {
    title: "Разработка описания настроек внедряемой системы",
  },
];

function OperationalDocumentation() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-5">Разработка эксплуатационной документации</h1>
        <div className="align-start">
          {/* <p>
            Компетенция специалистов нашей компании и{" "} 
            <Link className="text-links" to={AppRoute.Partners}>компаний партнеров</Link>,
            а также богатый <Link className="text-links"  to={AppRoute.Implementation}>опыт внедрений</Link>{" "}
            автоматизированных систем на базе NPT
            Platform, дает возможность максимально быстро и эффективно
            осуществлять разработку эксплуатационной документации на
            автоматизированную систему.
          </p> */}
          <p>
            Компетенция специалистов нашей компании и компаний партнеров, а
            также богатый{" "}
            <Link className="text-links" to={AppRoute.Implementation}>
              опыт внедрений
            </Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно осуществлять разработку
            эксплуатационной документации на автоматизированную систему.
          </p>
          <p>
            Разработка эксплуатационной документации на автоматизированную
            систему осуществляется в соответствии с ГОСТ Р 59795-2021
            Информационные технологии. Комплекс стандартов на автоматизированные
            системы. Автоматизированные системы. Требования к содержанию
            документов.
          </p>
          <p className="description__title">
            Разработка эксплуатационной документации на автоматизированную
            систему (далее – Услуга) осуществляется в рамках договора на
            внедрение информационной системы или по отдельному договору на
            оказание Услуги, и включает в себя следующие этапы:
          </p>
          {/* <h5 className="mb-3 mt-3">
            Внедрение автоматизированных систем на базе NPT Platform (далее –
            систем) условно можно разделить на следующие этапы:
          </h5> */}
          <ul className="description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                {i.link && (
                  <Link className="text-links" to={i.link}>
                    {i.linkText}
                  </Link>
                )}
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default OperationalDocumentation;
