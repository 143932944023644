import React from "react";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";
import { Link } from "react-router-dom";

const descriptionLi = [
  {
    title:
      " с составлением отчета о предпроектном обследовании или изучение существующего отчета о предпроектном обследовании",
    link: AppRoute.SurveyReport,
    linkText: "Проведение предпроектного обследования",
  },
  {
    title:
      " или изучение существующего технического задания на создание системы",
    link: AppRoute.TechnicalRequirements,
    linkText: "Разработка технического задания на создание системы",
  },
  {
    title: " или изучение существующего технического проекта на систему",
    link: AppRoute.ProjectDocumentation,
    linkText: "Разработка технического проекта на систему",
  },
  {
    title: " или изучение существующей эксплуатационной документации",
    link: AppRoute.OperationalDocumentation,
    linkText: "Разработка эксплуатационной документации на систему",
  },
  {
    title: "Описание объекта испытаний",
  },
  {
    title: "Определение цели испытаний",
  },
  {
    title: "Проверка перечня документов, используемых при разработке ПМИ",
  },
  {
    title: "Определение перечня проводимых испытаний",
  },
  {
    title:
      "Проверка перечня документов, на основании которых проводят испытания",
  },
  {
    title: "Описание действий по установке и настройке внедряемой системы",
  },
  {
    title:
      "Определение и согласование места и продолжительность проведения испытаний",
  },
  {
    title: "Определение состава участников испытаний",
  },
  {
    title: "Определение перечня ранее проведенных испытаний",
  },
  {
    title: "Проверка состава предъявляемой на испытания документации",
  },
  {
    title: "Определение мероприятий по подготовке к испытаниям",
  },
  {
    title: "Определение порядка привлечения экспертов",
  },
  {
    title: "Определение объема и назначения испытаний",
  },
  {
    title: "Определение условий начала испытаний",
  },
  {
    title: "Определение порядка проведения испытаний",
  },
  {
    title: "Определение условий завершения испытаний",
  },
  {
    title: "Определение формы отчетности по проведенным испытаниям",
  },
  {
    title: "Разработка программы и методики испытаний",
  },
  {
    title: "Проведение испытаний",
  },
  {
    title: "Формирование отчётности по проведенным испытаниям",
  },
];

//Разработка отчета о предпроектном обследовании
function TestProgramsAndMethods() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-5">Разработка программы и методики испытаний</h1>
        <div className="align-start">
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link className="text-links" to={AppRoute.Partners}>компаний партнеров</Link>, а также
            богатый <Link className="text-links" to={AppRoute.Implementation}>опыт внедрений</Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно осуществлять разработку
            эксплуатационной документации на автоматизированную систему.
          </p> */}
          <p>
            Компетенция специалистов нашей компании и компаний партнеров, а
            также богатый{" "}
            <Link className="text-links" to={AppRoute.Implementation}>
              опыт внедрений
            </Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно осуществлять разработку
            эксплуатационной документации на автоматизированную систему.
          </p>
          <p>
            Разработка программы и методики испытаний автоматизированной системы
            осуществляется в соответствии с ГОСТ Р 59792-2021 Информационные
            технологии. Комплекс стандартов на автоматизированные системы. Виды
            испытаний автоматизированных систем.
          </p>
          <h5 className="description__title">
            Разработка программы и методики испытаний автоматизированной системы
            (далее – Услуга) осуществляется в рамках договора на внедрение
            информационной системы или по отдельному договору на оказание
            Услуги, и включает в себя следующие этапы:
          </h5>
          <ul className="description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                {i.link && (
                  <Link className="text-links" to={i.link}>
                    {i.linkText}
                  </Link>
                )}
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default TestProgramsAndMethods;
