import React from "react";
import "./Advantages.scss";
import image1 from "../../images/Advantages/R1.svg";
import image2 from "../../images/Advantages/R2.svg";
import image3 from "../../images/Advantages/R3.svg";

const columns = [
  {
    id: 1,
    image: image1,
    title: "Гибкие инструменты автоматизации бизнес-процессов",
    paragraph:
      "Комплексная автоматизация бизнес-процессов предприятия с подстройкой под Ваши задачи",
  },
  {
    id: 2,
    image: image2,
    title:
      "Стандартизированная информационная модель на базе CIM (Common Information Model)",
    paragraph:
      "Получайте оперативный доступ к нужной информации из всего массива данных предприятия за счет хранения, каталогизации и использования данных в универсальном формате",
  },
  {
    id: 3,
    image: image3,
    title: "Широкие возможности визуализации информации",
    paragraph:
      "Выбирайте графическое представление информации подходящее для Ваших задач",
  },
];

function  Advantages({ openPopup }) {
  return (
    <section className="advantages">
      <div className="row advantages__row">
        {columns.map((i) => (
          <div
            className="col advantages__columns"
            onClick={() => openPopup(i.id)}
            key={i.id}
          >
            <img
              style={{ width: "100%" }}
              src={i.image}
              alt="сетка из квадратов белого цвета, на них еще несколько блоков квадрата в произвольной последовательности "
            />
            <h6 className="mt-3 mb-4" style={{ fontSize: "1.2rem" }}>
              {i.title}
            </h6>
            <p style={{ color: "var(--grey)" }}>{i.paragraph}</p>
          </div>
        ))}
      </div>
      {/* <Link to="https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748" target='_blank' rel="noopener noreferrer" className="advantages__logo link">
        <img src={minsvyazi} className="advantages__logo-img" />
        <p className="advantages__logo-p">Внесено в реестр Минкомсвязи</p>
      </Link> */}
    </section>
  );
}

export default Advantages;
