import React, { useEffect, useLayoutEffect, useRef } from "react";
import Layout from "../../../components/Layout/Layout";
import title from "../../../images/standardExtensions/developerPlugin/title.png";
import "./DeveloperPlugin.scss";
import DescriptionDeveloperPlugin from "../../../components/descriptionDeveloperPlugin/DescriptionDeveloperPlugin";
import { AppRoute, pluginsLinks } from "../../../constants";
import PictureWithZoom from "../../../components/pictureWithZoom/PictureWithZoom";
import PrevAndNextButtons from "../../../components/prevAndNextButtons/PrevAndNextButtons";
import { Link } from "react-router-dom";


function DeveloperPlugin({ openPopup }) {
  const route = window.location.pathname;
  const scripts = useRef(null);
  const view = useRef(null);
  const profile = useRef(null);
  const menuEd = useRef(null);
  const refTree = useRef(null);
  const table = useRef(null);

  const descriptionLi = [
    { ref: scripts, title: "Редактор скриптов" },
    { ref: view, title: "Редактор отображения" },
    { ref: profile, title: "Редактор профиля" },
    { ref: menuEd, title: "Редактор меню" },
    { ref: refTree, title: "Редактор деревьев" },
    { ref: table, title: "Редактор таблиц" },
  ];

  const handleClick = (i) => {
    i.current.scrollIntoView({ behavior: "smooth", block: "start" });

  };

  return (
    <Layout>
      {/* <main className="main" style={{display: 'block'}}> */}
      <main className="main" >
        <h1 className="mb-1 w-70 text-center">Инструменты разработки платформы</h1>
        <h4 className="mb-5 w-70 text-center">developer-plugin</h4>
        <div className="mb-5 row mt-3">
        <div className="col">
        <p>
            Расширение developer-plugin предназначено для разработки скриптов
            автоматизации, различных форм представления информации, а также
            формирования профиля информационной модели.
          </p>
          <p>
            Расширение developer-plugin содержит встроенную документацию с
            примерами для реализации различных задач в части автоматизации
            бизнес-процессов, отображения информации, интеграции со сторонними
            системами.
          </p>
          <h5 className="mb-3">Основные инструменты developer-plugin</h5>
          <ul style={{ paddingLeft: "25px", paddingRight: "25px" }}>
            {descriptionLi.map((i) => (
              <li
                className="mb-1 developer__description text-links"
                
                onClick={() => handleClick(i.ref)}
                key={i.title}
              >
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="col">
            <PictureWithZoom image={title} openPopup={openPopup} zoomColor='white'/>
        </div>
      </div>
      <DescriptionDeveloperPlugin
              scripts={scripts}
              view={view}
              profile={profile}
              menuEd={menuEd}
              refTree={refTree}
              table={table}
            />
      
      <div className="w-100 d-flex flex-column align-items-center">
      <PrevAndNextButtons route={route} links={pluginsLinks} buttonName="расширению"/>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </div>
      </main>
    </Layout>
  );
}

export default DeveloperPlugin;
