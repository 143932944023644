import React from "react";
import image from "../../images/Rosseti/title.png"
const description = ['С целью перехода на отечественное программное и аппаратное обеспечение были проведены испытания Автоматизированной информационной системы NPT Platform, разработанной российской группой компаний «ЭнергопромАвтоматизация». Цель испытаний – проверка совместимости NPT Platform с отечественной операционной системой «Альт рабочая станция» производства компании «Базальт СПО» и отечественной аппаратной платформой с установленным процессором Baikal-M, производства ООО «АйСиЭл Техно».', 
        'Испытания проводились на опытном образце на базе NPT Platform - ПТК «Эксплуатация», установленном на персональной электронной вычислительной машине. По результатам проведения испытания было зафиксировано, что для функционирования NPT Platform производительности процессора Baikal-M достаточно как для применения на серверных машинах, так и для применения в составе автоматизированных рабочих мест.',
        'По результатам испытаний подтверждена совместимость автоматизированной информационной системы NPT Platform с отечественной операционной системой и аппаратной платформой.'
      ];

function Article7() {
  return (
  <div className="mt-4 mb-4">
    {description.map(p => (
      <p key={p}>{p}</p>
    ))}
    <figure className="sign">
      <img src={image} className="w-100" alt="Главная страница опытного образца на базе NPT Platform – ПТК «Эксплуатация»"/>
      <figcaption style={{marginLeft: '25px'}}>Главная страница опытного образца на базе NPT Platform – ПТК «Эксплуатация»</figcaption>
    </figure>    
  </div>
  );
}
export default Article7;
