import React from "react";
import image from "../../images/technicalSolutions/РСТ.svg";
import './gost.scss'

function GOST() {
  return (
    <div className="gost__container">
      <img src={image} alt="логотип государственного стандарта" style={{marginRight: '26px', width: '70px', height: 'fit-content'}}/>
      <h5 className="gost__container-text">
      Информационная модель технического решения разработана в соответствии с серией ГОСТ Р 58651. Единая энергетическая система и изолированно работающие энергосистемы. Информационная модель электроэнергетики
      </h5>
    </div>
  );
}

export default GOST;
