import React, { useRef, useState } from "react";
import Slider from "react-slick";
import tree from "../../images/ImagePopup/visualInformation/1_tree.png";
import card from "../../images/ImagePopup/visualInformation/2_card.png";
import table from "../../images/ImagePopup/visualInformation/3_table.png";
import mapInCard from "../../images/ImagePopup/visualInformation/mapInCard.webm";
import gantEdit2 from "../../images/ImagePopup/visualInformation/gant2Fast.webm";
import gantEdit1 from "../../images/ImagePopup/visualInformation/gantEdit1.webm";
import elementInCard from "../../images/ImagePopup/visualInformation/5_elementInCard.png";
import constructor from "../../images/ImagePopup/visualInformation/constructor.webm";
import search from "../../images/ImagePopup/visualInformation/search.webm";
import img10 from "../../images/ImagePopup/visualInformation/10.png";
import oscilloscope from "../../images/ImagePopup/visualInformation/oscilloscope.webm";
import scShemeEdit2 from "../../images/ImagePopup/visualInformation/scShemeEdit2.webm";
import scSchemeEdit from "../../images/ImagePopup/visualInformation/scSchemeEdit.webm";
import substationEditor from "../../images/ImagePopup/visualInformation/substationEditFast.webm";
import indicators from "../../images/ImagePopup/visualInformation/indicators.webm";
import indicatorsSecurity from "../../images/ImagePopup/visualInformation/14_indicatorsSecurity.png";
import { VideoSide } from "./VideoSide";

function VisualInformation({ isOpen }) {
  const settings = {
    dots: true,
    infinite: false,
    customPaging: (i) => <div className="custom-slick-dots">{i + 1}</div>,
  };

  const vidRef1 = useRef(null);
  const vidRef2 = useRef(null);
  const vidRef3 = useRef(null);
  const vidRef4 = useRef(null);
  const vidRef5 = useRef(null);
  const vidRef6 = useRef(null);
  const vidRef7 = useRef(null);
  const vidRef8 = useRef(null);
  const vidRef9 = useRef(null);
  const vidRef10 = useRef(null);

  const [isPlayButton, setIsPlayButton] = useState(true);
  const [active, setActive] = useState(0);

  const images = [
    {
      type: "image",
      id: 1,
      image: tree,
      title: "Отображение информации в виде иерархической структуры (дерева)",
    },
    {
      id: 2,
      type: "image",
      image: card,
      title: "Отображение информации в виде карточки",
    },
    {
      id: 3,
      type: "image",
      image: table,
      title: "Отображение информации в виде таблицы",
    },
    {
      id: 4,
      type: "video",
      image: mapInCard,
      vidRef: vidRef1,
      title:
        "Отображение информации в виде графических элементов (карты) внутри карточки",
    },
    {
      id: 5,
      type: "image",
      image: elementInCard,
      title:
        "Отображение информации в виде графических элементов внутри карточки",
    },
    {
      id: 6,
      type: "video",
      image: gantEdit1,
      vidRef: vidRef2,
      title:
        "Отображение информации в виде диаграммы Ганта (режим редактирования)",
    },
    {
      id: 7,
      type: "video",
      image: gantEdit2,
      vidRef: vidRef3,
      title:
        "Отображение информации в виде диаграммы Ганта (инструменты фильтрации)",
    },
    {
      id: 8,
      type: "video",
      image: constructor,
      vidRef: vidRef4,
      title: "Осуществление поиска информации с помощью конструктора",
    },
    {
      id: 9,
      type: "video",
      vidRef: vidRef5,
      image: search,
      title: "Осуществление поиска информации с помощью текстового поиска",
    },
    {
      id: 10,
      type: "image",
      image: img10,
      title: "Просмотр осциллограмм во встроенном осциллографе",
    },
    {
      id: 11,
      type: "video",
      image: oscilloscope,
      vidRef: vidRef9,
      title: "Просмотр осциллограмм во встроенном осциллографе",
    },
    {
      id: 12,
      type: "video",
      vidRef: vidRef8,
      image: scShemeEdit2,
      title:
        "Редактирование схем сети в редакторе сети с отображением на карте подстанций, ЛЭП, опор ЛЭП",
    },
    {
      id: 13,
      image: scSchemeEdit,
      type: "video",
      vidRef: vidRef6,
      title: "Редактирование схем сети в редакторе сети",
    },
    {
      id: 14,
      type: "video",
      image: substationEditor,
      vidRef: vidRef10,
      title: "Редактирование схем подстанций в редакторе подстанций",
    },
    {
      id: 15,
      image: indicators,
      type: "video",
      vidRef: vidRef7,
      title: "Отображение индикаторов загрузки сервера",
    },
    {
      id: 16,
      type: "image",
      image: indicatorsSecurity,
      title: "Отображение событий информационной безопасности",
    },
  ];

  return (
    <div style={{ padding: "0 40px 20px", width: "90vw" }}>
      <Slider {...settings} afterChange={(idx) => setActive(idx)}>
        {images.map((i) => {
          return (
            <div className="popup-slider__container" key={i.id}>
              <div>
                <h5
                  className="mb-4 popup-slider__image-title"
                  style={{ color: "var(--white)" }}
                >
                  {i.title}
                </h5>
                {i.type === "video" ? (
                  <VideoSide
                    isOpen={isOpen}
                    active={active === i.id}
                    vidRef={i.vidRef}
                    image={i.image}
                    setIsPlayButton={setIsPlayButton}
                    isPlayButton={isPlayButton}
                  />
                ) : (
                  <img
                    className="popup-slider__image"
                    src={i.image}
                    alt="задачи"
                  />
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default VisualInformation;
