import React from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../constants";

const list = [
  "ведение реестра устройств РЗА, АСУ ТП, СИ",
  "наблюдение за процессами эксплуатации оборудования РЗА, АСУ ТП, СИ подстанции;",
  "автоматизированный сбор информации с устройств для выполнения их мониторинга с целью перспективного перехода на техническое обслуживание по состоянию",
  "регистрация и анализ неисправностей и отказов устройств",
  "автоматизированный анализ функционирования устройств РЗА, АСУ ТП и СИ",
  "взаимодействие со смежными программными комплексами и прочими корпоративными информационными системами ПАО «ФСК – Россети»",
  "формирование отчетов по заданным шаблонам",
  "оценка состояния устройств релейной защиты и автоматики (РЗА)",
  "анализ работы РЗА с применением современных технологий",
  "интеграция со смежными системами",
  "графическая визуализация",
  "построение модели схемы электрической сети для расчёта токов короткого замыкания по фазным координатам",
  "автоматический выбор точек короткого замыкания (КЗ) и поиска расчётного режима КЗ",
  "расчёт уставок устройств РЗА",
  "отображение осциллограмм аварийных процессов",
];

function Article9() {
  return (
    <div className="mt-4 mb-4">
      <p>
        <a
          href="https://www.rosseti.ru/suppliers/scientific-and-technical-development/niokr/reestr/?ELEMENT_ID=170836"
          rel="noopener noreferrer"
          target="_blank"
          className="text-links"
        >
          Система автоматической диагностики и повышения эффективности
          обслуживания устройств РЗА,
        </a>{" "}
        <a
          href="https://www.rosseti.ru/suppliers/scientific-and-technical-development/niokr/reestr/?ELEMENT_ID=170836"
          rel="noopener noreferrer"
          target="_blank"
          className="text-links"
        >
          АСУ ТП и средств измерений ПС - ПТК «Эксплуатация»,
        </a>
        {
          " разработанная в рамках выполнения НИОКР для ПАО «Федеральная сетевая компания – Россети», включена в "
        }
        <a
          href="https://reestr.digital.gov.ru/reestr/1578353/"
          rel="noopener noreferrer"
          target="_blank"
          className="text-links"
        >
          Единый реестр российских программ для электронных вычислительных машин
          и баз данных Министерства цифрового развития, связи и массовых
          коммуникаций Российской Федераци.
        </a>
      </p>
      <p>
        Это полностью импортонезависимое решение, предназначенное для
        автоматизации труда персонала, эксплуатирующего устройства релейной
        защиты и автоматики (РЗА), автоматизированные системы управления
        технологическими процессами (АСУ ТП) и средства измерения (СИ).
      </p>
      <p>
        {
          "ПТК «Эксплуатация» представляет собой распределенную корпоративную систему, реализованную на базе автоматизированной информационной платформы "
        }
        <Link to={AppRoute.Rosseti} className="text-links">NPT Platform</Link>
        {" разработанной ГК «ЭнергопромАвтоматизация»."}
      </p>
      <p>Программный комплекс позволяет выполнять следующие функции:</p>
      <ul className="article__list w-100" >  
        {list.map((li) => (
          <li key={li}>{li}</li>
        ))}
      </ul>
      <p>
        Использование ПТК «Эксплуатация» полностью соответствует условиям
        выполнения Приказа Министерства связи и массовых коммуникаций Российской
        Федерации «О необходимости соблюдения государственными заказчиками
        требований по защите информации» от 28.04.2016., выпущенного во
        исполнение постановления Правительства РФ №1236 от 16.11.2015 «Об
        установлении запрета на допуск программного обеспечения, происходящего
        из иностранных государств, для целей осуществления закупок для
        обеспечения государственных и муниципальных нужд».
      </p>
    </div>
  );
}
export default Article9;
