import React from "react";
import image from "../../images/News/elektronnyj_katalog.webp";
const description = [
  "Программное обеспечение электронного каталога разработано ГК «ЭнергопромАвтоматизация» по заданию ПАО «Россети Ленэнерго».",
  "Основными целями создания Электронного каталога являются упрощение процесса выбора оборудования, ускорение расчетов укрупненных технических решений и капитальных затрат и формирование единой платформы по обмену данными между всеми участниками процесса создания высокоавтоматизированных РЭС — от проектировщиков до поставщиков решений.",
  "В рамках ПТК «Эксплуатация» реализовано три вида мониторинга работы оборудования: непрерывный, периодический и ситуационный.",
];

const list = [
  "формирование и ведение каталогов оборудования ЦРЭС",
  "формирование и ведение перечня мультибрендовых решений для ЦРЭС с учетом коэффициентов стоимости и надежности",
  "ранжирование функциональных единиц типовых элементов объектов ЦРЭС",
  "формирование и ведение объектов ЦРЭС",
  "формирование и ведение модернизируемых объектов ЦРЭС",
  "ранжирование мультибрендовых технических решений для типового элемента объекта ЦРЭС",
  "формирование спецификации спроектированных объектов ЦРЭС",
  "расчет капитальных и операционных затрат на реализацию сформированного проекта",
];

const list2 = [
  "повысить качество предоставляемой информации по оборудованию и программным продуктам, применяемым на объектах ПАО «Ленэнерго»",
  "сократить временные затраты на подготовку, обработку данных по оборудованию и программным комплексам для Цифровых РЭС",
  "сократить временные затраты и минимизировать ошибки при выборе наименований оборудования при использовании типовых решений Цифровых РЭС",
  "создать единую среду взаимодействия и обмена данными по техническим решениям и спецификациям Цифровых РЭС",
];

function Article4() {
  return (
    <div className="mt-4 mb-4 d-flex flex-column align-items-center">
      {description.map((p) => (
        <p key={p}>{p}</p>
      ))}
      <ul className="article__list w-100">
        {list.map((li) => (
          <li key={li}>{li}</li>
        ))}
      </ul>
      <img
        src={image}
        alt="Мониторинг работы устройств"
        className="m-4"
        style={{ width: "fit-content" }}
      />
      <p>
        На завершающем этапе работы была выполнена апробация разработанных
        технических решений и методических рекомендаций в форме выполнения 2-х
        эскизных проектов Цифрового РЭС («городского» и «сельского»). Каждый
        эскизный проект включал в себя по три варианта технических решений по
        модернизации распределительной сети исследуемого участка РЭС. Для
        каждого из вариантов были сформированы спецификации и выполнены расчеты
        капитальных и операционных затрат.
      </p>
      <p className="w-100">
        Апробация Электронного каталога показала, что его применение позволит:
      </p>
      <ul className="w-100">
        {list2.map((li) => (
          <li className="article__list_sec" key={li}>
            {li}
          </li>
        ))}
      </ul>
      <p>
        Подробнее:
        <a
          style={{ marginLeft: "10px" }}
          rel="noopener noreferrer"
          target="_blank"
          href="https://rosseti-lenenergo.ru/press/lenenergo/130918.html"
          className="text-links"
        >
          https://rosseti-lenenergo.ru/press/lenenergo/130918.html
        </a>
      </p>
    </div>
  );
}
export default Article4;
