import { useNavigate } from "react-router-dom";
import "./PrevAndNextButtons.scss";

function PrevAndNextButtons({ route, links, buttonName }) {
  const navigate = useNavigate();
  const index = links.findIndex((link) => link.link === route);
  const nexLink = links[index + 1];
  const prevLink = links[index - 1];
  const maxLength = links.length - 1;
  const disabledPriv = index === 0;
  const disabledNext = index === maxLength;
  return (
    <div style={{ alignSelf: "center"}} >
      {!disabledPriv && (
        <button
          className={
            "technicalSolutionsButtons__button technicalSolutionsButtons__button_active"
          }
          type="button"
          style={{ marginRight: "10px" }}
          aria-label={`К предыдущему  ${buttonName}`}
          onClick={() => navigate(prevLink.link)}
          disabled={disabledPriv}
        >
          {`К предыдущему  ${buttonName}`}
        </button>
      )}

      {!disabledNext && (
        <button
          className={
            "technicalSolutionsButtons__button technicalSolutionsButtons__button_active"
          }
          type="button"
          aria-label={`К следующему  ${buttonName}`}
          onClick={() => navigate(nexLink.link)}
          disabled={disabledNext}
        >
          {`К следующему  ${buttonName}`}
        </button>
      )}
    </div>
  );
}

export default PrevAndNextButtons;
