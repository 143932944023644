import React from "react";
import title from "../../../images/Rosseti/title.png";
import Parameters from "../../../components/Parameters/Parameters";
import image1 from "../../../images/pdf/img/rosseti0.png";
import image2 from "../../../images/pdf/img/rosseti1.png";
import image3 from "../../../images/pdf/img/rosseti2.png";
import image4 from "../../../images/pdf/img/rosseti3.png";
import image5 from "../../../images/pdf/img/rosseti4.png";
import image6 from "../../../images/pdf/img/rosseti5.png";
import image7 from "../../../images/pdf/img/rosseti6.png";
import image8 from "../../../images/pdf/img/rosseti7.png";
import image9 from "../../../images/pdf/img/rosseti8.png";
import image10 from "../../../images/pdf/img/rosseti9.png";
import image11 from "../../../images/pdf/img/rosseti10.png";
import image12 from "../../../images/pdf/img/rosseti11.png";
import image13 from "../../../images/pdf/img/rosseti12.png";
import image14 from "../../../images/pdf/img/rosseti13.png";
import image15 from "../../../images/pdf/img/rosseti14.png";
import image16 from "../../../images/pdf/img/rosseti15.png";
import image17 from "../../../images/pdf/img/rosseti16.png";
import image18 from "../../../images/pdf/img/rosseti17.png";
import image19 from "../../../images/pdf/img/rosseti18.png";
import image20 from "../../../images/pdf/img/rosseti19.png";
import Layout from "../../../components/Layout/Layout";
import minsvyazi from "../../../images/minsvyazi.png";
import roosetiLogo from "../../../images/Rosseti/rosseti-logo-small.svg";

const descriptionLi = [
  "Ведение реестра устройств РЗА, АСУ ТП, СИ с привязкой к месту установки и функциональным местам",
  "Ведение реестра ЛЭП с учетом параметров схемы замещения ЛЭП",
  "Ведение перечней СИ по категориям (сфера ГРОЕИ, вне сферы ГРОЕИ, категория «Индикатор», средства калибровки и эталоны и т.п.)",
  "Управление процессами эксплуатации оборудования РЗА, АСУ ТП подстанции",
  "Управление процессами эксплуатации оборудования СИ подстанции (поверка, калибровка, контроль исправности, аттестация и т.п.)",
  "Ведение заданий от АО «СО ЕЭС» и от собственников на изменение параметров срабатывания устройств РЗА",
  "Ведение нормативно-справочной информации",
  "Ведения производителями устройств данных по своим продуктам, поставляемым ПАО «Россети» (устройствам, шкафам)",
  "Ведение нормативно-технической документации ПАО «Россети»",
  "Ведение данных о персонале, в том числе, допусков персонала к выполнению определенных видов работ",
  "Ведение титулов реконструкции и строительства объектов ПАО «Россети»",
  "Ведение и расчет ключевых показателей эффективности",
  "Формирование отчетов по заданным шаблонам и в свободной форме",
  "Формирование и ведение журналов в электронном виде",
  "Формирование оповещений для напоминания или информирования о событиях, возникающих в автоматизируемых бизнес-процессах",
  "Автоматизированный сбор информации с устройств для выполнения их мониторинга с целью перспективного перехода на техническое обслуживание по состоянию",
  "Автоматизированный анализ состояния устройств РЗА с выполнением оценки состояния",
  "Анализ сети ЛВС ПС на соответствие конфигурации, указанной в SCD файле подстанции",
  "Регистрация и анализ неисправностей и отказов устройств",
  "Регистрация технологических нарушений, происходящих на объектах ПАО «Россети»",
  "Просмотр и анализ осциллограмм в браузере без необходимости устанавливать дополнительное программное обеспечение",
  "Автоматизированный анализ работы РЗА с применением современных технологий",
  "Графическая визуализация схем ПС, ЛЭП, электрической сети, расчетных данных и уставок РЗА",
  "Построение модели электрической сети для расчёта токов короткого замыкания по фазным координатам",
  "Выполнение расчёта токов КЗ в фазных величинах для построенных моделей электрической сети",
  "Расчёт параметров срабатывания устройств РЗА (обязательных и индивидуальных) по выбранным точкам КЗ и расчетным режимам",
  "Хранение параметров срабатывания и результатов расчета уставок устройств РЗА",
  "Открытая платформа для расчета уставок ЭМ РЗА и МП РЗА различных производителей",
  "Взаимодействие со смежными программными комплексами (АСУ РЭО) и корпоративными информационными системами ПАО «Россети» (АСУЕ, АС ВЭЖД, СУПА, САПР ЭК РЗА, ПТК Приемка)",
];

const link = {
  text: "Эффекты от внедрения",
  img: roosetiLogo,
  color: "var(--blue)",
  link: "https://www.rosseti.ru/suppliers/scientific-and-technical-development/niokr/reestr/?ELEMENT_ID=170836",
};

function Rosseti({ openPopup }) {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
  ];
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="ПТК «Эксплуатация» (ПАО «Россети»)"
          logo={minsvyazi}
          image={title}
          images={images}
          linkR={link}
          openPopup={openPopup}
          zoomColor="white"
          descriptionTitle="Основные функции системы:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default Rosseti;
