import { AppRoute } from "../../constants";
import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";
import image from "../../images/small-robot.png";
import Layout from "../../components/Layout/Layout";

function NotFound() {
  return (
    <Layout>
      <main className="not-found">
        <h2 className="not-found__title">404</h2>
        <p className="not-found__description">Страница не найдена</p>
        <div className="not-found__container">
          <img src={image} className="not-found__img" alt="грустный робот" />
          <Link to={AppRoute.MainPage}>
            <button
              className="not-found__button button-hover"
              type="button"
              aria-label="вернуться назад"
            >
              На главную
            </button>
          </Link>
        </div>
      </main>
    </Layout>
  );
}

export default NotFound;
