import React from "react";
import title from "../../../images/ACM/title.png";
import Parameters from "../../../components/Parameters/Parameters";
import image1 from "../../../images/pdf/img/acm0.png";
import image2 from "../../../images/pdf/img/acm1.png";
import image3 from "../../../images/pdf/img/acm2.png";
import image4 from "../../../images/pdf/img/acm3.png";
import image5 from "../../../images/pdf/img/acm4.png";
import image6 from "../../../images/pdf/img/acm5.png";
import image7 from "../../../images/pdf/img/acm6.png";
import image8 from "../../../images/pdf/img/acm7.png";
import image9 from "../../../images/pdf/img/acm8.png";
import image10 from "../../../images/pdf/img/acm9.png";
import image11 from "../../../images/pdf/img/acm10.png";
import image12 from "../../../images/pdf/img/acm11.png";
import image13 from "../../../images/pdf/img/acm12.png";
import image14 from "../../../images/pdf/img/acm13.png";
import image15 from "../../../images/pdf/img/acm14.png";
import image16 from "../../../images/pdf/img/acm15.png";

import Layout from "../../../components/Layout/Layout";

const descriptionLi = [
  "Формирование базы данных оборудования электрических подстанций",
  "Сбор, хранение и передача информации о состоянии устройств РЗА",
  "Формирование моделей сети и оборудования электрических подстанций с использованием CIM (ГОСТ Р 58651.1-2019)",
  "Анализ технического состояния устройств РЗА",
  "Контроль изменения конфигурации устройств РЗА",
  "Автоматическая регистрация и расследование технологических нарушений электрических подстанций",
  "Отображение осциллограмм и их анализ с использованием методов машинного обучения",
  "Анализ функционирования устройств РЗА",
  "Отправка уведомлений о состоянии оборудования пользователям системы",
  "Формирование отчетов для пользователей по заданным шаблонам",
  "Диагностика технических средств и программного обеспечения",
];

function ACM({ openPopup }) {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
  ];
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="АСМ РЗА"
          image={title}
          images={images}
          openPopup={openPopup}
          zoomColor="white"
          descriptionTitle="Основные функции:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default ACM;
