import React from "react";
import { Link } from "react-router-dom";
import GOST from "../../../components/gost/GOST";
import Layout from "../../../components/Layout/Layout";
import { AppRoute, technicalSolutionsLinks } from "../../../constants";

import functionRSA from "../../../images/technicalSolutions/function.png";
import './Rpa.scss'
import PrevAndNextButtons from "../../../components/prevAndNextButtons/PrevAndNextButtons";


const functions = [
  {
    title: "формирование базы данных оборудования электрических подстанций",
  },

  {
    link: "https://gostassistent.ru/search?q=%D0%93%D0%9E%D0%A1%D0%A2+%D0%A0+58651",
    linkTitle: "(ГОСТ Р 58651)",
    title:
      "формирование моделей сети и оборудования электрических подстанций с использованием CIM ",
  },
  {
    title:
      "сбор данных мониторинга устройств РЗА от устройств, смежных информационных систем, выступающих источниками данных",
  },
  {
    title: "анализ технического состояния устройств РЗА",
  },
  {
    title: "контроль изменения уставок и конфигураций устройств РЗА",
  },
  {
    title:
      "автоматическая регистрация технологических нарушений на электрических подстанциях",
  },
  {
    title:
      "отображение осциллограмм и их анализ с использованием методов машинного обучения",
  },
  {
    title: "анализ функционирования устройств РЗА",
  },
  {
    title:
      "формирование справки с результатами анализа работы устройств РЗА на энергообъекте",
  },
  {
    title: "формирование рекомендаций по результатам мониторинга устройств РЗА",
  },
  {
    title: "уведомление пользователей",
  },
  {
    title: "диагностика технических средств и ПО",
  },
];

const functionsRSA = [
  "повышение надёжности функционирования устройств и комплексов РЗА",
  "обеспечение технологической возможности для перехода на техническое обслуживание оборудования РЗА по состоянию;",
  "автоматизация эксплуатационных функций персонала управления РЗА",
  "поддержка в принятии решения в части анализа функционирования устройств и комплексов РЗА",
];

const plugins = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
  {
    link: AppRoute.ScadaPlugin,
    title: "scada-plugin",
  },
  {
    link: AppRoute.MessagePlugin,
    title: "messages-plugin",
  },
  {
    link: AppRoute.DashboardPlugin,
    title: "dashboard-plugin",
  },
];


// function findLinkByRoute(route) {


//   const linkObject = technicalSolutionsLinks.find(item => item.link === route);
//   if (linkObject) {
//     return linkObject.title;
//   } else {
//     return "Ссылка не найдена";
//   }
// }

function Rpa() {
  const route = window.location.pathname;

  return (
    <Layout>
      <main className="main">
      <GOST />
        <h1 className="mb-4 text-center">
          Техническое решение по автоматизированной системе мониторинга релейной
          защиты
        </h1>
        <div className="align-start d-flex flex-column">
          <p>
            Автоматизированная система мониторинга релейной защиты (АСМ РЗА)
            представляет собой автоматизированную систему мониторинга релейной
            защиты и автоматики электрических подстанций, предназначенную для
            непрерывной оценки состояния устройств и комплексов РЗА и выполнения
            расчетно-аналитических задач, связанных с функционированием
            устройств и комплексов РЗА
          </p>
          <h5 className="mb-3 mt-3">
            Автоматизированная система мониторинга релейной защиты (АСМ РЗА)
            позволяет выполнять следующие функции:
          </h5>
          <ul className="mb-4 description__list">
            {functions.map((i) => (
              <li className="mb-1 mb-1 description__number-li" key={i.title}>
                {i.title}
                {i.link && (
                  <Link to={i.link} className="text-links">
                    {i.linkTitle}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <h2 className="mb-4">Структура АСМ РЗА</h2>
          <p>
            В состав АСМ РЗА входит программное обеспечение, выполняющее все
            функции АСМ РЗА нижнего уровня.
          </p>
          <p>
            <span style={{ fontWeight: "600" }}>Нижний уровень</span> АСМ
            представляет собой специализированное программное обеспечение
            станционного контроллера, которое предназначено для сбора и
            первичной обработки исходной информации на уровне энергообъекта.
          </p>
          <p>
            Установка программного обеспечения нижнего уровня АСМ РЗА
            выполняется на серверы c ОС Linux (в случае использования
            отечественной операционной системы должен быть установлен ALT
            Linux).
          </p>
          <p>
            В состав АСМ РЗА верхнего уровня входит программно-технический
            комплекс АСМ РЗА верхнего уровня.
          </p>
          <p>
            <span style={{ fontWeight: "600" }}>Верхним уровнем АСМ</span> может
            является:{" "}
            <Link className="text-links" to={AppRoute.Rosseti}>
              экземпляр ПТК «Эксплуатация»
            </Link>{" "}
            установленный на уровне предприятия или программное обеспечение АСМ
            РЗА верхнего уровня на базе NPT Platform.
          </p>
          <p>
            Установка программного обеспечения верхнего уровня АСМ РЗА
            выполняется на серверы c ОС Linux (в случае использования
            отечественной операционной системы должен быть установлен ALT
            Linux).
          </p>
          <p>
            При проектировании АСМ РЗА предусматривается возможность аппаратного
            и программного расширения.
          </p>
          <h2 className="mb-4">Программное и аппаратное обеспечение АСМ РЗА</h2>
          <h5 className="mb-3 mt-3">
            Прикладное программное обеспечение АСМ РЗА для одного объекта
            электросетевого хозяйства:
          </h5>
          <table style={{ border: "1px solid white", maxWidth: "1000px" }}>
            <thead>
              <tr>
                <th className="rpa__table-text">
                  Уровень АСМ РЗА
                </th>
                <th className="rpa__table-text">
                  Программное обеспечение
                </th>
                <th className="rpa__table-text">
                  Количество лицензий
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="rpa__table-text">
                  Верхний уровень
                </td>
                <td className="rpa__table-text">
                  NPT Platform
                </td>
                <td className="rpa__table-text">
                  1
                </td>
              </tr>
              <tr>
                <td className="rpa__table-text">
                  Нижний уровень
                </td>
                <td className="rpa__table-text">
                  Специализированное программное обеспечение SCADA NPT Compact
                </td>
                <td className="rpa__table-text">
                  1
                </td>
              </tr>
            </tbody>
          </table>
          <h5 className="mb-3 mt-3">
            Системное программное обеспечение АСМ РЗА включает в себя:
          </h5>
          <ul className="mb-4 description__list">
            <li className="mb-1 description__number-li">
              Операционная система Linux (в случае использования отечественного
              ПО – ALT Linux);
            </li>
            <li className="mb-1 description__number-li">
              СУБД Postgres (в случае использования отечественного ПО – Postgres
              PRO). Масштабирование АСМ РЗА может выполняться как горизонтально
              (увеличение количества нижних уровней), так и вертикально
              (увеличение количества верхних уровней).
            </li>
          </ul>
          <h2 className="mb-4">Варианты применения</h2>
          <p>
            Мониторинг технического состояния и анализ правильности срабатывания
            устройств РЗА на объектах электросетевого хозяйства.
          </p>
          <img
            src={functionRSA}
            className="mb-3"
            alt=" Функции P3A"
            style={{ alignSelf: "center", width: "100%" }}
          />

          <h5 className="mb-3 mt-3">
            АСМ РЗА позволяет достичь следующих целей для предприятия:
          </h5>
          <ul className="mb-4 description__list">
            {functionsRSA.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <h5 className="mb-3 mt-3">
          Стандартные расширения, необходимые для реализации технического решения:
          </h5>
          <ul className="mb-4 description__list">
            {plugins.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                <Link className="text-links" to={i.link}>
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
          <PrevAndNextButtons buttonName="решению" route={route} links={technicalSolutionsLinks}/>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default Rpa;

