import React from "react";

function Title({ title, paragraph }) {
  return (
    <div style={{ maxWidth: "760px", margin: '0 10px' }}>
      <h2 className="display-5 fw-semibold mb-4 text-center">{title}</h2>
      <p className="lead text-center" style={{ color: "var(--grey)" }}>
        {paragraph}
      </p>
    </div>
  );
}

export default Title;
