import React, { useEffect } from "react";
// import title from "../../images/lenenergo/title.png";
// import Parameters from "../../components/Parameters/Parameters";
// import image1 from "../../images/lenenergo/lenenergo_page-0001.jpg";
// import image2 from "../../images/lenenergo/lenenergo_page-0002.jpg";
import Layout from "../../components/Layout/Layout";
import { AppRoute } from "../../constants";
import { Link, useNavigate } from "react-router-dom";

const descriptionLi = [
  "компании, которые уже успели автоматизировать некоторые бизнес-процессы, настраивают интеграции между разнородными программными продуктами или внедряют их заново",
  "компании, которые еще не начали процесс автоматизации, выбирают единую платформу, на основе которой планируют автоматизировать большинство бизнес-процессов",
];

const descriptionList = [
  { id: 1, title: "Ведение НТД ", link: AppRoute.Docs },
  {
    id: 2,
    title: "Ведение проектов строительства и реконструкции объектов  ",
    link: AppRoute.Build,
  },
  {
    id: 3,
    title:
      "Ведение реестра ЛЭП и электросетевого оборудования напряжением 0,4—35 кВ ",
    link: AppRoute.Eqmv,
  },
  { id: 4, title: "Витрина данных реального времени  ", link: AppRoute.Dm },
  { id: 5, title: "Отображение объектов на ГИС  ", link: AppRoute.Gis },
  { id: 6, title: "Отображение осциллограмм  ", link: AppRoute.Oscl },
  {
    id: 7,
    title: "Оценивание состояния установившегося режима сети  ",
    link: AppRoute.Se,
  },
  {
    id: 8,
    title: "Расчет параметров установившегося и послеаварийного режимов  ",
    link: AppRoute.Pf,
  },
  { id: 9, title: "Расчет ТКЗ  ", link: AppRoute.Sc },
  {
    id: 10,
    title: "Риск-ориентированная система учета активов ",
    link: AppRoute.Opt,
  },
  {
    id: 11,
    title: "Управление мероприятиями ТОиР первичного оборудования",
    link: AppRoute.Maintanance,
  },
];

function MiningIndustry({ openPopup }) {
  //const images = [image1, image2];
  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/", { state: { fromAutomationProcesses: true } });
    };
  }, []);

  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4 text-center">
          Технические решения для Добывающей промышленности
        </h1>
        <div className="align-start d-flex flex-column">
          <p>
            Добывающая промышленность - отрасли производства, занимающиеся
            добычей различного сырья и топлива из недр земли, из вод и лесов.
            Основные отрасли добывающая промышленность: добыча полезных
            ископаемых (уголь, нефть, природный газ, сланцы, торф, железная
            руда, руды цветных, редких и благородных металлов, нерудное сырьё и
            др.), добыча морепродуктов, заготовка древесины. Продукция
            добывающей промышленности используется преимущественно{" "}
            {
              <Link to={AppRoute.ManufacturingIndustry} className="text-links">
                в обрабатывающей промышленности
              </Link>
            }
          </p>
          <p>
            Предприятия добывающей промышленности имеют свои особенности в части
            бизнес-процессов. К таким особенностям можно отнести: разветвленная
            холдинговая структура, сложная цепь поставок, низкая прозрачность
            деятельности удаленных мест добычи (например, угольных шахт)
          </p>
          <p>
            Указанные особенности, а также необходимость соответствовать мировым
            стандартам все чаще становятся мотивом для
            информационно-технологической модернизации предприятий добывающей
            отрасли. В связи с этим, естественным процессом является рост
            степени автоматизации бизнес-процессов
          </p>
          <h5 className="mb-3 mt-3">
            При этом можно отметить следующие тенденции автоматизации:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <p>
            Если попробовать отобразить взаимосвязи между бизнес-процессами
            добывающих компаний, то такая схема будет похожа на запутанный
            клубок, так как взаимосвязей между бизнес-процессами критично много
          </p>
          <p>
            NPT Platfrom позволяет выполнить автоматизацию бизнес-процессов
            добывающих компаний, а также может выступать в качестве единого
            информационного окна с установленными четкими взаимосвязями с
            имеющимися информационными системами
          </p>
          <h5 className="mb-3 mt-3">
            Перечень типовых технических решений на базе NPT Platform для
            добывающих компаний:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionList.map((i) => (
              <li className="mb-1 description__circle-li" key={i.id}>
                <Link to={i.link} className="text-links">
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link to={AppRoute.Implementation} className="text-links">
            Подробную информацию по внедрению решений по комплексной
            автоматизации бизнес-процессов добывающих компаний можно посмотреть
            в разделе «Внедрение»
          </Link>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
        {/* <Parameters
        title="Технические решения для Добывающей промышленности"
        image={title}
        images={images}
        openPopup={openPopup}
      >
        <h5 className="mb-3">Основные функции:</h5>
        <ul className="description__list">
          {descriptionLi.map(i => (
            <li className="mb-1 description__number-li" key={i}>{i}</li>
          ))}
        </ul>
      </Parameters> */}
      </main>
    </Layout>
  );
}

export default MiningIndustry;
