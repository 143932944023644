import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from '../../../images/technicalSolutions/Pf1.png'
import img2 from '../../../images/technicalSolutions/Pf2.png'
import img3 from '../../../images/technicalSolutions/Pf3.png'
import img4 from '../../../images/technicalSolutions/Pf4.png'

const images = [
  {
    title: 'Табличная форма с элементами схемы замещения, входящими в схему',
    img: img1,
    type: "image",
  },
  {
    title: 'Табличная форма силового оборудования, входящего в схему',
    img: img2,
    type: "image",
  },
  {
    title: 'Графическая визуализация схемы сети',
    img: img3,
    type: "image",
  },
  {
    title: 'Отображение результатов расчета на графике',
    img: img4,
    type: "image",
  },
];

const functions = [
  { id: 1, title: "Расчет установившихся режимов (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)" },
  { id: 2, title: "Расчет электрических потерь мощности (активной и реактивной) и напряжения в элементах сети электроснабжения" },
  { id: 3, title: "Осуществление контроля за уровнями напряжения, токов и нагрузкой энерго, и техоборудования, сигнализаций при превышении допустимых отклонений" },
  { id: 4, title: "Оперативный контроль технологического режима объектов электрической сети" },
  { id: 5, title: "Анализ работы распределительной сети с возможностью ретроспективного анализа сетевых неисправностей" },
  { id: 6, title: "Мониторинг и сигнализацию текущего состояния устройств РЗА" },
  { id: 7, title: "Расчет отклонений от расчетных данных режимов на основе электрических величин (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)" },
  { id: 8, title: "Оценка ошибки измерений" },
  { id: 9, title: "Предупреждение по результатам проведенного анализа возможных последствий неблагоприятных ситуаций с целью преждевременного устранения возможных технологических нарушений" }
];

const extensions = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

function Se() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    extensions={extensions}
    images={images}
    route={window.location.pathname}
    title="Техническое решение по оцениванию состояния установившегося режима сети"
    description="Решение предназначено для анализа динамического отклонения
    параметров первичного оборудования от расчетных значений и контроля
    режима работы электрических сетей и объектов электроэнергетики"
    listTitle="Основные функции:"
  />
  );
}

export default Se;
