import React from "react";
import Article1 from "./components/Articles/Article1";
import Article2 from "./components/Articles/Article2";
import Article3 from "./components/Articles/Article3";
import Article4 from "./components/Articles/Article4";
import Article5 from "./components/Articles/Article5";
import Article6 from "./components/Articles/Article6";
import Article7 from "./components/Articles/Article7";
import Article8 from "./components/Articles/Article8";
import Article9 from "./components/Articles/Article9";
import tes from "./images/News/1_tes.png";
import Vn from "./images/News/2_Vn.png";
import minsvyazi from "./images/News/3_minsvyazi.png";
import cres from "./images/News/4_cres.png";
import gost from "./images/News/5_gost.jpg";
import CIM from "./images/News/6_CIM.png";
import Baikal from "./images/News/7_Baikal.png";
import film from "./images/News/8_film.png";
import rosseti from "./images/News/rosseti.png";

export const AppRoute = {
  MainPage: "/",
  NotFound: "*",
  News: "/news",
  Rosseti: "/rosseti",
  ACM: "/acm",
  Transneft: "/transneft",
  Lenenergo: "/lenenergo",
  RZA: "/rza",
  Partners: "/partners",
  NewsItem: "/news/:id",
  ElectricPower: "/electric-power",
  MiningIndustry: "/mining-industry",
  ManufacturingIndustry: "/manufacturing-industry",
  Production: "/production",
  TimeAttendanceSystem: "/time-and-attendance-system",
  Telecommunication: "/telecommunication-asets-accounting-system",
  EnergyFacilities: "/automation-system-for-designing-wind-energy-facilities",
  Build: "/build",
  Dm: "/dm",
  Docs: "/docs",
  Edu: "/edu",
  Eqgen: "/eqgen",
  Eqhv: "/eqhv",
  Eqmv: "/eqmv",
  Gis: "/gis",
  Maintanance: "/maintanance",
  Metering: "/metering",
  Opt: "/opt",
  Oscl: "/oscl",
  Pf: "/pf",
  Rpa: "/rpa",
  Sc: "/sc",
  Se: "/se",
  Uml: "/uml",
  DeveloperPlugin: "/developer-plugin",
  VectorPlugin: "/vector-plugin",
  ScadaPlugin: "/scada-plugin",
  MessagePlugin: "/message-plugin",
  MapPlugin: "/map-plugin",
  OscilloscopePlugin: "/oscilloscope-plugin",
  VideoPlugin: "/video-plugin",
  DashboardPlugin: "/dashboard-plugin",
  TestProgramsAndMethods: "/test-programs-and-methods",
  TechnicalSupport: "/technical-support",
  TechnicalRequirements: "/technical-requirements",
  ScientificDevelopmentAndResearch: "/scientific-development-and-research",
  ProjectDocumentation: "/project-documentation",
  OperationalDocumentation: "/operational-documentation",
  Implementation: "/implementation",
  SurveyReport: "/survey-report",
  DemoForm: "/demoForm",
};

export const news = [
  {
    title:
      "Представляем вашему вниманию фильм, созданный на основе демонстрации жизненного цикла РЗА с помощью программных комплексов: САПР «ЭК РЗА и АСУ ТП»,  ПТК «Эксплуатация» и ПТК «Приемка» с детальным показом всех этапов работы на примере создания ПС. ",
    data: "06.10.2023 18:11",
    page: <Article8 />,
    id: 1,
    image: film,
    alt: "изображение новости про фильм РЗА",
  },
  {
    title:
      "ПТК «Эксплуатация» - отечественная система, реализованная на базе NPT Platform, включена в Единый реестр российских программ для электронных вычислительных машин и баз данных Минкомсвязи РФ",
    data: "28.06.2023 17:51",
    page: <Article9 />,
    id: 2,
    image: rosseti,
    alt: "изображение новости ПТК «Эксплуатация»",
  },
  {
    title:
      "NPT Platform успешно прошла испытания на совместимость с российской операционной системой «Альт рабочая станция» и аппаратной платформой на базе процессора Baikal-M",
    data: "30.05.2022 13:09",
    page: <Article7 />,
    id: 3,
    image: Baikal,
    alt: "изображение новости NPT Platform успешно прошла испытания",
  },
  {
    title: "ГК «ЭнергопромАвтоматизация» на конференции «CIM в России и мире»",
    data: "14.02.2022 20:40",
    page: <Article6 />,
    id: 4,
    image: CIM,
    alt: "изображение новости ГК «ЭнергопромАвтоматизация» на конференции «CIM в России и мире»",
  },
  {
    title:
      "Начато публичное обсуждение проектов ГОСТ Р на профиль информационной модели устройств релейной защиты и автоматики",
    data: "04.02.2022 14:37",
    page: <Article5 />,
    id: 5,
    image: gost,
    alt: "изображение новости проекты ГОСТ",
    article: [
      {
        id: 1,
        type: "p",
        description: [
          "Первая редакция проекта национального стандарта ГОСТ Р «Единая энергетическая система и изолированно работающие энергосистемы. Информационная модель электроэнергетики. Профиль информационной модели устройств релейной защиты и автоматики», разработанная Департаментом релейной защиты, метрологии и автоматизированных систем управления технологическими процессами ПАО «Россети» и ГК «ЭнергопромАвтоматизация», представлена для публичного обсуждения в Технический комитет по стандартизации «ЭЛЕКТРОЭНЕРГЕТИКА» (ТК 016).",
          "Проект стандарта разработан по плану работ подкомитета ПК-7 «Интеллектуальные технологии в электроэнергетике» и предназначен для обмена информацией по устройствам РЗА в формате общей информационной модели CIM между субъектами электроэнергетики, потребителями электрической энергии, проектными и научными организациями.  CIM модель обеспечивает однозначную интерпретацию передаваемых и получаемых данных всеми участниками информационного обмена.",
        ],
      },
    ],
  },

  {
    title:
      "Поздравляем ПАО «Россети Ленэнерго» с получением диплома конкурса «Сделано в России» за создание электронного каталога типовых решений для построения высокоавтоматизированных районов электрических сетей (РЭС)!",
    data: "27.12.2021 18:23",
    page: <Article4 />,
    id: 6,
    image: cres,
    alt: "изображение новости ПАО «Россети Ленэнерго» получила диплом «Сделано в России»",
  },
  {
    title:
      'Программное обеспечение ГK "ЭнергопромАвтоматизация" внесено в единый реестр российских программ для электронных вычислительных машин и баз данных Минкомсвязи России',
    data: "25.12.2020 13:46",
    page: <Article3 />,
    id: 7,
    image: minsvyazi,
    alt: 'изображение новости Программное обеспечение ГK "ЭнергопромАвтоматизация" внесено в единый реестр российских программ',
  },
  {
    title:
      "ГК «ЭнергопромАвтоматизация» в составе консорциума разработала корпоративную систему ПТК «Эксплуатация»",
    data: "26.10.2020 15:11",
    page: <Article2 />,
    image: Vn,
    id: 8,
    alt: "изображение новости ГК «ЭнергопромАвтоматизация» в составе консорциума разработала корпоративную систему ПТК «Эксплуатация»",
  },
  {
    title:
      "ООО «ЭнергопромАвтоматизация» разрабатывает корпоративную информационную систему для ПАО «Транснефть»",
    data: "18.10.2016 12:07",
    image: tes,
    alt: "ООО «ЭнергопромАвтоматизация» разрабатывает корпоративную информационную систему для ПАО «Транснефть»",
    id: 9,
    page: <Article1 />,
  },
];

export const code = `
<table xmlns="http://npt-platform.com/table" id="substationTable">
  <source>
    <finder classList="cim:Substation" pageable="true">      
    </finder>
  </source>
  <header>
      <column label="Административно-территориальная единица" field="region" path="cim:Substation.Region.cim.IdentifiedObject.name" filter-strategy="string"></column>
      <column label="Наименование ПС" field="label" path="cim:IdentifiedObject.name" filter-strategy="string"></column>
      <column label="Распределительные устройства" field="voltageLevel" path="cim:Substation.VoltageLevels.cim.IdentifiedObject.name" filter-strategy="string"></column>
  </header>
</table>
`;

export const code2 = `
  var query = require('query');
  var table = require('table');

  table.select(function(request) {
    var qb = query.instanceOf('cim:Substation')
    .join("voltageLevel", "cim:Substation.VoltageLevels")
    .equal('voltageLevel[cim.IdentifiedObject.name]', 'ОРУ 500 кВ')  
    return qb;
  });
`;

export const code3 = `
<tree xmlns="http://npt-platform.com/tree" id="registry">
  <branch id="GR" select="cim:GeographicalRegion" icon="npt-i-electrical-unit" icon-color="#418FDE">      
    <add id="addSGR" class="cim:SubGeographicalRegion" parent-ref="cim:SubGeographicalRegion.Region"/>
    <branch id="SGR" select="cim:SubGeographicalRegion" icon="npt-i-eg-alter" icon-color="#FFBE33">
      <add id="addSubstation" class="cim:Substation" parent-ref="cim:Substation.Region"/>      
      <branch id="Substation" select="cim:Substation" icon="npt-i-ps" icon-color="#228B22"></branch> 
    </branch> 
  </branch> 
</tree>
`;

export const code4 = `
"toolbar": [
  {
    "id": "export",
    "type": "group",
    "label": "Экспорт",     
    "group": [
      {
        "id": "exportSubjectsProfile",
        "label": "Экспорт профиля субъектов ПС"
      },
      {
        "id": "exportSubjectsModel",
        "label": "Экспорт субъектов ПС"
      }            
    ]
  },        
  {
    "id": "Reports",
    "type": "button",
    "label": "Отчеты"
  }
]
`;

export const code5 = `
"content": [
  {
    "ui": "grid-cell",
    "content": [
      {
      "ui": "predicate-placeholder",
      "ref": "cim:IdentifiedObject.name"
      },
      {
      "ui": "predicate-placeholder",
      "ref": "cim:IdentifiedObject.mRID",
      "options": {
        "label": "Идентификатор в мастер системе"
        }
      },
      {
        "ui": "predicate-placeholder",
        "ref": "cim:Substation.Region"
      }
    ]
  }
]
`;

export const code6 = `
var query = require('query');
var model = require('model');

model.build('substationModel', 'rdf/xml', function() {
  return model
    .over(query.instanceOf(
    'cim:Substation', 
    'cim:PowerSystemResource', 
    'cim:TransformerEnd'
  ) .primitives(true)
    .subjectRelations(true)
});
`;

export const code7 = `
var files = require('files');
var printRDF = require('print').printRDF;

var adapter = files.loadRDFModel('model.rdf');
var profile = files.loadXSDProfile('profile.xsd');
adapter.setProfile(profile);

printRDF(adapter);
`;

export const technicalSolutionsLinks = [
  {
    title:
      "Ведение проектов строительства и реконструкции объектов ветроэнергетики",
    link: AppRoute.Build,
  },
  {
    title: "Витрина данных реального времени",
    link: AppRoute.Dm,
  },
  {
    title: "Ведение НТД",
    link: AppRoute.Docs,
  },
  {
    title: "Размещение обучающих видеоматериалов",
    link: AppRoute.Edu,
  },
  {
    title: "Ведение реестра генерирующего оборудования",
    link: AppRoute.Eqgen,
  },
  {
    title:
      "Ведение реестра ЛЭП и электросетевого оборудования напряжением 110—750 кВ",
    link: AppRoute.Eqhv,
  },
  {
    title:
      "Ведение реестра ЛЭП и электросетевого оборудования напряжением 0,4—35 кВ",
    link: AppRoute.Eqmv,
  },
  {
    title: "Отображение объектов на ГИС",
    link: AppRoute.Gis,
  },
  {
    title: "Управление мероприятиями ТОиР первичного оборудования",
    link: AppRoute.Maintanance,
  },
  {
    title:
      "Введение учета потребления электроэнергии и оценки энергоэффективности",
    link: AppRoute.Metering,
  },
  {
    title: "Риск-ориентированная система учета телекоммуникационных активов",
    link: AppRoute.Opt,
  },
  {
    title: "Отображение осциллограмм",
    link: AppRoute.Oscl,
  },
  {
    title: "Расчет параметров установившегося и послеаварийного режимов",
    link: AppRoute.Pf,
  },
  {
    title: "АСМ РЗА",
    link: AppRoute.Rpa,
  },
  {
    title: "Расчет ТКЗ",
    link: AppRoute.Sc,
  },
  {
    title: "Оценивание состояния установившегося режима сети",
    link: AppRoute.Se,
  },
  {
    title:
      "Создание диаграмм классов информационной модели с использованием UML",
    link: AppRoute.Uml,
  },
];

technicalSolutionsLinks.sort((a, b) => a.title.localeCompare(b.title));

export const implementationExamples = [
  {
    title: "ПТК «Эксплуатация» (ПАО «Россети»)",
    link: AppRoute.Rosseti,
  },
  {
    title: "АСМ РЗА",
    link: AppRoute.ACM,
  },
  {
    title: "КИАС ДЭО (ПАО «Транснефть»)",
    link: AppRoute.Transneft,
  },
  {
    title: "ЭК для ЦРЭС (ПАО «Ленэнерго»)",
    link: AppRoute.Lenenergo,
  },
  {
    title: "Сбор и анализ аварийной информации РЗА",
    link: AppRoute.RZA,
  },
  {
    title: 'ПТК "Производство"',
    link: AppRoute.Production,
  },
  {
    title: "Система учета рабочего времени",
    link: AppRoute.TimeAttendanceSystem,
  },
  {
    title: "Система учета телекоммуникационных активов",
    link: AppRoute.Telecommunication,
  },
  {
    title: "Система автоматизации проектирования объектов ветроэнергетики",
    link: AppRoute.EnergyFacilities,
  },
];

implementationExamples.sort((a, b) => a.title.localeCompare(b.title));

export const pluginsLinks = [
  {
    title: "Инструменты разработки платформы (developer-plugin)",
    link: AppRoute.DeveloperPlugin,
  },
  {
    title: "Создание интерактивных схем (vector-plugin)",
    link: AppRoute.VectorPlugin,
  },
  {
    title: "Отображение данных реального времени (scada-plugin)",
    link: AppRoute.ScadaPlugin,
  },
  {
    title: "Создание интерактивных географических карт (map-plugin)",
    link: AppRoute.MapPlugin,
  },
  {
    title: "Отображение осциллограмм (oscilloscope-plugin)",
    link: AppRoute.OscilloscopePlugin,
  },
  {
    title: "Отображение видеопотока (video-plugin)",
    link: AppRoute.VideoPlugin,
  },
  {
    title: "Формирование автоматических уведомлений (message-plugin)",
    link: AppRoute.MessagePlugin,
  },
  {
    title: "Отображение статистических данных (dashboard-plugin)",
    link: AppRoute.DashboardPlugin,
  },
];

pluginsLinks.sort((a, b) => a.title.localeCompare(b.title));
