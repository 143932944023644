import React from "react";
import Layout from "../../../components/Layout/Layout";
import Parameters from "../../../components/Parameters/Parameters";
import title from "../../../images/timeAttendanceSystem/title.png";

const descriptionLi = [
  "Контроль рабочего времени сотрудников: начало рабочего дня, окончание рабочего дня",
  "Ведение комплексных проектов: распределение задач между различными сотрудниками, планирование работ",
  "Контроль выполнения задач сотрудниками в рамках различных проектов",
  "Осуществление руководителем структурного подразделения оценки работы сотрудника по каждой задаче и по проекту в целом",
  "Сводная информация для руководителя структурного подразделения/компании по загрузке сотрудников в рамках различных временных промежутков (день/неделя/месяц/год)",
  "Сводная информация для руководителя структурного подразделения/компании о нахождении сотрудников на рабочем месте, в отгуле, в отпуске",
  "Планирование и согласование графика отпусков сотрудников",
  "Формирование отчетов по форме компании, в том числе для предоставления в отдел кадров",
];

function TimeAttendanceSystem({ openPopup }) {
  return (
    <Layout>
      <main className="main">
        <Parameters
          title="Система учета рабочего времени"
          image={title}
          openPopup={openPopup}
          descriptionTitle="Основные функции:"
          descriptionList={descriptionLi}
        />
      </main>
    </Layout>
  );
}

export default TimeAttendanceSystem;
