import React, { useEffect, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import "./Typewriter.scss";

const Typewriter = ({
  text,
  delay,
  isOpen,
  setIsFinish,
  active,
  textHeight,
  textWidth,
  language
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isOpen && currentIndex < text.length) {
      let timeout = null;
      if (active) {
        timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);
      } else {
        setIsFinish(false);
        setCurrentIndex(0);
        setCurrentText("");
        timeout && clearTimeout(timeout);
      }
      return () => clearTimeout(timeout);
    } else if (isOpen && currentIndex === text.length) {
      setIsFinish(true);
    } else if (!isOpen) {
      setIsFinish(false);
      setCurrentIndex(0);
      setCurrentText("");
    }
  }, [currentIndex, delay, text, isOpen, setIsFinish, active]);

  const highlightedCode = hljs.highlight(currentText, {
    language: language,
  }).value;

  return (
    <pre style={{minHeight: textHeight, minWidth: textWidth}}>
      <code>
        <div dangerouslySetInnerHTML={{ __html: highlightedCode }}></div>
      </code>
    </pre>
  );
};

export default Typewriter;