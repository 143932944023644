import React from "react";
import EditorPopup from "./EditorPopup";
import VisualInformation from "./VisualInformation";
import CIMPopup from "./CIMPopup";
import "./Popup.scss";

function Popup({ isOpen, onClose, card, windowRef, image }) {
  const PopupContent = () => {
    switch (card) {
      case 1:
        return <EditorPopup windowRef={windowRef} isOpen={isOpen} />;
      case 2:
        return <CIMPopup isOpen={isOpen} width={"90vw"}/>;
      case 3:
        return <VisualInformation isOpen={isOpen} />;
      default:
        return <></>;
    }
  };

  return (
    <section className={`popup ${isOpen ? `popup_opened` : ""}`}>
      <div className="popup__container">
        <button
          className="popup__close-icon links"
          aria-label="закрыть"
          type="button"
          onClick={onClose}
        />
        {image && 
        <img src={image.image} alt="карта" style={image.height ?{height: '90%'} : {width: '90%'}}/>
        }
        {PopupContent()}
      </div>
    </section>
  );
}

export default Popup;