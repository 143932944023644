import React from "react";
import "./Intro.scss";
import img from "../../images/intro-img1.png";
import minsvyazi from "../../images/minsvyazi.png";
import { Link } from "react-router-dom";

function Intro() {
  return (
    <section className="intro row">
  
        <div className="col" style={{ paddingLeft: "20px" }}>
          {/* <h1 className="fw-semibold intro__title">NPT PLATFORM</h1> */}
          <p
            // style={{ color: "var(--grey)", maxWidth: "560px" }}
            style={{ maxWidth: "560px" }}
            className="mt-4 mb-4 lead intro__paragraph"
          >
            Структурируйте и автоматизируйте бизнес-процессы Вашего производства
          </p>
          <Link
            to="https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748"
            target="_blank"
            rel="noopener noreferrer"
            className="intro__logo link"
          >
            <img src={minsvyazi} className="intro__logo-img" alt="логотип Минкомсвязи"/>
            <p className="intro__logo-p">Внесено в реестр Минкомсвязи</p>
          </Link>
        </div>

        <div className="p-0 col">
          <img src={img} className="intro__img" alt="рука держит схему, на схеме компьютеры,шестеренки, планшет, блоки кода, все соединено линиями"></img>
        </div>

    </section>
  );
}

export default Intro;

// <Link to="https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748" target='_blank' rel="noopener noreferrer" className="advantages__logo link">
// <img src={minsvyazi} className="advantages__logo-img" />
// <p className="advantages__logo-p">Внесено в реестр Минкомсвязи</p>
// </Link>

//   {/* <div className="intro__container">
//     <h5 className="fw-semibold intro__name" style={{ color: "var(--blue)" }}>
//       NPT PLATFORM
//     </h5>
//     <h1
//       className="fw-semibold intro__title"
//     >
//       Автоматизированная система для создания корпоративных приложений
//     </h1>
//     <p style={{ color: "var(--grey)" }} className="mt-3 mb-4 lead intro__paragraph">
//       NPT Platform автоматизирует процессы производственной деятельности
//       предприятий.
//     </p>
//     <div className="p-0 intro__img">
//       <img src={img} style={{ maxWidth: "100%" }} alt="робот c клавиатурой NPT"></img>
//     </div>
//   </div> */}
