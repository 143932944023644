import React from "react"; 
import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { circleText, circleTextRight } from "./circleText";
import { rectText, rectTextRight } from "./rectText";

function Path({ path, setDefaultActive, defaultActive }) {
  const {
    d,
    hover,
    firstLine,
    firstLineActive,
    secondLineActive,
    secondLine,
    rectSvg,
    rectSvgActive,
    textRect,
    circleText,
    circleTextZoom,
  } = path;

  const [timer, setTimer] = useState(null);
  const hovered =  Number(defaultActive) === path.id;

  function handleMouseEnter(e) {
    setTimer(
      setTimeout(() => {
        setDefaultActive(e.target.id);
        //setHovered(defaultActive===e.target.id);
      }, 300)
    );
  }

  function handleMouseLeave() {
    clearTimeout(timer);
    setDefaultActive(0);
    //setHovered(false);
  }

  const animation = useSpring({
    to: { d: hovered ? hover : d },
    config: { duration: 300 },
    delay: hovered ? 0 : 300,
  });

  const animationCircleText = useSpring({
    to: { d: hovered ? circleTextZoom : circleText },
    config: { duration: 300 },
    delay: hovered ? 0 : 300,
  });

  const animFirstLine = useSpring({
    to: { line: hovered ? firstLineActive : firstLine },
    config: { duration: 150 },
    delay: hovered ? 150 : 300,
  });

  const animSecondLine = useSpring({
    to: {
      line: hovered ? secondLineActive : secondLine,
      opacity: hovered ? 1 : 0,
    },
    config: { duration: 150 },
    delay: hovered ? 300 : 300,
    immediate: (key) => key === (hovered && "opacity"),
  });

  const animRect = useSpring({
    to: {
      widthSvg: hovered ? rectSvgActive.width : rectSvg.width,
      heightSvg: hovered ? rectSvgActive.height : rectSvg.height,
      ySvg: hovered ? rectSvgActive.y : rectSvg.y,
      xSvg: hovered ? rectSvgActive.x : rectSvg.x,
    },
    config: { duration: 200 },
    delay: hovered ? 450 : 150,
  });

  const animTextOpacity = useSpring({
    to: { opacity: hovered ? 1 : 0 },
    config: { duration: 150 },
    delay: hovered ? 650 : 0,
  });

  return (
    <>
      <animated.path d={animFirstLine.line} stroke="#151414" strokeWidth="3" />
      <g onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <animated.path
          id={path.id}
          d={animation.d}
          style={{ cursor: "pointer" }}
          fill="#E52A1B"
        />
        <animated.path
          d={animationCircleText.d}
          style={{ cursor: "pointer" }}
          fill="white"
        />
      </g>
      <animated.path
        style={animSecondLine}
        d={animSecondLine.line}
        stroke="#151414"
        strokeWidth="3"
      />

      {rectSvg && rectSvgActive && (
        <animated.rect
          //x={rectSvg.x}
          x={animRect.xSvg}
          y={animRect.ySvg}
          width={animRect.widthSvg}
          style={animRect}
          height={animRect.heightSvg}
          rx={10}
          fill="#151414"
        />
      )}

      <animated.path style={animTextOpacity} d={textRect} fill="white" />
    </>
  );
}

function Circle() {
  const [defaultActive, setDefaultActive] = useState(4);
  const paths = [
    {
      id: 3,
      d: "M360.764 425C388.536 394.346 406.09 354.214 408 310H344.443C342.654 336.955 331.941 361.455 315.255 380.577L360.764 425Z",
      hover:
        "M371.373 449C407.606 409.017 430.508 356.67 433 299H350.079C347.746 334.159 333.769 366.116 312 391.057L371.373 449Z",
      firstLine: "M358.5 358.5H368",
      firstLineActive: "M358.5 358.5H595",
      secondLineActive: "M595 357V466",
      secondLine: "M595 357V363.5",
      rectSvg: { x: 430, y: 448, width: 340, height: 0 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectText.text3.d,
      circleText: circleText.text3.d,
      circleTextZoom: circleText.text3.dZoom,
      active: defaultActive,
    },
    {
      id: 8,
      d: "M149.133 215C168.661 196.871 194.255 185.26 222.511 183.6V120C176.516 121.764 134.902 140.71 103.838 170.642L149.133 215Z",
      hover:
        "M136.252 218C160.935 195.291 193.286 180.746 229 178.667V99C170.863 101.21 118.264 124.942 79 162.435L136.252 218Z",
      rectSvg: {
        x: 430,
        y: 12,
        width: 0,
        height: 145,
      },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectText.text8.d,
      firstLine: "M177.5 169V157",
      firstLineActive: "M177.5 169V64",
      secondLineActive: "M177.5 64H432",
      secondLine: "M177.5 64H188.5",
      circleText: circleText.text8.d,
      circleTextZoom: circleText.text8.dZoom,
      active: defaultActive,
    },
    {
      id: 7,
      active: defaultActive,
      d: "M91.3026 181C63.71 211.995 46.4559 252.485 45 297H108.392C109.761 269.751 120.204 244.924 136.747 225.48L91.3026 181Z",
      hover:
        "M77.5704 151C40.8794 192.148 17.936 245.903 16 305H100.295C102.116 268.825 116.002 235.864 138 210.051L77.5704 151Z",
      rectSvg: { x: 430, y: 12, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectText.text7.d,
      firstLine: "M62.5 267.5L54 249",
      firstLineActive: "M62.5 267.5L28 69",
      secondLineActive: "M28 69H431.5",
      secondLine: "M28 69H40",
      circleText: circleText.text7.d,
      circleTextZoom: circleText.text7.dZoom,
    },
    {
      id: 2,
      active: defaultActive,
      d: "M314.267 222.447C332.003 242.129 343.264 267.764 344.683 296H408C406.485 249.972 387.936 208.269 358.455 177L314.258 222.447H314.267Z",
      hover:
        "M312.011 212.814C334.904 238.284 349.44 271.46 351.272 308H433C431.045 248.434 407.102 194.466 369.048 154L312 212.814H312.011Z",
      firstLine: "M357 244H371.5",
      firstLineActive: "M357 244H602",
      secondLineActive: "M602 244V152",
      secondLine: "M602 244V233.5",
      rectSvg: { x: 430, y: 155, width: 340, height: 0 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectText.text2.d,
      circleText: circleText.text2.d,
      circleTextZoom: circleText.text2.dZoom,
    },
    {
      id: 6,
      active: defaultActive,
      d: "M139.739 382.591C121.859 363.239 110.344 337.945 108.48 310H45C46.9857 355.72 65.8277 397.075 95.4824 428L139.739 382.591Z",
      hover:
        "M140 394.508C116.976 369.58 102.147 336.997 99.7465 301H18C20.557 359.894 44.8208 413.164 83.0086 453L140 394.508Z",
      firstLine: "M66.5 340L54 363",
      firstLineActive: "M66.5 340L32 538.5",
      secondLineActive: "M32 538.5H435.5",
      secondLine: "M32 538.5H44",
      rectSvg: {
        x: 430,
        y: 448,
        width: 0,
        height: 145,
      },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectText.text6.d,
      circleText: circleText.text6.d,
      circleTextZoom: circleText.text6.dZoom,
    },
    {
      id: 5,
      active: defaultActive,
      d: "M104.835 438.587C135.514 466.061 175.519 483.323 219.519 485V421.395C192.73 419.822 168.329 409.401 149.173 393L104.835 438.578V438.587Z",
      hover:
        "M76 450.948C117.196 487.68 170.916 510.757 230 513V427.963C194.027 425.86 161.261 411.927 135.538 390L76 450.936V450.948Z",
      firstLine: "M164.5 449.5L174 435.5",
      firstLineActive: "M127.5 550L174 435.5",
      secondLineActive: "M430.5 551C422.1 551 209.667 551 126 551",
      secondLine: "M133.5 551C125.1 551 134 551 126 551",
      textRect: rectText.text5.d,
      rectSvg: {
        x: 430,
        y: 448,
        width: 0,
        height: 145,
      },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      circleText: circleText.text5.d,
      circleTextZoom: circleText.text5.dZoom,
    },
    {
      id: 4,
      d: "M306.042 389C286.316 406.951 260.693 418.428 232.46 420.095V485C278.732 483.217 320.646 464.273 352.13 434.286L306.051 389H306.042Z",
      hover:
        "M317.943 386.908C292.891 409.705 260.351 424.28 224.497 426.398V508.823C283.261 506.559 336.489 482.501 376.472 444.419L317.954 386.908H317.943Z",
      firstLine: "M270 447L272 449",
      firstLineActive: "M270 447L348 525",
      secondLineActive: "M347 524.5H432.5",
      secondLine: "M347 525H348",
      rectSvg: { x: 430, y: 448, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectText.text4.d,
      circleText: circleText.text4.d,
      circleTextZoom: circleText.text4.dZoom,
      active: defaultActive,
    },
    {
      id: 1,
      d: "M348.141 168.379C317.332 140.338 276.937 122.697 232.46 121V184.688C259.726 186.29 284.517 197.073 303.821 214L348.141 168.379Z",
      active: defaultActive,
      hover:
        "M369 159.587C330.915 124.913 280.98 103.099 226 101V179.754C259.705 181.735 290.351 195.068 314.213 216L369 159.587Z",
      firstLine: "M278.5 171.5L282 163.5",
      firstLineActive: "M278.5 171.5L308.925 79",
      secondLineActive: "M308.924 79H430.183",
      secondLine: "M308.924 79H325",
      rectSvg: {
        x: 430,
        y: 12,
        width: 0,
        height: 145,
      },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectText.text1.d,
      circleText: circleText.text1.d,
      circleTextZoom: circleText.text1.dZoom,
    },
  ];

  const pathsRight = [
    {
      id: 30,
      d: "M1107.76 425C1135.53 394.346 1153.09 354.214 1155 310H1091.44C1089.65 336.955 1078.94 361.455 1062.25 380.577L1107.76 425Z",
      hover:
        "M1121.86 450C1158.4 409.75 1181.49 357.055 1184 299H1100.39C1098.04 334.393 1083.95 366.563 1062 391.671L1121.86 450Z",
      firstLine: "M1137 340L1142.5 351",
      firstLineActive: "M1137 340L1171.5 538.5",
      secondLineActive: "M1171.5 538.5H768",
      secondLine: "M1171.5 538.5H1156",
      rectSvg: { x: 767, y: 448, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      circleTextZoom: circleTextRight.text3.textZoom,
      circleText: circleTextRight.text3.d,
      textRect: rectTextRight.text3.d,
    },
    {
      id: 80,
      d: "M896.131 215C915.659 196.871 941.254 185.26 969.509 183.6V120C923.514 121.764 881.9 140.71 850.836 170.642L896.131 215Z",
      hover:
        "M881.015 216C906.028 192.909 938.81 178.12 975 176.006V95C916.088 97.2471 862.787 121.378 823 159.502L881.015 216Z",
      firstLine: "M919.184 171.5L916.5 165.5",
      firstLineActive: "M919.184 171.5L888.759 79",
      secondLineActive: "M888.758 79H767.499",
      secondLine: "M888.758 79H883",
      rectSvg: { x: 767, y: 12, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectTextRight.text8.d,
      circleText: circleTextRight.text8.d,
      circleTextZoom: circleTextRight.text8.textZoom,
    },
    {
      id: 70,
      d: "M838.301 181C810.708 211.995 793.454 252.485 791.998 297H855.39C856.759 269.751 867.202 244.924 883.745 225.48L838.301 181Z",
      hover:
        "M831.561 156C795.472 196.347 772.904 249.054 771 307H853.913C855.704 271.529 869.362 239.211 891 213.901L831.561 156Z",
      firstLine: "M827 240H816",
      firstLineActive: "M827 240H602",
      secondLineActive: "M602 240V156",
      secondLine: "M602 240V232",
      rectSvg: { x: 430, y: 155, width: 340, height: 0 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectTextRight.text7.d,
      circleText: circleTextRight.text7.d,
      circleTextZoom: circleTextRight.text7.textZoom,
    },
    {
      id: 20,
      d: "M1061.26 222.447C1079 242.129 1090.26 267.764 1091.68 296H1155C1153.48 249.972 1134.93 208.269 1105.45 177L1061.26 222.447H1061.26Z",
      hover:
        "M1061.01 212.05C1083.71 237.19 1098.13 269.934 1099.95 306H1181C1179.06 247.208 1155.32 193.94 1117.58 154L1061 212.05H1061.01Z",
      firstLine: "M1127 267.5L1133 260.5",
      firstLineActive: "M1127 267.5L1161.5 69",
      secondLineActive: "M1161.5 69H763.5",
      secondLine: "M1161.5 69H1155",
      rectSvg: { x: 767, y: 12, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectTextRight.text2.d,
      circleText: circleTextRight.text2.d,
      circleTextZoom: circleTextRight.text2.textZoom,
    },
    {
      id: 60,
      d: "M886.737 382.591C868.858 363.239 857.342 337.945 855.478 310H791.998C793.984 355.72 812.826 397.075 842.48 428L886.737 382.591Z",
      hover:
        "M891 394.968C867.221 369.384 851.906 335.944 849.427 299H765C767.641 359.444 792.7 414.116 832.14 455L891 394.968Z",
      firstLine: "M809 363.542H802",
      firstLineActive: "M809 363.542H595",
      secondLineActive: "M595 362.185V460.815",
      secondLine: "M595 362.185V375.5",
      rectSvg: { x: 430, y: 448, width: 340, height: 0 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectTextRight.text6.d,
      circleText: circleTextRight.text6.d,
      circleTextZoom: circleTextRight.text6.textZoom,
    },
    {
      id: 50,
      d: "M851.833 438.587C882.512 466.061 922.517 483.323 966.517 485V421.395C939.728 419.822 915.327 409.401 896.171 393L851.833 438.578V438.587Z",
      hover:
        "M827 447.462C867.126 483.297 919.451 505.812 977 508V425.037C941.962 422.985 910.047 409.393 884.992 388L827 447.45V447.462Z",
      firstLine: "M932 447L926 453",
      firstLineActive: "M932 447L854 525",
      secondLineActive: "M770 524.5H854.5",
      secondLine: "M846.5 524.5H854.5",
      rectSvg: { x: 767, y: 448, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectTextRight.text5.d,
      circleText: circleTextRight.text5.d,
      circleTextZoom: circleTextRight.text5.textZoom,
    },
    {
      id: 40,
      d: "M1053.04 389C1033.31 406.951 1007.69 418.428 979.458 420.095V485C1025.73 483.217 1067.64 464.273 1099.13 434.286L1053.05 389H1053.04Z",
      hover:
        "M1064.69 388C1039.31 411.187 1006.33 426.011 970 428.165V512C1029.55 509.697 1083.48 485.227 1124 446.494L1064.7 388H1064.69Z",
      firstLine: "M1029 444L1022.5 435.5",
      firstLineActive: "M1069 550L1022.5 435.5",
      secondLineActive: "M766 551C774.4 551 986.833 551 1070.5 551",
      secondLine: "M1066 551C1070.5 551 1066 551 1070.5 551",
      rectSvg: { x: 767, y: 448, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 448, width: 340, height: 145 },
      textRect: rectTextRight.text4.d,
      circleText: circleTextRight.text4.d,
      circleTextZoom: circleTextRight.text4.textZoom,
    },
    {
      id: 10,
      d: "M1095.14 168.379C1064.33 140.338 1023.93 122.697 979.458 121V184.688C1006.72 186.29 1031.52 197.073 1050.82 214L1095.14 168.379Z",
      hover:
        "M1115 159.568C1077.45 125.497 1028.21 104.062 974 102V179.385C1007.23 181.331 1037.45 194.432 1060.98 215L1115 159.568Z",
      firstLine: "M1022 169V156",
      firstLineActive: "M1022 169V64",
      secondLineActive: "M1022 64H767.5",
      secondLine: "M1022 64H1008.5",
      rectSvg: { x: 767, y: 12, width: 0, height: 145 },
      rectSvgActive: { x: 430, y: 12, width: 340, height: 145 },
      textRect: rectTextRight.text1.d,
      circleText: circleTextRight.text1.d,
      circleTextZoom: circleTextRight.text1.textZoom,
    },
  ];

  return (
    <svg
      width='100%'
      viewBox="0 0 1200 650"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {paths.map((path) => (
        <Path
          path={path}
          key={path.id}
          setDefaultActive={setDefaultActive}
          defaultActive={defaultActive}
        />
      ))}

      {/* белая подложка левого круга */}
      <path
        d="M226.182 378.444C268.11 378.444 302.099 344.361 302.099 302.318C302.099 260.274 268.11 226.191 226.182 226.191C184.254 226.191 150.265 260.274 150.265 302.318C150.265 344.361 184.254 378.444 226.182 378.444Z"
        fill="#EDEDED"
        stroke="#E52A1B"
        strokeWidth="3.46975"
        strokeMiterlimit={10}
      />
      {/* NPT градиент в левом круге */}
      <>
        <path
          d="M192.224 320.582H190.529C190.425 320.582 190.33 320.669 190.33 320.782V334.244L181.186 320.677C181.151 320.625 181.091 320.591 181.022 320.591H178.729C178.625 320.591 178.53 320.677 178.53 320.79V337.792C178.53 337.896 178.617 337.992 178.729 337.992H180.425C180.529 337.992 180.624 337.905 180.624 337.792V323.488L190.364 337.905C190.399 337.957 190.459 337.992 190.529 337.992H192.224C192.328 337.992 192.423 337.905 192.423 337.792V320.79C192.423 320.686 192.337 320.591 192.224 320.591V320.582Z"
          fill="url(#paint0_linear_14_2087)"
        />
        <path
          d="M206.185 321.533C205.744 321.256 205.259 321.039 204.766 320.9C204.273 320.77 203.772 320.683 203.261 320.64C202.768 320.605 202.189 320.579 201.54 320.57H197.085C196.981 320.57 196.886 320.657 196.886 320.77V337.772C196.886 337.876 196.972 337.971 197.085 337.971H198.78C198.884 337.971 198.979 337.884 198.979 337.772V331.11H201.972C202.915 331.11 203.754 331.032 204.472 330.884C205.199 330.728 205.882 330.424 206.497 329.973C207.829 329.002 208.503 327.623 208.503 325.879C208.503 324.986 208.304 324.144 207.915 323.389C207.517 322.626 206.938 322.002 206.194 321.533H206.185ZM198.979 322.513H200.969C201.09 322.513 201.28 322.513 201.54 322.505C201.791 322.505 201.981 322.496 202.111 322.496C203.054 322.496 203.823 322.626 204.42 322.878C205.043 323.138 205.536 323.528 205.882 324.049C206.228 324.569 206.41 325.185 206.41 325.87C206.41 326.408 206.28 326.929 206.038 327.406C205.796 327.883 205.45 328.264 205.009 328.533C204.567 328.811 204.092 328.984 203.59 329.063C203.071 329.149 202.431 329.193 201.704 329.193H198.979V322.522V322.513Z"
          fill="url(#paint1_linear_14_2087)"
        />
        <path
          d="M219.916 320.582H210.47C210.366 320.582 210.271 320.669 210.271 320.782V322.326C210.271 322.43 210.358 322.525 210.47 322.525H214.172V337.792C214.172 337.896 214.259 337.992 214.371 337.992H216.067C216.171 337.992 216.266 337.905 216.266 337.792V322.525H219.925C220.029 322.525 220.124 322.438 220.124 322.326V320.782C220.124 320.677 220.037 320.582 219.925 320.582H219.916Z"
          fill="url(#paint2_linear_14_2087)"
        />
        <path
          d="M225.994 330.979C225.804 330.857 225.596 330.762 225.38 330.701C225.164 330.641 224.956 330.606 224.74 330.589C224.532 330.571 224.29 330.562 224.022 330.562H222.188C222.084 330.562 221.989 330.649 221.989 330.762V337.771C221.989 337.875 222.075 337.97 222.188 337.97H222.888C222.992 337.97 223.087 337.884 223.087 337.771V335.143H224.203C224.601 335.143 224.956 335.108 225.259 335.047C225.57 334.978 225.873 334.848 226.132 334.648C226.712 334.223 227.006 333.625 227.006 332.87C227.006 332.48 226.92 332.115 226.747 331.786C226.574 331.456 226.323 331.178 225.994 330.97V330.979ZM223.079 331.595H223.779C223.831 331.595 223.909 331.595 224.022 331.595C224.125 331.595 224.203 331.595 224.255 331.595C224.627 331.595 224.93 331.647 225.164 331.742C225.406 331.838 225.579 331.985 225.717 332.185C225.847 332.384 225.916 332.61 225.916 332.87C225.916 333.069 225.873 333.269 225.778 333.451C225.683 333.633 225.561 333.772 225.397 333.867C225.233 333.972 225.042 334.041 224.852 334.076C224.644 334.11 224.385 334.128 224.091 334.128H223.087V331.612L223.079 331.595Z"
          fill="url(#paint3_linear_14_2087)"
        />
        <path
          d="M231.926 336.95H229.227V330.774C229.227 330.67 229.14 330.574 229.028 330.574H228.327C228.223 330.574 228.128 330.661 228.128 330.774V337.783C228.128 337.887 228.214 337.982 228.327 337.982H231.926C232.029 337.982 232.124 337.895 232.124 337.783V337.149C232.124 337.045 232.038 336.95 231.926 336.95Z"
          fill="url(#paint4_linear_14_2087)"
        />
        <path
          d="M236.636 330.696C236.601 330.626 236.532 330.574 236.454 330.574H235.676C235.598 330.574 235.529 330.618 235.494 330.696L232.475 337.705C232.449 337.765 232.458 337.835 232.492 337.896C232.527 337.956 232.588 337.982 232.657 337.982H233.418C233.496 337.982 233.565 337.939 233.6 337.861L234.56 335.632H237.553L238.513 337.861C238.548 337.93 238.617 337.982 238.695 337.982H239.456C239.525 337.982 239.586 337.948 239.62 337.896C239.655 337.843 239.664 337.765 239.638 337.705L236.645 330.696H236.636ZM237.112 334.599H235.001L236.065 332.084L237.112 334.599Z"
          fill="url(#paint5_linear_14_2087)"
        />
        <path
          d="M244.085 330.574H240.193C240.089 330.574 239.994 330.661 239.994 330.774V331.407C239.994 331.511 240.08 331.606 240.193 331.606H241.603V337.783C241.603 337.887 241.689 337.982 241.802 337.982H242.502C242.606 337.982 242.701 337.895 242.701 337.783V331.606H244.094C244.198 331.606 244.293 331.52 244.293 331.407V330.774C244.293 330.67 244.206 330.574 244.094 330.574H244.085Z"
          fill="url(#paint6_linear_14_2087)"
        />
        <path
          d="M248.983 330.571H245.436C245.332 330.571 245.237 330.658 245.237 330.771V337.78C245.237 337.884 245.324 337.979 245.436 337.979H246.137C246.241 337.979 246.336 337.892 246.336 337.78V334.77H248.879C248.983 334.77 249.078 334.683 249.078 334.57V333.937C249.078 333.833 248.992 333.737 248.879 333.737H246.336V331.595H248.983C249.087 331.595 249.182 331.508 249.182 331.395V330.762C249.182 330.658 249.095 330.562 248.983 330.562V330.571Z"
          fill="url(#paint7_linear_14_2087)"
        />
        <path
          d="M255.903 330.972C255.306 330.634 254.657 330.469 253.983 330.469C253.308 330.469 252.642 330.642 252.045 330.981C251.448 331.319 250.972 331.787 250.626 332.377C250.28 332.967 250.099 333.618 250.099 334.311C250.099 335.005 250.28 335.639 250.635 336.22C250.99 336.801 251.474 337.261 252.062 337.599C252.659 337.937 253.299 338.102 253.974 338.102C254.649 338.102 255.315 337.929 255.912 337.59C256.509 337.252 256.984 336.784 257.339 336.194C257.685 335.604 257.867 334.953 257.867 334.259C257.867 333.566 257.685 332.932 257.33 332.351C256.976 331.77 256.491 331.31 255.894 330.981L255.903 330.972ZM256.777 334.259C256.777 334.641 256.708 334.997 256.561 335.335C256.414 335.673 256.215 335.968 255.955 336.22C255.696 336.471 255.393 336.671 255.055 336.818C254.277 337.148 253.369 337.096 252.599 336.662C252.166 336.419 251.82 336.081 251.561 335.665C251.301 335.248 251.171 334.797 251.171 334.303C251.171 333.808 251.301 333.34 251.552 332.915C251.803 332.49 252.149 332.152 252.581 331.9C252.997 331.657 253.446 331.536 253.922 331.527C253.939 331.527 253.957 331.527 253.983 331.527C254.467 331.527 254.934 331.648 255.367 331.891C255.799 332.134 256.145 332.473 256.396 332.889C256.647 333.305 256.777 333.765 256.777 334.251V334.259Z"
          fill="url(#paint8_linear_14_2087)"
        />
        <path
          d="M264.01 334.191C264.209 333.844 264.313 333.462 264.313 333.054C264.313 332.612 264.209 332.195 264.01 331.831C263.802 331.458 263.508 331.163 263.128 330.946C262.842 330.79 262.531 330.686 262.193 330.643C261.873 330.599 261.493 330.582 261.043 330.582H259.382C259.278 330.582 259.183 330.669 259.183 330.782V337.79C259.183 337.895 259.27 337.99 259.382 337.99H260.083C260.187 337.99 260.282 337.903 260.282 337.79V331.614H261.19C261.545 331.614 261.847 331.632 262.072 331.666C262.28 331.701 262.47 331.779 262.661 331.909C262.834 332.031 262.972 332.187 263.076 332.386C263.18 332.586 263.232 332.794 263.232 333.002C263.232 333.28 263.162 333.523 263.024 333.739C262.886 333.956 262.695 334.121 262.444 334.243C262.263 334.312 262.064 334.364 261.847 334.381C261.623 334.399 261.337 334.416 261 334.416H260.792C260.723 334.416 260.654 334.459 260.619 334.52C260.584 334.581 260.593 334.668 260.636 334.728L263.007 337.903C263.041 337.955 263.102 337.981 263.162 337.981H264.027C264.097 337.981 264.166 337.938 264.2 337.869C264.235 337.799 264.226 337.721 264.183 337.66L262.427 335.318C262.704 335.266 262.955 335.171 263.197 335.023C263.543 334.815 263.811 334.538 264.019 334.191H264.01Z"
          fill="url(#paint9_linear_14_2087)"
        />
        <path
          d="M272.935 330.574H271.853C271.776 330.574 271.706 330.618 271.672 330.696L269.31 336.239L266.94 330.696C266.905 330.626 266.836 330.574 266.758 330.574H265.677C265.573 330.574 265.478 330.661 265.478 330.774V337.783C265.478 337.887 265.565 337.982 265.677 337.982H266.378C266.481 337.982 266.577 337.895 266.577 337.783V332.526L268.826 337.861C268.86 337.93 268.93 337.982 269.007 337.982H269.604C269.682 337.982 269.751 337.939 269.786 337.861L272.035 332.526V337.783C272.035 337.887 272.122 337.982 272.234 337.982H272.935C273.039 337.982 273.134 337.895 273.134 337.783V330.774C273.134 330.67 273.047 330.574 272.935 330.574Z"
          fill="url(#paint10_linear_14_2087)"
        />
        <path
          d="M237.185 324.77L193.379 278.336H184.321L178.967 272.967V266.669H198.534L242.54 313.103H262.237V271.908L267.523 266.539H273.803V324.77H237.176H237.185ZM184.321 313.606L178.967 308.237V281.919H185.247L190.602 287.288V313.606H184.321ZM240.948 307.205L209.391 273.886V269.376H221.752L254.832 304.221V307.205H240.948Z"
          fill="url(#paint11_linear_14_2087)"
        />
      </>
      {/* бувы NPT левый круг */}
      <defs>
        <linearGradient
          id="paint0_linear_14_2087"
          x1="141.134"
          y1="313.035"
          x2="288.314"
          y2="366.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14_2087"
          x1="142.889"
          y1="308.166"
          x2="290.061"
          y2="361.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14_2087"
          x1="144.855"
          y1="302.791"
          x2="292.027"
          y2="356.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14_2087"
          x1="144.013"
          y1="305.112"
          x2="291.184"
          y2="358.745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14_2087"
          x1="144.598"
          y1="303.467"
          x2="291.779"
          y2="357.091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14_2087"
          x1="145.295"
          y1="301.567"
          x2="292.467"
          y2="355.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_14_2087"
          x1="146.282"
          y1="298.861"
          x2="293.463"
          y2="352.485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_14_2087"
          x1="146.785"
          y1="297.478"
          x2="293.966"
          y2="351.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_14_2087"
          x1="147.581"
          y1="295.294"
          x2="294.761"
          y2="348.927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_14_2087"
          x1="148.387"
          y1="293.091"
          x2="295.559"
          y2="346.724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_14_2087"
          x1="149.387"
          y1="290.342"
          x2="296.559"
          y2="343.967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_14_2087"
          x1="158.854"
          y1="264.344"
          x2="306.026"
          y2="317.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset={1} stopColor="#F37560" />
        </linearGradient>
      </defs>

      {pathsRight.map((path) => (
        <Path
          path={path}
          key={path.id}
          setDefaultActive={setDefaultActive}
          defaultActive={defaultActive}
        />
      ))}

      {/* белая подложка правого круга */}
      <path
        d="M973.18 378.444C1015.11 378.444 1049.1 344.361 1049.1 302.318C1049.1 260.274 1015.11 226.191 973.18 226.191C931.252 226.191 897.263 260.274 897.263 302.318C897.263 344.361 931.252 378.444 973.18 378.444Z"
        fill="#EDEDED"
        stroke="#E52A1B"
        strokeWidth="3.46975"
        strokeMiterlimit="10"
      />
      {/* NPT градиент в правом круге */}
      <path
        d="M939.222 320.582H937.527C937.423 320.582 937.328 320.669 937.328 320.782V334.244L928.184 320.677C928.149 320.625 928.089 320.591 928.02 320.591H925.727C925.623 320.591 925.528 320.677 925.528 320.79V337.792C925.528 337.896 925.615 337.992 925.727 337.992H927.423C927.527 337.992 927.622 337.905 927.622 337.792V323.488L937.362 337.905C937.397 337.957 937.457 337.992 937.527 337.992H939.222C939.326 337.992 939.421 337.905 939.421 337.792V320.79C939.421 320.686 939.335 320.591 939.222 320.591V320.582Z"
        fill="url(#paint0_linear_101_1060)"
      />
      <path
        d="M953.183 321.533C952.742 321.256 952.258 321.039 951.764 320.9C951.271 320.77 950.77 320.683 950.259 320.64C949.766 320.605 949.187 320.579 948.538 320.57H944.083C943.979 320.57 943.884 320.657 943.884 320.77V337.772C943.884 337.876 943.97 337.971 944.083 337.971H945.778C945.882 337.971 945.977 337.884 945.977 337.772V331.11H948.97C949.913 331.11 950.752 331.032 951.47 330.884C952.197 330.728 952.88 330.424 953.495 329.973C954.827 329.002 955.501 327.623 955.501 325.879C955.501 324.986 955.303 324.144 954.913 323.389C954.515 322.626 953.936 322.002 953.192 321.533H953.183ZM945.977 322.513H947.967C948.088 322.513 948.278 322.513 948.538 322.505C948.789 322.505 948.979 322.496 949.109 322.496C950.052 322.496 950.822 322.626 951.418 322.878C952.041 323.138 952.534 323.528 952.88 324.049C953.226 324.569 953.408 325.185 953.408 325.87C953.408 326.408 953.278 326.929 953.036 327.406C952.794 327.883 952.448 328.264 952.007 328.533C951.565 328.811 951.09 328.984 950.588 329.063C950.069 329.149 949.429 329.193 948.702 329.193H945.977V322.522V322.513Z"
        fill="url(#paint1_linear_101_1060)"
      />
      <path
        d="M966.914 320.582H957.468C957.364 320.582 957.269 320.669 957.269 320.782V322.326C957.269 322.43 957.355 322.525 957.468 322.525H961.17V337.792C961.17 337.896 961.256 337.992 961.369 337.992H963.064C963.168 337.992 963.263 337.905 963.263 337.792V322.525H966.923C967.026 322.525 967.122 322.438 967.122 322.326V320.782C967.122 320.677 967.035 320.582 966.923 320.582H966.914Z"
        fill="url(#paint2_linear_101_1060)"
      />
      <path
        d="M972.992 330.979C972.801 330.857 972.594 330.762 972.377 330.701C972.161 330.641 971.953 330.606 971.737 330.589C971.53 330.571 971.287 330.562 971.019 330.562H969.185C969.081 330.562 968.986 330.649 968.986 330.762V337.771C968.986 337.875 969.073 337.97 969.185 337.97H969.886C969.99 337.97 970.085 337.884 970.085 337.771V335.143H971.201C971.599 335.143 971.953 335.108 972.256 335.047C972.568 334.978 972.87 334.848 973.13 334.648C973.71 334.223 974.004 333.625 974.004 332.87C974.004 332.48 973.917 332.115 973.744 331.786C973.571 331.456 973.32 331.178 972.992 330.97V330.979ZM970.076 331.595H970.777C970.829 331.595 970.907 331.595 971.019 331.595C971.123 331.595 971.201 331.595 971.253 331.595C971.625 331.595 971.928 331.647 972.161 331.742C972.403 331.838 972.576 331.985 972.715 332.185C972.844 332.384 972.914 332.61 972.914 332.87C972.914 333.069 972.87 333.269 972.775 333.451C972.68 333.633 972.559 333.772 972.395 333.867C972.23 333.972 972.04 334.041 971.85 334.076C971.642 334.11 971.383 334.128 971.088 334.128H970.085V331.612L970.076 331.595Z"
        fill="url(#paint3_linear_101_1060)"
      />
      <path
        d="M978.924 336.95H976.225V330.774C976.225 330.67 976.138 330.574 976.026 330.574H975.325C975.221 330.574 975.126 330.661 975.126 330.774V337.783C975.126 337.887 975.212 337.982 975.325 337.982H978.924C979.027 337.982 979.123 337.895 979.123 337.783V337.149C979.123 337.045 979.036 336.95 978.924 336.95Z"
        fill="url(#paint4_linear_101_1060)"
      />
      <path
        d="M983.634 330.696C983.599 330.626 983.53 330.574 983.452 330.574H982.674C982.596 330.574 982.527 330.618 982.492 330.696L979.473 337.705C979.447 337.765 979.456 337.835 979.49 337.896C979.525 337.956 979.586 337.982 979.655 337.982H980.416C980.494 337.982 980.563 337.939 980.598 337.861L981.558 335.632H984.551L985.511 337.861C985.546 337.93 985.615 337.982 985.693 337.982H986.454C986.523 337.982 986.584 337.948 986.618 337.896C986.653 337.843 986.662 337.765 986.636 337.705L983.643 330.696H983.634ZM984.11 334.599H981.999L983.063 332.084L984.11 334.599Z"
        fill="url(#paint5_linear_101_1060)"
      />
      <path
        d="M991.084 330.574H987.191C987.087 330.574 986.992 330.661 986.992 330.774V331.407C986.992 331.511 987.079 331.606 987.191 331.606H988.601V337.783C988.601 337.887 988.688 337.982 988.8 337.982H989.501C989.605 337.982 989.7 337.895 989.7 337.783V331.606H991.093C991.196 331.606 991.292 331.52 991.292 331.407V330.774C991.292 330.67 991.205 330.574 991.093 330.574H991.084Z"
        fill="url(#paint6_linear_101_1060)"
      />
      <path
        d="M995.981 330.571H992.434C992.33 330.571 992.235 330.658 992.235 330.771V337.78C992.235 337.884 992.322 337.979 992.434 337.979H993.135C993.239 337.979 993.334 337.892 993.334 337.78V334.77H995.877C995.981 334.77 996.076 334.683 996.076 334.57V333.937C996.076 333.833 995.99 333.737 995.877 333.737H993.334V331.595H995.981C996.085 331.595 996.18 331.508 996.18 331.395V330.762C996.18 330.658 996.093 330.562 995.981 330.562V330.571Z"
        fill="url(#paint7_linear_101_1060)"
      />
      <path
        d="M1002.9 330.972C1002.3 330.634 1001.66 330.469 1000.98 330.469C1000.31 330.469 999.64 330.642 999.043 330.981C998.446 331.319 997.97 331.787 997.624 332.377C997.278 332.967 997.097 333.618 997.097 334.311C997.097 335.005 997.278 335.639 997.633 336.22C997.988 336.801 998.472 337.261 999.06 337.599C999.657 337.937 1000.3 338.102 1000.97 338.102C1001.65 338.102 1002.31 337.929 1002.91 337.59C1003.51 337.252 1003.98 336.784 1004.34 336.194C1004.68 335.604 1004.86 334.953 1004.86 334.259C1004.86 333.566 1004.68 332.932 1004.33 332.351C1003.97 331.77 1003.49 331.31 1002.89 330.981L1002.9 330.972ZM1003.77 334.259C1003.77 334.641 1003.71 334.997 1003.56 335.335C1003.41 335.673 1003.21 335.968 1002.95 336.22C1002.69 336.471 1002.39 336.671 1002.05 336.818C1001.27 337.148 1000.37 337.096 999.597 336.662C999.164 336.419 998.818 336.081 998.559 335.665C998.299 335.248 998.169 334.797 998.169 334.303C998.169 333.808 998.299 333.34 998.55 332.915C998.801 332.49 999.147 332.152 999.579 331.9C999.995 331.657 1000.44 331.536 1000.92 331.527C1000.94 331.527 1000.95 331.527 1000.98 331.527C1001.47 331.527 1001.93 331.648 1002.36 331.891C1002.8 332.134 1003.14 332.473 1003.39 332.889C1003.65 333.305 1003.77 333.765 1003.77 334.251V334.259Z"
        fill="url(#paint8_linear_101_1060)"
      />
      <path
        d="M1011.01 334.191C1011.21 333.844 1011.31 333.462 1011.31 333.054C1011.31 332.612 1011.21 332.195 1011.01 331.831C1010.8 331.458 1010.51 331.163 1010.13 330.946C1009.84 330.79 1009.53 330.686 1009.19 330.643C1008.87 330.599 1008.49 330.582 1008.04 330.582H1006.38C1006.28 330.582 1006.18 330.669 1006.18 330.782V337.79C1006.18 337.895 1006.27 337.99 1006.38 337.99H1007.08C1007.18 337.99 1007.28 337.903 1007.28 337.79V331.614H1008.19C1008.54 331.614 1008.85 331.632 1009.07 331.666C1009.28 331.701 1009.47 331.779 1009.66 331.909C1009.83 332.031 1009.97 332.187 1010.07 332.386C1010.18 332.586 1010.23 332.794 1010.23 333.002C1010.23 333.28 1010.16 333.523 1010.02 333.739C1009.88 333.956 1009.69 334.121 1009.44 334.243C1009.26 334.312 1009.06 334.364 1008.85 334.381C1008.62 334.399 1008.33 334.416 1008 334.416H1007.79C1007.72 334.416 1007.65 334.459 1007.62 334.52C1007.58 334.581 1007.59 334.668 1007.63 334.728L1010 337.903C1010.04 337.955 1010.1 337.981 1010.16 337.981H1011.02C1011.09 337.981 1011.16 337.938 1011.2 337.869C1011.23 337.799 1011.22 337.721 1011.18 337.66L1009.42 335.318C1009.7 335.266 1009.95 335.171 1010.19 335.023C1010.54 334.815 1010.81 334.538 1011.02 334.191H1011.01Z"
        fill="url(#paint9_linear_101_1060)"
      />
      <path
        d="M1019.93 330.574H1018.85C1018.77 330.574 1018.7 330.618 1018.67 330.696L1016.31 336.239L1013.94 330.696C1013.9 330.626 1013.83 330.574 1013.76 330.574H1012.68C1012.57 330.574 1012.48 330.661 1012.48 330.774V337.783C1012.48 337.887 1012.56 337.982 1012.68 337.982H1013.38C1013.48 337.982 1013.58 337.895 1013.58 337.783V332.526L1015.82 337.861C1015.86 337.93 1015.93 337.982 1016.01 337.982H1016.6C1016.68 337.982 1016.75 337.939 1016.78 337.861L1019.03 332.526V337.783C1019.03 337.887 1019.12 337.982 1019.23 337.982H1019.93C1020.04 337.982 1020.13 337.895 1020.13 337.783V330.774C1020.13 330.67 1020.05 330.574 1019.93 330.574Z"
        fill="url(#paint10_linear_101_1060)"
      />
      <path
        d="M984.183 324.77L940.377 278.336H931.32L925.965 272.967V266.669H945.532L989.538 313.103H1009.24V271.908L1014.52 266.539H1020.8V324.77H984.174H984.183ZM931.32 313.606L925.965 308.237V281.919H932.245L937.6 287.288V313.606H931.32ZM987.946 307.205L956.389 273.886V269.376H968.75L1001.83 304.221V307.205H987.946Z"
        fill="url(#paint11_linear_101_1060)"
      />

      {/* бувы NPT правый круг */}
      <defs>
        <linearGradient
          id="paint0_linear_101_1060"
          x1="888.132"
          y1="313.035"
          x2="1035.31"
          y2="366.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_101_1060"
          x1="889.887"
          y1="308.166"
          x2="1037.06"
          y2="361.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_101_1060"
          x1="891.853"
          y1="302.791"
          x2="1039.02"
          y2="356.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_101_1060"
          x1="891.01"
          y1="305.112"
          x2="1038.18"
          y2="358.745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_101_1060"
          x1="891.596"
          y1="303.467"
          x2="1038.78"
          y2="357.091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_101_1060"
          x1="892.293"
          y1="301.567"
          x2="1039.46"
          y2="355.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_101_1060"
          x1="893.281"
          y1="298.861"
          x2="1040.46"
          y2="352.485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_101_1060"
          x1="893.783"
          y1="297.478"
          x2="1040.96"
          y2="351.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_101_1060"
          x1="894.579"
          y1="295.294"
          x2="1041.76"
          y2="348.927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_101_1060"
          x1="895.384"
          y1="293.091"
          x2="1042.56"
          y2="346.724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_101_1060"
          x1="896.386"
          y1="290.342"
          x2="1043.56"
          y2="343.967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_101_1060"
          x1="905.852"
          y1="264.344"
          x2="1053.02"
          y2="317.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE3324" />
          <stop offset="0.36" stopColor="#BE202E" />
          <stop offset="0.66" stopColor="#EE3324" />
          <stop offset="1" stopColor="#F37560" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default Circle;
