import { AppRoute } from "../../../constants";
import image from '../../../images/standardExtensions/video-plugin.png'
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";

const descriptionLinks = [{link: AppRoute.Edu, title: 'Техническое решение по размещению обучающих видеоматериалов '}];
const description = [
  'Расширение video-plugin предназначено для отображения видеоинформации (видеопроигрывателя) с целью размещения видеоуроков для пользователей платформы или интеграции данных от систем видеонаблюдения',
]

function VideoPlugin({openPopup}) {
  const route = window.location.pathname;
  return (
    <StandardExtensionsDescription route={route} title={'video-plugin'} 
    titleRu={'Отображение видеопотока'} 
    description={description}
    list={descriptionLinks}
    listTitle={'Технические решения с применением video-plugin:'}
    image={image}
    openPopup={openPopup}
  />
  );
}

export default VideoPlugin;
