import React from "react";
import { useState } from "react";
import { useRef } from "react";
import Slider from "react-slick";
import classEdit from "../../images/ImagePopup/CIM/classEdit.webm";
import addPredicate_edit from "../../images/ImagePopup/CIM/addPredicate_edit.webm";
import predicateEdit from "../../images/ImagePopup/CIM/editPredicate.webm";
import importCimTool from "../../images/ImagePopup/CIM/5_importCimTool.png";
import gost from "../../images/ImagePopup/CIM/6_gost.png";
import profileEditClass_edit from "../../images/ImagePopup/CIM/profileEditClass_edit.webm";
import { VideoSide } from "./VideoSide";

function CIMPopup({isOpen, width, descriptionDeveloper, padding}) {
  const settings = {
    dots: true,
    infinite: false,
    customPaging: (i) => <div className="custom-slick-dots">{i + 1}</div>,
  };

  const vidRef1 = useRef(null);
  const vidRef2 = useRef(null);
  const vidRef3 = useRef(null);
  const vidRef4 = useRef(null);
  const [isPlayButton, setIsPlayButton] = useState(true);
  const [active, setActive] = useState(0);

  const images = [
    {
      id: 1,
      type: "video",
      image: profileEditClass_edit,
      title: "Создавайте классы",
      vidRef: vidRef1,
    },
    {
      id: 2,
      type: "video",
      image: classEdit,
      title: "Редактируйте классы",
      vidRef: vidRef2,
    },
    {
      id: 3,
      type: "video",
      image: addPredicate_edit,
      vidRef: vidRef3,
      title: "Добавляйте атрибуты и ассоциации класса",
    },
    {
      id: 4,
      type: "video",
      image: predicateEdit,
      vidRef: vidRef4,
      title: "Редактируйте атрибуты и ассоциации класса",
    },
    {
      id: 6,
      type: "image",
      image: importCimTool,
      title:
        "Импортируйте профиль из сторонних приложений, поддерживающих CIM: CIM Tool, Enterprise Architect, и др.",
    },
    {
      id: 7,
      type: "image",
      image: gost,
      title:
        "Создавайте/импортируйте профиль с поддержкой серии стандартов ГОСТ Р 58651 и CIM (МЭК 61968, МЭК 61970)",
    },
  ];

  return (
    <div style={{ padding: padding ? padding : "0 40px 20px", width: width }}>
      <Slider {...settings} afterChange={(idx) => setActive(idx)}>
        {images.map((i) => {
          return (
            <div className={`popup-slider__container ${descriptionDeveloper} `} key={i.id}>
              <div>
                <h5 className="mb-4 popup-slider__image-title" style={{color: 'var(--white)'}}>{i.title}</h5>
                {i.type === "video" ? (
                  <VideoSide
                    isOpen={isOpen}
                    active={active === i.id}
                    vidRef={i.vidRef}
                    image={i.image}
                    setIsPlayButton={setIsPlayButton}
                    isPlayButton={isPlayButton}
                  />
                ) : (
                  <img
                    className="popup-slider__image"
                    src={i.image}
                    alt="задачи"
                  />
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default CIMPopup;
