import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from '../../../images/technicalSolutions/videoLessons.png'
import img2 from '../../../images/technicalSolutions/video-plugin2.png'

const functions = [
  { id: 1, title: "Доступ к обучающим материалам из любого устройства, имеющего доступ к ресурсу" },
  { id: 2, title: "Видеоплеер позволяет указывать тайм-коды в роликах, соответствующих конкретным темам. Данный функционал позволяет быстро находить необходимую информацию, не тратя время на просмотр всей видеозаписи" },
  { id: 3, title: "Удобный интерфейс позволяет быстро загружать материалы и сортировать их в различные каталоги в зависимости от их тематики" },
  { id: 4, title: "Практичность применения: возможность обращаться к видео в любой момент по необходимости" }
];

const extensions = [
  {
    link: AppRoute.VideoPlugin,
    title: "video-plugin",
  },
];

const images = [
  {
    img: img1,
    type: "image",
  },
  {
    type: "image",
    img: img2,
  },
];

function Edu() {
  return (
    <TechnicalSolutionsContent
    route={window.location.pathname}
    functions={functions}
    extensions={extensions}
    images={images}
    title="Размещение обучающих видеоматериалов"
    description="Решение предназначено для размещения в автоматизированной системе
    архива с видеозаписями, с помощью которых можно проводить обучение
    пользователей работе в автоматизированной системе без необходимости организации
    централизованных и время затратных уроков"
  />
  );
}

export default Edu;
