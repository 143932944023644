import React from "react";
import { useEffect } from "react";
import playB from "../../images/icons/playB.svg";

export function VideoSide({
  vidRef,
  isPlayButton,
  setIsPlayButton,
  image,
  active,
  isOpen,
  width,
}) {
  useEffect(() => {
    if (active && isOpen) {
      vidRef.current.pause();
      vidRef.current.currentTime = 0;
    } else {
      vidRef.current.pause();
      vidRef.current.currentTime = 0;
    }
  }, [vidRef, active, isOpen]);

  const handlePlay = () => {
    vidRef.current.play();
  };

  const handlePause = () => {
    vidRef.current.pause();
  };

  const setButtonPause = () => {
    setIsPlayButton(true);
  };

  const setButtonPlay = () => {
    setIsPlayButton(false);
  };

  const handleToggleVideo = () =>
    vidRef.current.paused ? handlePlay() : handlePause();

  return (
    <div className="video-container">
      <video
        onPause={setButtonPause}
        onPlay={setButtonPlay}
        ref={vidRef}
        controls
        className="popup-slider__image"
        style={{ maxWidth: width }}
        src={image}
      />
      <div className="video-container_button" onClick={handleToggleVideo}>
        <img
          className={`link ${
            isPlayButton ? "play-button__image_active" : "play-button__image"
          }`}
          src={playB}
          alt="кнопка запустить видео"
        />
      </div>
    </div>
  );
}
