import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import GOST from "../../../components/gost/GOST";
import Layout from "../../../components/Layout/Layout";
import { AppRoute, technicalSolutionsLinks } from "../../../constants";
import editor from '../../../images/technicalSolutions/uml-editor.svg'
import ModalContent from "../../services/implementation/ModalContent";
import PrevAndNextButtons from "../../../components/prevAndNextButtons/PrevAndNextButtons";

const functions = [
  "добавление пакетов классов информационной модели",
  "редактирование пакетов классов информационной модели",
  "удаление пакетов классов информационной модели",
  "добавление классов информационной модели",
  "редактирование классов информационной модели",
  "удаление классов информационной модели",
  "добавление атрибутов классов информационной модели",
  "редактирование атрибутов классов информационной модели",
  "удаление атрибутов классов информационной модели",
  "добавление отношений (ассоциаций, наследований) между классами информационной модели",
  "удаление отношений (ассоциаций, наследований) между классами информационной модели",
  "просмотр атрибутов классов информационной модели, в том числе классов типа enumeration и compound",
  "просмотр UML диаграммы классов с отображением отношений между классами",
  "выгрузка профиля информационной модели в формат RDF, формат XMI",
  "загрузка профиля информационной модели, созданного в стороннем инструменте для работы с информационными моделями",
];

const extensions = [
  {
    link: AppRoute.DeveloperPlugin,
    title: "developer-plugin",
  },
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

function Uml() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const route = window.location.pathname;

  function handleOpenModal(data) {
    setModalData(data);
    setShowModal(true);
  }

  return (
    <Layout>
      <main className="main">
        <GOST />
        <h1 className="mb-4 text-center">
          Создание диаграмм классов информационной модели с использованием UML
        </h1>
        <div className="align-start d-flex flex-column">
          <p>
            Решение предназначено для работы с профилем информационной модели с
            использованием{" "}
            <span
              className="text-links"
              onClick={() =>
                handleOpenModal({
                  modalName: "Uml",
                  top: "500px",
                  left: "calc(54% - 215px)",
                  right: "140px",
                })
              }
            >
              UML
            </span>
            {" "}диаграмм классов информационной модели
          </p>
          {showModal &&
            createPortal(
              <ModalContent
                onClose={() => setShowModal(false)}
                modalData={modalData}
              />,
              document.body
            )}
          <h5 className="mb-3 mt-3">Основные функции:</h5>
          <ul className="mb-4 description__list">
            {functions.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>

          <h5 className="mb-3 mt-3">
            Стандартные расширения, необходимые для реализации технического
            решения:
          </h5>
          <ul className="mb-4 description__list">
            {extensions.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                <Link to={i.link} className="text-links">
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
          <img src={editor} alt="пример применения" style={{ width: "100%" }} />
          <PrevAndNextButtons buttonName="решению" route={route} links={technicalSolutionsLinks}/>
        </div>

        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default Uml;
