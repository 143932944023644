import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";
import { AppRoute } from "../../../constants";
import video from "../../../images/ImagePopup/visualInformation/scSchemeEdit.webm";

const descriptionLinks = [
  {
    link: AppRoute.Opt,
    title: "Риск-ориентированная система учета телекоммуникационных активов",
  },
  {
    link: AppRoute.Gis,
    title: "Техническое решение по отображению объектов на ГИС",
  },
];

const description = [
  "Расширение map-plugin предназначено для создания интерактивных географических карт с возможностью отображения в корпоративной сети без доступа в интернет",
  "Пример применения map-plugin: редактор электрических схем энергорайонов с привязкой к географическим координатам и отображением подстанции и ЛЭП на карте:",
];

function MapPlugin() {
  const route = window.location.pathname;

  return (
    <StandardExtensionsDescription
      route={route}
      title={"map-plugin"}
      titleRu={"Создание интерактивных географических карт"}
      description={description}
      list={descriptionLinks}
      video={video}
      listTitle={"Технические решения с применением map-plugin:"}
    />
  );
}

export default MapPlugin;
