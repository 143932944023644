
import React from "react";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";
import { Link } from "react-router-dom";

const descriptionLi = [
  'Определение цели и средств обследования',
  'Определение методов проведения обследования',
  'Определение объема и границы обследования',
  'Составление характеристики объектов автоматизации и результатов их автоматизации',
  'Определение организационных границы внедряемой системы',
  'Определение оценочного количества пользователей внедряемой системы',
  'Определение ориентировочного количества объектов информационной модели внедряемой системы',
  'Определение результатов автоматизации бизнес-процессов',
  'Исследование существующих бизнес-процессов работы, подлежащих автоматизации',
  'Анализ существующих информационных систем и информационно-технологической инфраструктуры в части интеграции с внедряемой системой',
  'Определение порядка развертывания внедряемой системы',
  'Определение структуры и характеристик каналов связи, необходимых для внедрения системы',
  'Определение требований к обеспечению информационной безопасности',
  'Выводы о достаточности информационно - технологической инфраструктуры для реализации внедряемой системы',
  'Формирование обоснования необходимости разработки внедряемой системы',
  'Определение функций и задач внедряемой системы',
  'Оценка ожидаемых технико-экономических результатов создания внедряемой системы',
  'Разработка отчета о предпроектном обследовании, который содержит описание модели автоматизации исследованных бизнес-процессов',
  'Разработка блок-схем бизнес-процессов «Как есть»',
  'Разработка верхнеуровневой схемы функциональной архитектуры внедряемой системы',
  'Разработка схемы информационных потоков внедряемой системы',
];

function SurveyReport() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-5">Разработка отчета о предпроектном обследовании</h1>
        <div className="align-start">
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link className="text-links" to={AppRoute.Partners}>
              компаний партнеров
            </Link>
            , а также богатый{" "}
            <Link className="text-links" to={AppRoute.Implementation}>
              опыт внедрений
            </Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно осуществлять разработку
            эксплуатационной документации на автоматизированную систему.
          </p> */}
                    <p>
            Компетенция специалистов нашей компании и компаний партнеров, а также богатый{" "}
            <Link className="text-links" to={AppRoute.Implementation}>
              опыт внедрений
            </Link>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            максимально быстро и эффективно осуществлять разработку
            эксплуатационной документации на автоматизированную систему.
          </p>
          <h5 className="description__title">
            Предпроектное обследование (далее – Услуга) предоставляется в рамках
            договора на внедрение информационной системы или по отдельному
            договору на оказание Услуги, и включает в себя следующие этапы:
          </h5>
          <ul className="description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default SurveyReport;
