import { AppRoute } from "../../constants";
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
//import logo from "../../images/logo-with-text.svg";
import teleg from "../../images/icons/teleg.svg";
import tel from "../../images/icons/telephone.svg";

function isPreRendering() {
  return navigator.userAgent === 'ReactSnap';
};

let pdf = ''

if (!isPreRendering()) { 
  console.log(1)
  pdf = process.env.PUBLIC_URL + "/Руководство-разработчика-приложений.pdf";
}

const footerContacts = [
  {
    img: teleg,
    name: "Телеграм",
    link: "https://t.me/epsaspb",
  },
  {
    img: tel,
    name: "Контакты",
    link: "http://epsa-spb.ru/kontakty-2/",
  },
];

const footerAbout = [
  {
    name: "Презентация о платформе",
    link: "http://epsa-spb.ru/f/prezentaciya_npt_platform_2022.pdf",
  },
  {
    name: "Документация",
    link: pdf,
  },
  {
    name: "Регистрация в реестре отечественного ПО",
    link: "https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748",
  },
];

const footerService = [
  {
    name: "Внедрение",
    link: AppRoute.Implementation,
  },
  {
    name: "Техническая поддержка",
    link: AppRoute.TechnicalSupport,
  },
  {
    name: "Разработка отчета о предпроектном обследовании",
    link: AppRoute.SurveyReport,
  },
  {
    name: "Разработка технических требований",
    link: AppRoute.TechnicalRequirements,
  },
  {
    name: "Разработка проектной документации",
    link: AppRoute.ProjectDocumentation,
  },
  {
    name: "Разработка эксплуатационной документации",
    link: AppRoute.OperationalDocumentation,
  },
  {
    name: "Разработка программы и методики испытаний",
    link: AppRoute.TestProgramsAndMethods,
  },
  {
    name: "Научные разработки и исследования",
    link: AppRoute.ScientificDevelopmentAndResearch,
  },
];

// const footerLinks = [
//   // {
//   //   id: 1,
//   //   width: 'col',
//   //   title: "О компании",
//   //   links: [
//   //     {
//   //       img: teleg,
//   //       name: "Телеграмм",
//   //       link: "https://t.me/epsaspb",
//   //     },
//   //     {
//   //       img: tel,
//   //       name: "Контакты",
//   //       link: "http://epsa-spb.ru/kontakty-2/",
//   //     },
//   //   ],
//   // },
//   {
//     id: 2,
//     width: "260px",

//     title: "NPT Platform",
//     links: [
//       {
//         name: "Презентация о платформе",
//         link: "http://epsa-spb.ru/f/prezentaciya_npt_platform_2022.pdf",
//       },
//       {
//         name: "Документация",
//         link: pdf,
//       },
//       {
//         name: "Регистрация в реестре отечественного ПО",
//         link: "https://reestr.digital.gov.ru/reestr/309153/?sphrase_id=2401748",
//       },
//     ],
//   },
//   {
//     id: 3,
//     width: "300px",
//     title: "Услуги",
//     links: [
//       {
//         name: "Внедрение",
//         link: AppRoute.Implementation,
//       },
//       {
//         name: "Техническая поддержка",
//         link: AppRoute.TechnicalSupport,
//       },
//       {
//         name: "Разработка отчета о предпроектном обследовании",
//         link: AppRoute.SurveyReport,
//       },
//       {
//         name: "Разработка технических требований",
//         link: AppRoute.TechnicalRequirements,
//       },
//       {
//         name: "Разработка проектной документации",
//         link: AppRoute.ProjectDocumentation,
//       },
//       {
//         name: "Разработка эксплуатационной документации",
//         link: AppRoute.OperationalDocumentation,
//       },
//       {
//         name: "Разработка программы и методики испытаний",
//         link: AppRoute.TestProgramsAndMethods,
//       },
//       {
//         name: "Научные разработки и исследования",
//         link: AppRoute.ScientificDevelopmentAndResearch,
//       },
//     ],
//   },
// ];

// function Footer() {
//   return (
//     <footer className="footer">
//       <div className="row footer__container">
//         <div className="col p-0" style={{marginRight: "34px", minWidth: '310px', maxWidth: '400px'}}>
//         <h6 style={{ color: "var(--blue)", fontSize: '19px' }} className="mb-4">
//           О компании
//         </h6>
//         <ul className="footer__links d-flex flex-wrap">
//                   {footerContacts.map((link) => (
//                     <li className="footer__list " style={{marginRight: '20px'}} key={link.name}>
//                         <img
//                           src={link.img}
//                           alt={link.name}
//                           className="footer__link-img"
//                         />
//                        <Link to={link.link} className="link">{link.name}</Link>
//                       </li>
//                   ))}
//                 </ul>
//           <p className="footer__company-name">Изготовитель и разработчик:</p>
//           <Link to="http://npt.spb.ru/" className="text-links footer__company-description">ООО «Новые программные технологии» (ООО «НПТ»)</Link>
//           <p className="footer__company-name mt-4">Юридический адрес:</p>
//           <p className="footer__company-description">
//           194223, г.Санкт-Петербург, вн.тер.г. муниципальный округ Светлановское, ул Курчатова, д. 9, стр. 2, офис 233
//           </p>

//           {/* <a
//             href="http://www.epsa-spb.ru/"
//             rel="noopener noreferrer"
//             target="_blank"
//           >
//             <img src={logo} style={{ width: "73%" }} alt="Логотип" />
//           </a> */}
//         </div>
//         <div className="col">
//           <div className="row justify-content-end">
//             {footerLinks.map((i) => (
//               <div className='col' key={i.title} style={{maxWidth: i.width}}>
//                 <h6 style={{ color: "var(--blue)", fontSize: '19px' }} className="mb-4">
//                   {i.title}
//                 </h6>
//                 <ul className="footer__links">
//                   {i.links.map((link) => (
//                     <li className="footer__list" key={link.name} style={{minWidth: '310px'}}>
//                       {link.img && (
//                         <img
//                           src={link.img}
//                           alt={link.name}
//                           className="footer__link-img"
//                         />
//                       )}
//                       {i.id === 3 ?
//                       <Link to={link.link} className="link">{link.name}</Link> :
//                       <a
//                         className="link"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         href={link.link}
//                       >
//                         {link.name}
//                       </a>}
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//       <div style={{ background: "var(--background)" }}>
//         <p className="footer__caption">
//           ⓒ 2023 NPT Platform, Все права защищены
//         </p>
//       </div>
//     </footer>
//   );
// }

// export default Footer;

function Footer() {
  return (
    <footer className="footer">
      <div className="row footer__container">
        <div
          className="col mt-4"
          style={{ minWidth: "313px", maxWidth: "530px" }}
        >
          <h6
            style={{ color: "var(--blue)", fontSize: "19px" }}
            className="mb-4"
          >
            О компании
          </h6>
          <ul className="footer__links d-flex flex-wrap">
            {footerContacts.map((link) => (
              <li
                className="footer__list"
                style={{ marginRight: "20px" }}
                key={link.name}
              >
                <img
                  src={link.img}
                  alt={link.name}
                  className="footer__link-img"
                />
                <Link to={link.link} className="link">
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
          <p className="footer__company-name">Изготовитель и разработчик:</p>
          <Link
            to="http://npt.spb.ru/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-links footer__company-description"
          >
            ООО «Новые программные технологии» (ООО&nbsp;«НПТ»)
          </Link>
          <p className="footer__company-name mt-4">Юридический адрес:</p>
          <p className="footer__company-description">
            194223, Санкт-Петербург, вн.тер.г. муниципальный округ
            Светлановское, ул.&nbsp;Курчатова, д.&nbsp;9, стр.&nbsp;2,
            офис&nbsp;233
          </p>
        </div>
        <div className="col d-flex mt-4 footer__links-container">
          <div style={{ maxWidth: "260px" }}>
            <h6
              style={{ color: "var(--blue)", fontSize: "19px" }}
              className="mb-4"
            >
              NPT Platform
            </h6>
            <ul className="footer__links">
              {footerAbout.map((link) => (
                <li
                  className="footer__list"
                  key={link.name}
                  style={{ minWidth: "310px" }}
                >
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.link}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h6
              style={{ color: "var(--blue)", fontSize: "19px" }}
              className="mb-4"
            >
              Услуги
            </h6>
            <ul className="footer__links">
              {footerService.map((link) => (
                <li
                  className="footer__list"
                  key={link.name}
                  style={{ minWidth: "310px" }}
                >
                  <Link to={link.link} className="link">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div style={{ background: "var(--background)" }}>
        <p className="footer__caption">
          ⓒ 2023 NPT Platform, Все права защищены
        </p>
      </div>
    </footer>
  );
}

export default Footer;
