import React from "react";
import Layout from "../../../components/Layout/Layout";
import { AppRoute } from "../../../constants";
import { Link } from "react-router-dom";

const activityList = [
  "прикладные научные исследования, направленные на получение и применение новых знаний для решения технологических и инженерных проблем в области промышленности и электроэнергетики",
  "научные исследования в области разработки активных энергетических комплексов, системы электроснабжения с применением ВИЭ и накопителями электроэнергии",
  "исследования в области промышленных протоколов, применяемых в энергетике (МЭК 61850-8-1 (MMS, GOOSE), МЭК 61850-9-2 (SV), МЭК 61850-90-2, IEEE 1588 Precision Time Protocol (PTP) и др.)",
  "исследования в области проектирования цифровых подстанций с помощью положений стандарта МЭК 61850-6",
  "исследования в области информационного моделирования (серия отечественных стандартов ГОСТ Р 58651 и международных стандартов CIM (Common Information Model) МЭК 61970, МЭК 61968, МЭК 62325)",
];

const examplesList = [
  {
    id: 1,
    title:
      "разработка программно-аппаратного комплекса управления ветроэнергетической установкой",
  },
  {
    linkText:
      "разработка системы автоматизации проектирования объектов ветроэнергетики",
    link: AppRoute.EnergyFacilities,
    id: 2,
  },
  {
    firstTitle:
      "разработка программного продукта для проектирования цифровых подстанций с помощью положений стандарта МЭК 61850-6 ",
    id: 3,
    linkText: "(САПР SCADA Studio)",
    link: "https://reestr.digital.gov.ru/reestr/305144/?sphrase_id=2401720",
  },
  {
    id: 4,
    firstTitle: "разработка отечественного стандарта в области CIM: ",
    linkText: "ГОСТ Р 58651.10-2023",
    link: "https://protect.gost.ru/document1.aspx?control=31&baseC=6&page=1&month=3&year=2023&search=&id=251979",
    title:
      ". Единая энергетическая система и изолированно работающие энергосистемы. Информационная модель электроэнергетики. Профиль информационной модели устройств релейной защиты и автоматики",
  },
  {
    id: 5,
    firstTitle: "разработка программного комплекса ",
    title:
      ". В данном программно-техническом комплексе была выполнена автоматизация бизнес-процессов и расчетных задач сотрудников служб ПАО «Россети» (расчетов токов короткого замыкания, расчетов параметров срабатывания устройств РЗА) с применением информационной модели на базе отечественных и международных стандартов CIM",
    linkText: "ПТК «Эксплуатация»",
    link: AppRoute.Rosseti,
  },
  {
    id: 6,
    firstTitle:
      "разработка программного комплекса для выполнения проверки параметров локальной вычислительной сети электрической подстанции, параметров сообщений, передаваемых по сети на соответствие файлам настройки и стандарту ",
    linkText: "МЭК 61850",
    link: "https://fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2023667007&TypeFile=html",
  },
];

function ScientificDevelopmentAndResearch() {
  return (
    <Layout>
      <main className="main">
        <h1 className="mb-5">Научные разработки и исследования</h1>
        <div className="align-start">
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link className="text-links" to={AppRoute.Partners}>компаний партнеров</Link>, а также
            богатый опыт выполнения НИР и НИОКР, дает возможность максимально
            быстро и эффективно осуществлять исследования и разработки в рамках
            НИР и НИОКР по различным направлениям.
          </p> */}
          <p>
            Компетенция специалистов нашей компании и компаний партнеров, а
            также богатый опыт выполнения НИР и НИОКР, дает возможность
            максимально быстро и эффективно осуществлять исследования и
            разработки в рамках НИР и НИОКР по различным направлениям.
          </p>
          <p>
            Разработка отчетов о НИР и НИОКР осуществляется в соответствии с
            ГОСТ 7.32-2017 Система стандартов по информации, библиотечному и
            издательскому делу. Отчет о научно-исследовательской работе.
            Структура и правила оформления.
          </p>
          <h4 className="mt-4 mb-3">Научно-техническая деятельность</h4>
          <h5 className="description__title">
            Компания осуществляет научно-техническую деятельность по следующим
            направлениям:
          </h5>
          <ul style={{ paddingLeft: "25px", paddingRight: "25px" }}>
            {activityList.map((i) => (
              <li className="mb-1 description__circle-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <h4 className="mt-4 mb-3">Научно-техническая деятельность</h4>
          <ul style={{ paddingLeft: "25px", paddingRight: "25px" }}>
            {examplesList.map((i) => (
              <li className="mb-1 description__circle-li" key={i.id}>
                {i.firstTitle}
                {i.link && (
                  <Link className="text-links" to={i.link}>
                    {i.linkText}
                  </Link>
                )}
                {i.title}
              </li>
            ))}
          </ul>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default ScientificDevelopmentAndResearch;
