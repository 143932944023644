import { AppRoute } from "../../../constants";
import video from "../../../images/ImagePopup/visualInformation/oscilloscope.webm";
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";

const list = [
  {
    id: 1,
    title:
      "Просмотр осциллограмм, записанных РАС/устройствами РЗА, без предварительного выполнения операций по конфигурированию с возможностью:",
    description: [
      "выбора пользователем аналоговых и дискретных сигналов, отображаемых на осциллограмме аварийных событий;",
      "изменения пользователем порядка расположения каждого из аналоговых и дискретных сигналов на представленной осциллограмме аварийных событий посредством их индивидуального перемещения;",
      "изменения пользователем масштаба графического отображения аналоговых сигналов по оси времени (общее масштабирование) и по оси амплитуды (индивидуально или в группах);",
      "изменения пользователем полярности аналоговых или дискретных сигналов на отображаемой осциллограмме аварийных событий с индикацией изменения состояния полярности сигнала;",
      "автоматической группировки аналоговых сигналов или дискретных сигналов по заданным пользователем критериям в соответствии с требованиями приложения Б;",
      "автоматического отображения только дискретных сигналов, изменивших свое состояние, с возможностью выбора пользователем режима отображения дискретных сигналов: отображение всех дискретных сигналов или дискретных сигналов, изменивших свое состояние;",
      "выбора пользователем режима просмотра аналоговых сигналов от ТТ, ТН и ШОН в первичных и вторичных величинах;",
      "выбора пользователем режима просмотра значений аналоговых сигналов от ТТ, ТН и ШОН в мгновенных, действующих значениях или значениях первой гармоники;",
      "автоматического формирования линейных (фазных) напряжений (токов) из заданных пользователем соответствующих фазных (линейных) напряжений (токов) с представлением их в виде расчетных аналоговых сигналов;",
      'выполнения пользователем математических операций (например, сложение/вычитание, умножение) над зарегистрированными и расчетными аналоговыми сигналами (с возможностью их индивидуального масштабирования и выполнения математических операций над ними, например для формирования "фиктивного" сигнала вместо отсутствующего зарегистрированного аналогового сигнала одного из присоединений) с представлением их в виде расчетных аналоговых сигналов;',
      '"наложения" выбранных пользователем аналоговых или дискретных сигналов (с представлением их в виде отдельного канала в осциллограмме аварийных событий и возможностью редактирования свойств их отображения (например, выделением каждого из сигналов различными цветами));',
      "автоматического вычисления и отображения на осциллограмме аварийных событий симметричных составляющих аналоговых сигналов (прямая, обратная и нулевая последовательности);",
      "построения векторных диаграмм токов и напряжений (фазных, линейных, составляющих прямой, обратной и нулевой последовательностей);",
      "спектрального анализа (преобразование Фурье);",
    ],
  },
  {
    id: 2,
    title:
      "Автоматическую сборку в одну осциллограмму аварийных событий последовательности осциллограмм одного аварийного события, записанных одним автономным РАС",
  },
  {
    id: 3,
    title:
      "Совмещение пользователем осциллограмм по разным аварийным событиям, с сохранением всех функций по обработке данных",
  },
  {
    id: 4,
    title:
      "Возможность сохранения совмещенной осциллограммы аварийных событий (с пользовательскими настройками и разметкой) и ее дальнейшей обработки после считывания (в том числе другим пользователем на другом ПК)",
  },
  {
    id: 5,
    title:
      "Приведение осциллограмм аварийных событий с одного или разных автономных РАС/устройств РЗА к единой частоте дискретизации с возможностью изменения длительности полученной совмещенной осциллограммы аварийных событий по задаваемым пользователем границам. Единая частота дискретизации должна определяться минимальной частотой дискретизации от всех регистраторов, выводимых для просмотра",
  },
  {
    id: 6,
    title: "Представление пользователю информации об аварийном событии:",
    description: [
      "дата и время пуска;",
      "информация о пуске;",
      "длительность аварийного режима записи;",
      "длительность осциллограммы аварийных событий.",
    ],
  },
];

const descriptionLinks = [
  {
    link: AppRoute.Oscl,
    title: "Техническое решение по отображению осциллограмм",
  },
];

const description = [
  "Расширение oscilloscope-plugin предназначено для реализации в браузере функционала просмотровщика осциллограмм в соответствии с ГОСТ Р 58601-2019 Единая энергетическая система и изолированно работающие энергосистемы. Оперативно-диспетчерское управление. Релейная защита и автоматика. Автономные регистраторы аварийных событий. Нормы и требования. п. 9.13 Требования к программному обеспечению для обработки и анализа данных регистратора аварийных событий",
];

function OscilloscopePlugin() {
  const route = window.location.pathname;

  return (
    <StandardExtensionsDescription
      route={route}
      title={"oscilloscope-plugin"}
      titleRu={"Отображение осциллограмм"}
      description={description}
      list={descriptionLinks}
      listTitle={"Технические решения с применением oscilloscope-plugin:"}
      video={video}
    >
      <h5 className="mb-3 mt-3"> Основные функции: </h5>
      <ul className="description__list">
        {list.map((i) => (
          <li className="mb-3 description__number-li " key={i.id}>
            {i.title}
            {i.description && (
              <ol>
                {i.description.map((i) => (
                  <li key={i} className="description__circle-li mb-1">
                    {i}
                  </li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ul>
    </StandardExtensionsDescription>
  );
}

export default OscilloscopePlugin;
