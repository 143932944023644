import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from '../../../images/technicalSolutions/Pf1.png'
import img2 from '../../../images/technicalSolutions/Pf2.png'
import img3 from '../../../images/technicalSolutions/Pf3.png'
import img4 from '../../../images/technicalSolutions/Pf4.png'

// const functions = [
//   'Ведение первичного оборудования в древовидной структуре',
//   'Создание, редактирование, графическая визуализация схем подстанций с указанием силового оборудования',
//   'Ведение каталога схем подстанций',
//   'Ведение линий электропередачи и их участков в древовидной структуре',
//   'Ведение справочников для формирования параметров первичного оборудования: асинхронные и синхронные машины, продольные и поперечные батареи статических конденсаторов, реакторы заземления нейтрали, силовые трансформаторы, токоограничивающие и шунтирующие реакторы, оборудование линий электропередачи',
//   'Добавление эквивалентных ветвей, генераторов, нагрузки и шунтов вручную',
//   'Создание схем сети для расчета параметров установившегося режима как на основе созданных схем подстанций, так и на основе эквивалентных ветвей, генерации, нагрузки, шунтов и узлов',
//   'Редактирование, графическая визуализация схем сети для расчета параметров установившегося режима с указанием силового оборудования, линий электропередачи, эквивалентов и внешних связей',
//   'Ведение каталога схем для расчета параметров установившегося режима',
//   'Выбор и настройка расчетных режимов для параметров установившегося режима',
//   'Расчет параметров установившегося режима (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)',
//   'Отображение результатов расчета параметров установившегося режима (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)',
// ];


const images = [
  {
    title: 'Табличная форма с элементами схемы замещения, входящими в схему',
    img: img1,
    type: "image",
  },
  {
    title: 'Табличная форма силового оборудования, входящего в схему',
    img: img2,
    type: "image",
  },
  {
    title: 'Графическая визуализация схемы сети',
    img: img3,
    type: "image",
  },
  {
    title: 'Отображение результатов расчета на графике',
    img: img4,
    type: "image",
  },
];

const functions = [
  {id: 1, title: "Ведение первичного оборудования в древовидной структуре"},
  {id: 2, title: "Ведение линий электропередачи и их участков в древовидной структуре"},
  {id: 3, title: "Создание, редактирование, графическая визуализация схем любых объектов ЭЭС (подстанций, электрических станций и др.) с указанием силового оборудования"},
  {id: 4, title: "Ведение каталога схем объектов ЭЭС"},
  {id: 5, title: "Ведение справочников для формирования параметров первичного оборудования: асинхронные и синхронные машины, продольные и поперечные батареи статических конденсаторов, реакторы заземления нейтрали, силовые трансформаторы, токоограничивающие и шунтирующие реакторы, оборудование линий электропередачи и др."},
  {id: 6, title: "Создание схем сети с привязкой к любому объекту ЭЭС"},
  {id: 7, title: "Загрузка моделей из сторонних программных комплексов для добавления в схему сети как в формате CIM RDF/XML, так и в любом другом (RastrWin, АРМ СРЗА и др.)"},
  {id: 8, title: "Хранение как исходных файлов моделей из сторонних источников, так и преобразованных в формат CIM RDF/XML"},
  {id: 9, title: "Добавление эквивалентных ветвей, генераторов, нагрузки и шунтов вручную в схеме сети"},
  {id: 10, 
    description: [
      'предварительно созданные в системе модели объектов (подстанции, ЛЭП, станции и др.)',
      'элементы, созданные вручную (эквивалентные ветви, шунты, генерация/нагрузка, узлы)',
      'модели, загруженные извне (RastrWin, АРМ СРЗА и др.)'
    ],
    title: "Гибкое формирование схем сети для расчета параметров режимов, позволяющее включать в схему следующие компоненты:"},
  {id: 11, title: "Отображение в табличном виде элементов схемы замещения сети"},
  {id: 12, title: "Отображение в табличном виде единиц силового оборудования по категориям (силовые трансформаторы, участки ЛЭП, компенсирующее оборудование, коммутационные аппараты)"},
  {id: 13, title: "Отображение в табличном виде выделенных районов добавленных в схему компонентов по базовым напряжениям"},
  {id: 14, title: "Возможность создания графической визуализации схемы сети с указанием силового оборудования, ЛЭП, эквивалентов и внешних связей"},
  {id: 15, title: "Ведение каталога схем для расчета параметров режимов"},
  {id: 16, title: "Выбор и настройка расчетных режимов для расчета параметров"},
  {id: 17, title: "Расчет параметров установившегося режима (величины токов, активной и реактивной мощностей в ветвях, результирующее напряжение в узлах схемы сети)"},
  {id: 18, title:  "Отображение результатов расчета параметров установившегося режима в табличном виде и на графической форме (величины токов, активной и реактивной мощностей в ветвях, результирующее напряжение в узлах схемы сети)"}
]

const extensions = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

function Pf() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    extensions={extensions}
    images={images}
    route={window.location.pathname}
    title="Техническое решение по расчету параметров установившегося и послеаварийного режимов"
    description="Решение предназначено для создания произвольной электрической сети и последующего расчета параметров установившегося и послеаварийного режимов"
  />
  );
}

export default Pf;
