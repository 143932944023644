import indicators from "../../../images/ImagePopup/visualInformation/indicators.webm";
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";
import { AppRoute } from "../../../constants";

const descriptionLinks = [
  {
    link: AppRoute.Edu,
    title: "Витрина данных реального времени ",
  },
  {
    link: AppRoute.Edu,
    title: "Техническое решение по АСМ РЗА",
  },
  {
    link: AppRoute.Edu,
    title:
      "Техническое решение по управлению мероприятиями ТОиР первичного оборудования",
  },
  {
    link: AppRoute.Edu,
    title:
      "Техническое решение по ведению проектов строительства и реконструкции объектов ветроэнергетики",
  },
];
const description = [
  'Расширение dashboard-plugin предназначено для отображения аналитических данных в виде интерактивной витрины данных: индикаторы, графики, диаграммы, таблицы и прочее',
]

function DashboardPlugin() {
  const route = window.location.pathname;
  return (
    <StandardExtensionsDescription route={route} title={'dashboard-plugin'} titleRu={'Отображение статистических данных'}
      description={description}
      list={descriptionLinks}
      video={indicators}
      listTitle={'Технические решения с применением dashboard-plugin:'}
    />
  );
}

export default DashboardPlugin;
