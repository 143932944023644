import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import img1 from '../../../images/technicalSolutions/metering-1.png'
import img2 from '../../../images/technicalSolutions/metering-2.png'
import img3 from '../../../images/technicalSolutions/metering-3.png'
import img4 from '../../../images/technicalSolutions/metering-4.png'
import img5 from '../../../images/technicalSolutions/metering-5.png'
import img6 from '../../../images/technicalSolutions/metering-6.png'
import img7 from '../../../images/technicalSolutions/metering-7.png'
import img8 from '../../../images/technicalSolutions/metering-8.png'

const images = [
  {
    img: img1,
    type: "image",
  },
  {
    type: "image",
    img: img2,
  },
  {
    type: "image",
    img: img3,
  },
  {
    type: "image",
    img: img4,
  },
  {
    type: "image",
    img: img5,
  },
  {
    type: "image",
    img: img6,
  },
  {
    type: "image",
    img: img7,
  },
  {
    type: "image",
    img: img8,
  },
];

// const functions = [
//   "Возможность заполнения, редактирования и отображения данных по устройствам учета в удобных для чтения карточках",
//   "Возможность ассоциации устройства с первичным оборудованием, учет которого оно ведет",
//   "Возможность ведения типового оборудования и ассоциации с ним заведенного в базу данных оборудования, что позволяет ускорить процесс заполнения базы данных и быстрого редактирования общей для объектов информации",
//   "Возможность вывода сводной информации в табличном виде для наглядности",
// ];

const functions = [
  { id: 1, title: "Возможность вести и отображать данные по устройствам и системам учета в удобных карточках" },
  { id: 2, title: "Ведение комплексной информации по системам учета с указанием входящих в ее состав устройств" },
  { id: 3, title: "Возможность ассоциации устройства с измерительными трансформаторами тока, напряжения, датчиками для учета электроэнергии" },
  { id: 4, title: "Возможность ведения базы данных типового оборудования и ассоциации с ним экземпляров оборудования" },
  { id: 5, title: "Возможность оперативного доступа к информации об устройствах и системах учета" },
  { id: 6, title: "Возможность оперативно отслеживать точки коммерческого учета электроэнергии с привязкой к электрической сети" },
  { id: 7, title: "Возможность ведения данных по потребителям электрической энергии, создания перечня организаций, с которыми заключены договора" },
  { id: 8, title: "Возможность интеграции с системами АСКУЭ и АИИС КУЭ для получения измерений в системы мониторинга измерительных каналов (ПАМИ)" }, 
  { id: 9, title: "Оценка фактического состояния эффективности работы оборудования по отклонениям от плановых (нормативных / паспортных / проектных) показателей и выявление потенциала энергосбережения (мест с нерациональными потерями)"}
];

function Metering() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    images={images}
    route={window.location.pathname}
    title="Учет потребления электроэнергии и оценка энергоэффективности"
    description="Решение предназначено для создания и ведения структурированной базы устройств коммерческого учета и ее отображение в иерархическом дереве предприятия"
  />
  );
}

export default Metering;
