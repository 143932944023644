import React, { useEffect, useState } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppRoute } from "../../constants";
import Popup from "../Popup/Popup"
import MainPage from "../mainPage/MainPage"

// import Partners from "../../pages/partners/Partners";
import NewsItem from "../../pages/NewsItem/NewsItem"
import ElectricPower from "../../pages/ElectricPower/ElectricPower"
import ManufacturingIndustry from "../../pages/ManufacturingIndustry/ManufacturingIndustry"
import MiningIndustry from "../../pages/MiningIndustry/MiningIndustry";
import NotFound from "../../pages/NotFound/NotFound";
import News from "../../pages/News/News";
// import DemoForm from "../../pages/demoForm/DemoForm";

import Rosseti from "../../pages/implementationExamples/rosseti/Rosseti";
import RZA from "../../pages/implementationExamples/rza/RZA";
import Lenenergo from "../../pages/implementationExamples/lenenergo/Lenenergo";
import Transneft from "../../pages/implementationExamples/transneft/Transneft";
import ACM from "../../pages/implementationExamples/acm/ACM";
import TimeAttendanceSystem from "../../pages/implementationExamples/timeAttendanceSystem/TimeAttendanceSystem";
import Telecommunication from "../../pages/implementationExamples/telecommunication/Telecommunication";
import EnergyFacilities from "../../pages/implementationExamples/energyFacilities/EnergyFacilities";
import Production from "../../pages/implementationExamples/production/Production";

import Build from "../../pages/technicalSolutions/build/Build";
import Dm from "../../pages/technicalSolutions/dm/Dm";
import Edu from "../../pages/technicalSolutions/edu/Edu";
import Eqgen from "../../pages/technicalSolutions/eqgen/Eqgen";
import Eqhv from "../../pages/technicalSolutions/eqhv/Eqhv";
import Eqmv from "../../pages/technicalSolutions/eqmv/Eqmv";
import Gis from "../../pages/technicalSolutions/gis/Gis";
import Maintanance from "../../pages/technicalSolutions/maintanance/Maintanance";
import Metering from "../../pages/technicalSolutions/metering/Metering";
import Opt from "../../pages/technicalSolutions/opt/Opt";
import Oscl from "../../pages/technicalSolutions/oscl/Oscl";
import Pf from "../../pages/technicalSolutions/pf/Pf";
import Rpa from "../../pages/technicalSolutions/rpa/Rpa";
import Sc from "../../pages/technicalSolutions/sc/Sc";
import Se from "../../pages/technicalSolutions/se/Se";
import Uml from "../../pages/technicalSolutions/uml/Uml";
import Docs from "../../pages/technicalSolutions/docs/Docs";

import DeveloperPlugin from "../../pages/standardExtensions/developerPlugin/DeveloperPlugin";
import DashboardPlugin from "../../pages/standardExtensions/dashboardPlugin/DashboardPlugin";
import MessagePlugin from "../../pages/standardExtensions/messagePlugin/MessagePlugin";
import VideoPlugin from "../../pages/standardExtensions/videoPlugin/VideoPlugin";
import OscilloscopePlugin from "../../pages/standardExtensions/oscilloscopePlugin/OscilloscopePlugin";
import VectorPlugin from "../../pages/standardExtensions/vectorPlugin/VectorPlugin";
import MapPlugin from "../../pages/standardExtensions/mapPlugin/MapPlugin";
import ScadaPlugin from "../../pages/standardExtensions/scadaPlugin/ScadaPlugin";

import OperationalDocumentation from "../../pages/services/operationalDocumentation/OperationalDocumentation";
import ProjectDocumentation from "../../pages/services/projectDocumentation/ProjectDocumentation";
import ScientificDevelopmentAndResearch from "../../pages/services/scientificDevelopmentAndResearch/ScientificDevelopmentAndResearch";
import TechnicalRequirements from "../../pages/services/technicalRequirements/TechnicalRequirements";
import TechnicalSupport from "../../pages/services/technicalSupport/TechnicalSupport";
import TestProgramsAndMethods from "../../pages/services/testProgramsAndMethods/TestProgramsAndMethods";
import Implementation from "../../pages/services/implementation/Implementation";
import SurveyReport from "../../pages/services/surveyReport/SurveyReport";


function App() {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenImagePopup, setIsOpenImagePopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedImage, setSelectedImage] = useState({});

  function openImagePopup(image) {
    setIsOpenImagePopup(true);
    setSelectedImage(image)
  }

  function openPopup(cardId) {
    setIsOpenPopup(true);
    setSelectedCard(cardId);
  }

  if (isOpenPopup) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }

  useEffect(() => {
    function handelEscape(evt) {
      if (evt.key === "Escape") {
        closePopup();
      }
    }

    function handleClosePopups(evt) {
      if (
        evt.target.classList.contains("popup_opened") ||
        evt.target.classList.contains("popup__close-icon")
      ) {
        closePopup();
      }
    }

    document.addEventListener("mousedown", handleClosePopups);
    document.addEventListener("keydown", handelEscape);

    return () => {
      document.removeEventListener("keydown", handelEscape);
      document.removeEventListener("mousedown", handleClosePopups);
    };
  }, []);

  function closePopup() {
    setIsOpenPopup(false);
    setIsOpenImagePopup(false);
  }

  return (
    <>
      <Routes>
      
        <Route path={AppRoute.MainPage} element={<MainPage openPopup={openPopup} />} />
        <Route path={AppRoute.News} element={<News />} />
        <Route path={AppRoute.NewsItem} element={<NewsItem />} />
        <Route path={AppRoute.ElectricPower} element={<ElectricPower openPopup={openImagePopup}/>} />
        <Route path={AppRoute.MiningIndustry} element={<MiningIndustry openPopup={openImagePopup}/>} />
        <Route
          path={AppRoute.ManufacturingIndustry}
          element={<ManufacturingIndustry openPopup={openImagePopup}/>}
        />
        <Route path={AppRoute.Rosseti} element={<Rosseti openPopup={openImagePopup}/>} />
        <Route path={AppRoute.ACM} element={<ACM openPopup={openImagePopup}/>} />
        <Route path={AppRoute.Transneft} element={<Transneft openPopup={openImagePopup}/>} />
        <Route path={AppRoute.Lenenergo} element={<Lenenergo openPopup={openImagePopup}/>} />
        <Route path={AppRoute.RZA} element={<RZA openPopup={openImagePopup}/>} />
        {/* <Route
          path={AppRoute.Partners}
          element={<Partners openPopup={openImagePopup} />}
        /> */}
        <Route path={AppRoute.NotFound} element={<NotFound />} />
        <Route path={AppRoute.TimeAttendanceSystem} element={<TimeAttendanceSystem openPopup={openImagePopup}/>} />
        <Route path={AppRoute.Telecommunication} element={<Telecommunication openPopup={openImagePopup}/>} />
        <Route path={AppRoute.EnergyFacilities} element={<EnergyFacilities openPopup={openImagePopup}/>} />
        <Route path={AppRoute.Production} element={<Production openPopup={openImagePopup}/>} />
        <Route path={AppRoute.Build} element={<Build />} />
        <Route path={AppRoute.Dm} element={<Dm />} />
        <Route path={AppRoute.Docs} element={<Docs />} />
        <Route path={AppRoute.Edu} element={<Edu />} />
        <Route path={AppRoute.Eqgen} element={<Eqgen />} />
        <Route path={AppRoute.Eqhv} element={<Eqhv />} />
        <Route path={AppRoute.Eqmv} element={<Eqmv />} />
        <Route path={AppRoute.Gis} element={<Gis />} />
        <Route path={AppRoute.Maintanance} element={<Maintanance />} />
        <Route path={AppRoute.Metering} element={<Metering />} />
        <Route path={AppRoute.Opt} element={<Opt />} />
        <Route path={AppRoute.Oscl} element={<Oscl />} />
        <Route path={AppRoute.Pf} element={<Pf />} />
        <Route path={AppRoute.Rpa} element={<Rpa />} />
        <Route path={AppRoute.Sc} element={<Sc />} />
        <Route path={AppRoute.Se} element={<Se />} />
        <Route path={AppRoute.Uml} element={<Uml />} />
        <Route path={AppRoute.DeveloperPlugin} element={<DeveloperPlugin openPopup={openImagePopup}/>} />
        <Route path={AppRoute.VectorPlugin} element={<VectorPlugin />} />
        <Route path={AppRoute.ScadaPlugin} element={<ScadaPlugin openPopup={openImagePopup}/>} />
        <Route path={AppRoute.MapPlugin} element={<MapPlugin />} />
        <Route path={AppRoute.OscilloscopePlugin} element={<OscilloscopePlugin />} />
        <Route path={AppRoute.VideoPlugin} element={<VideoPlugin openPopup={openImagePopup}/>} />
        <Route path={AppRoute.MessagePlugin} element={<MessagePlugin openPopup={openImagePopup}/>} />
        <Route path={AppRoute.DashboardPlugin} element={<DashboardPlugin />} />
        <Route path={AppRoute.TestProgramsAndMethods} element={<TestProgramsAndMethods />} />
        <Route path={AppRoute.TechnicalSupport} element={<TechnicalSupport />} />
        <Route path={AppRoute.TechnicalRequirements} element={<TechnicalRequirements />} />
        <Route path={AppRoute.ScientificDevelopmentAndResearch} element={<ScientificDevelopmentAndResearch />} />
        <Route path={AppRoute.ProjectDocumentation} element={<ProjectDocumentation />} />
        <Route path={AppRoute.OperationalDocumentation} element={<OperationalDocumentation />} />
        <Route path={AppRoute.Implementation} element={<Implementation />} />
        <Route path={AppRoute.SurveyReport} element={<SurveyReport />} />
        {/* <Route path={AppRoute.DemoForm} element={<DemoForm />}   /> */}

      </Routes>
      <Popup card={selectedCard} isOpen={isOpenPopup} onClose={closePopup} />
      <Popup isOpen={isOpenImagePopup} onClose={closePopup} image={selectedImage}/>
    </>
  );
}

export default App;
