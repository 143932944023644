import React from "react";
import Title from "../Title/Title";
import "./InformationEnvironment.scss";
import triangle from "../../images/informationEnvironment/12.1.svg";

function InformationEnvironment() {
  return (
    <section className=" information-environment">
      <Title
        color={"orange"}
        title={"Единая информационная среда"}
        paragraph={
          "Ускорьте Ваши бизнес-процессы с готовыми решениями по автоматизации"
        }
      />
              <img
          src={triangle}
          alt="пирамида, внутри расписаны готовые решения по автоматизации"
          style={{width: '100%'}}
        />
      {/* <div className="container information-environment__row-container">
        <div className="row align-items-center" style={{ height: "100%" }} > 

   
        </div>
      </div> */}
    </section>
  );
}

export default InformationEnvironment;