import React from "react";
import { Link } from "react-router-dom";
import GOST from "../gost/GOST";
import Layout from "../Layout/Layout";
import { AppRoute, technicalSolutionsLinks } from "../../constants";
import './TechnicalSolutionsContent.scss';
import TechnicalSolutionSlider from "../technicalSolutionSlider/TechnicalSolutionSlider";
import PrevAndNextButtons from "../../components/prevAndNextButtons/PrevAndNextButtons";

function TechnicalSolutionsContent({
  extensions,
  functions,
  title,
  description,
  sliderTitle,
  images,
  centerHeight,
  video,
  route
}) {
  return (
    <Layout>
      <main className="main">
        <GOST />
        <h1 className="mb-4 text-center">{title}</h1>
        <div className="align-start d-flex flex-column">
          <p>{description}</p>
          <h5 className="mb-3 mt-3">Основные функции:</h5>
          <ul className="mb-4 description__list">
            {functions.map((i) => (
              <li className="mb-1 description__number-li" key={i.title}>
                {i.title}
                {i.description && <ol>
                {i.description.map((e) => (
              <li className="mb-1 description__circle-li" key={e}>
                {e}
              </li>
            ))}
          </ol>}
              </li>
            ))
          }
          </ul>
          {extensions ? (
            <>
              <h5 className="mb-3 mt-3">
                Стандартные расширения, необходимые для реализации технического
                решения:
              </h5>
              <ul className="mb-4 description__list">
                {extensions.map((i) => (
                  <li className="mb-1 description__number-li" key={i.title}>
                    <Link to={i.link} className="text-links">
                      {i.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <h5 className="mb-3 mt-3">Зависимостей от стандартных расширений нет</h5>
          )}
          {sliderTitle && <h5 className="mb-3 mt-3">{sliderTitle}</h5>}
        </div>
        {images && <TechnicalSolutionSlider images={images} centerHeight={centerHeight} video={video}/>}
        <PrevAndNextButtons buttonName="решению" route={route} links={technicalSolutionsLinks}/>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default TechnicalSolutionsContent;
