import React, { useEffect } from "react";
// import title from "../../images/lenenergo/title.png";
// import Parameters from "../../components/Parameters/Parameters";
// import image1 from "../../images/lenenergo/lenenergo_page-0001.jpg";
// import image2 from "../../images/lenenergo/lenenergo_page-0002.jpg";
import Layout from "../../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../constants";

const descriptionLi = [
  "компании, которые уже успели автоматизировать некоторые бизнес-процессы, настраивают интеграции между разнородными программными продуктами или внедряют их заново",
  "компании, которые еще не начали процесс автоматизации, выбирают единую платформу, на основе которой планируют автоматизировать большинство бизнес-процессов",
];

const descriptionList = [
  { id: 1, title: "Ведение НТД ", link: AppRoute.Docs },
  {
    id: 2,
    title: "Ведение проектов строительства и реконструкции объектов",
    link: AppRoute.Build,
  },
  { id: 3, title: "Витрина данных реального времени", link: AppRoute.Dm },
  { id: 4, title: "Отображение объектов на ГИС", link: AppRoute.Gis },
  { id: 5, title: "Отображение осциллограмм", link: AppRoute.Oscl },
  {
    id: 6,
    title: "Оценивание состояния установившегося режима сети",
    link: AppRoute.Se,
  },
  {
    id: 7,
    title: "Риск-ориентированная система учета активов",
    link: AppRoute.Opt,
  },
  {
    id: 8,
    title: "Управление мероприятиями ТОиР первичного оборудования",
    link: AppRoute.Maintanance,
  },
  {
    id: 9,
    title: "Учет потребления электроэнергии и оценка энергоэффективности",
    link: AppRoute.Metering,
  },
];

function ManufacturingIndustry({ openPopup }) {
  // const images = [image1, image2 ];

  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/", { state: { fromAutomationProcesses: true } });
    };
  }, []);

  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4 text-center">
          Технические решения для Обрабатывающей промышленности
        </h1>
        <div className="align-start d-flex flex-column">
          <p>
            Обрабатывающая промышленность — отрасль промышленности, в качестве
            сырья в которой используются продукты сельского хозяйства (хлопок,
            шерсть и т. д.) или добытые{" "}
            {
              <Link to={AppRoute.MiningIndustry} className="text-links">
                добывающей промышленностью
              </Link>
            }{" "}
            (нефть, природный газ, руда и т. д.), обрабатываемые вручную или с
            помощью машин. Она изготавливает готовые к употреблению и применению
            изделия, а также оборудование, машины и технику, предназначенные для
            эксплуатации
          </p>
          <p>
            В качестве драйверов роста рынка решений для цифровизации
            обрабатывающей промышленности можно выделить рост конкуренции и
            необходимость оптимизации издержек, продолжение поддержки со стороны
            государства цифровой трансформации и роста производительности труда,
            повышенные требования к управлению себестоимостью и качеством
            продукции, импортозамещение в государственном и оборонном секторе
          </p>
          <p>
            Осенью 2021 года государством был сделан очередной важный шаг к
            преодолению российской промышленностью отставания от зарубежных
            конкурентов и обретению импортонезависимости. В ноябре 2021 года
            Правительство утвердило распоряжение о стратегическом направлении
            цифровой трансформации обрабатывающей промышленности,
            скоординированном с госпрограммами и нацпроектами и утвержденном до
            2030 года. В связи с этим, естественным процессом является рост
            степени автоматизации бизнес-процессов
          </p>
          <h5 className="mb-3 mt-3">
            При этом можно отметить следующие тенденции автоматизации:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionLi.map((i) => (
              <li className="mb-1 description__number-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <p>
            NPT Platfrom позволяет выполнить автоматизацию бизнес-процессов
            обрабатывающих компаний, а также может выступать в качестве единого
            информационного окна с установленными четкими взаимосвязями с
            имеющимися информационными системами
          </p>
          <h5 className="mb-3 mt-3">
            Перечень типовых технических решений на базе NPT Platform для
            добывающих компаний:
          </h5>
          <ul className="mb-4 description__list">
            {descriptionList.map((i) => (
              <li className="mb-1 description__circle-li" key={i.id}>
                <Link to={i.link} className="text-links">
                  {i.title}
                </Link>
              </li>
            ))}
          </ul>
          <Link to={AppRoute.Implementation} className="text-links">
            Подробную информацию по внедрению решений по комплексной
            автоматизации бизнес-процессов добывающих компаний можно посмотреть
            в разделе «Внедрение»
          </Link>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
        {/* <Parameters
          title="Технические решения для Обрабатывающей промышленности"
          image={title}
          images={images}
          openPopup={openPopup}
        >
          <h5 className="mb-3">Основные функции:</h5>
          <ul className="description__list">
            {descriptionLi.map(i => (
              <li className="mb-1 description__number-li" key={i}>{i}</li>
            ))}
          </ul>
        </Parameters> */}
      </main>
    </Layout>
  );
}

export default ManufacturingIndustry;
