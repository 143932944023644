import React from "react";

function Article8() {
  return (
    <div className="mt-4 mb-4">
      <p>
        Фильм создан на основе демонстрации жизненного цикла РЗА с помощью
        программных комплексов: САПР «ЭК РЗА и АСУ ТП», ПТК «Эксплуатация» и ПТК
        «Приемка» с детальным показом всех этапов работы на примере создания ПС.
        Демонстрация проводилась 5 июля 2023 года в г. Москве в рамках VIII
        Международной научно-технической конференции «Развитие и повышение
        надежности распределительных электрических сетей»
      </p>
      <a href="http://www.epsa-spb.ru/speeches_and_reports/" className="text-links" rel="noopener noreferrer"
          target="_blank">(Ссылка на фильм)</a>
    </div>
  );
}
export default Article8;
