import React, { useRef, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import {
  AppRoute,
  implementationExamples,
  technicalSolutionsLinks,
} from "../../../constants";
import { Link } from "react-router-dom";
import ModalContent from "./ModalContent";
import { createPortal } from "react-dom";

const descriptionLi3 = [
  "написание программного кода, конфигурирование модулей системы (справочников, документов, журналов, отчетов и т.д.)",
  "построение базы данных",
  "разработка пользовательского интерфейса",
  "администрирование системы по правам пользователей",
  "настройка миграции движения информации при распределенных базах",
  "настройка механизмов перегрузки начальных данных",
];

const descriptionLi4 = [
  "предварительное ознакомление с программой на типовых конфигурациях",
  "общий обзор специализированной конфигурации для каждого модуля",
  "обучение пользователей (в группах и индивидуально) по плану",
  "обучение администратора сети Заказчика (при необходимости)",
  "дополнительное обучение в период ввода в эксплуатацию",
];

function Implementation() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const model = useRef();
  const platform = useRef();
  const equipment = useRef();
  const test = useRef();
  const data = useRef();
  const acceptance = useRef();
  const integration = useRef();
  const guarantee = useRef();

  const handleClick = (i) => {
    i.current.scrollIntoView({ behavior: "smooth" });
  };

  const descriptionLi = [
    {
      ref: model,
      title:
        "Построение модели бизнес-процессов и составление технического задания",
    },
    {
      ref: platform,
      title: "Конфигурирование платформы и составление технического проекта",
    },
    {
      ref: equipment,
      title: "Подготовка оборудования и системы к эксплуатации",
    },
    {
      ref: test,
      title:
        "Тестирование системы, обучение пользователей и разработка эксплуатационной документации",
    },
    {
      ref: data,
      title: "Перенос данных из существующих систем",
    },
    {
      ref: acceptance,
      title:
        "Проведение приемочных испытаний по разработанной программе и методике испытаний",
    },
    {
      ref: integration,
      title: "Системная интеграция и ввод программы в эксплуатацию",
    },
    {
      ref: guarantee,
      title: "Гарантийное обслуживание",
    },
  ];

  function handleOpenModal(data) {
    setModalData(data);
    setShowModal(true);
  }

  return (
    <Layout>
      <main className="main">
        <h1 className="mb-4" style={{ alignSelf: "center" }}>
          Внедрение
        </h1>
        <div className="align-start">
          <p>
            Существуют ситуации, когда типовые{" "}
            <span
              className="text-links"
              onClick={() =>
                handleOpenModal({
                  modalName: technicalSolutionsLinks,
                  top: "310px",
                  right: "40px",
                  left: "calc(54% - 215px)",
                })
              }
            >
              технические решения
            </span>{" "}
            слабо применимы или неприменимы вовсе, в этом случае необходимо
            создание нетиповых решений. Специалисты нашей компании или компаний
            партнеров используют уникальную платформу NPT Platform, позволяющую
            построить автоматизированную систему управления любой
            функциональности и сложности.
            {/* слабо применимы или неприменимы вовсе,
            в этом случае необходимо создание нетиповых решений. Специалисты
            нашей компании или{" "}
            <Link to={AppRoute.Partners} className="text-links">компаний партнеров</Link> используют
            уникальную платформу NPT Platform, позволяющую построить
            автоматизированную систему управления любой функциональности и
            сложности. */}
          </p>
          {showModal &&
            createPortal(
              <ModalContent
                onClose={() => setShowModal(false)}
                modalData={modalData}
              />,
              document.body
            )}
          {/* <p>
            Компетенция специалистов нашей компании и{" "}
            <Link to={AppRoute.Partners} className="text-links">компаний партнеров</Link>, а также{" "}
            <span className="text-links" onClick={() => handleOpenModal({modalName: implementationExamples, top: '400px', right: '30px', left: 'calc(65% - 299px)'})}>
              богатый опыт внедрений 
            </span>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            достичь поставленных заказчиком целей внедрения с минимальными
            усилиями, за минимальное время, максимально эффективно и
            функционально.
          </p> */}
          <p>
            Компетенция специалистов нашей компании и компаний партнеров , а
            также{" "}
            <span
              className="text-links"
              onClick={() =>
                handleOpenModal({
                  modalName: implementationExamples,
                  top: "400px",
                  right: "30px",
                  left: "calc(65% - 299px)",
                })
              }
            >
              богатый опыт внедрений
            </span>{" "}
            автоматизированных систем на базе NPT Platform, дает возможность
            достичь поставленных заказчиком целей внедрения с минимальными
            усилиями, за минимальное время, максимально эффективно и
            функционально.
          </p>
          <h5 className="description__title">
            Внедрение автоматизированных систем на базе NPT Platform (далее –
            систем) условно можно разделить на следующие этапы:
          </h5>
          <ul className="description__list">
            {descriptionLi.map((i) => (
              <li
                className="mb-1 description__number-li"
                onClick={() => handleClick(i.ref)}
                key={i.title}
              >
                {i.title}
              </li>
            ))}
          </ul>
          <h4 ref={model} className="mt-5 mb-3">
            Построение модели бизнес-процессов и составление технического
            задания
          </h4>
          <h5 className="description__title">
            В процессе моделирования решаются следующие задачи:
          </h5>
          <ul className="description__list">
            <li className="mb-1 description__circle-li">
              проводится предпроектное исследование, результатом которого
              является описание бизнес - процессов предприятия,{" "}
              <Link to={AppRoute.SurveyReport} className="text-links">
                отчет
              </Link>{" "}
              о модели автоматизации исследованных бизнес-процессов
            </li>
            <li className="mb-1 description__circle-li">
              <Link
                to={AppRoute.ScientificDevelopmentAndResearch}
                className="text-links"
              >
                при необходимости выполняются научно-исследовательские работы
                для оптимизации процессов Заказчика{" "}
              </Link>
            </li>
            <li className="mb-1 description__circle-li">
              составляется{" "}
              <Link to={AppRoute.TechnicalRequirements} className="text-links">
                техническое задание
              </Link>{" "}
              на конфигурирование платформы. В техническом задании содержится
              описание модулей системы, их функционального взаимодействия друг с
              другом, в полном объеме, для каждой конфигурации.
            </li>
          </ul>
          <h4 ref={platform} className="mt-5 mb-3">
            Конфигурирование системы
          </h4>
          <p>
            На этапе конфигурирования предоставляется уникальная услуга –
            разработка контрольного макета внедряемой системы.
          </p>
          <h5 className="description__title">
            При создании контрольного макета выполняются следующие работы:
          </h5>
          <ul className="description__list">
            {descriptionLi3.map((i) => (
              <li className="mb-1 description__circle-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <p>
            Этап конфигурирования завершается разработкой{" "}
            <Link className="text-links" to={AppRoute.ProjectDocumentation}>
              технического проекта
            </Link>{" "}
            , в котором отражается выполненная конфигурация системы и ее
            функционал.
          </p>

          <h4 className="mt-5 mb-3" ref={equipment}>
            Подготовка оборудования и системы к эксплуатации
          </h4>
          <p>
            На серверное оборудование, на котором производится внедрение,
            выполняется установка контрольного макета системы для тестирования и
            прохождения приемочных испытаний.
          </p>
          <p>
            До момента ввода в эксплуатацию системы происходит постоянная
            проверка готовности сети и серверного оборудования, на котором
            производится внедрение. При этом параметры серверных станций должны
            обеспечивать оптимальное функционирование системы.
          </p>
          <h4 className="mt-5 mb-3" ref={test}>
            Тестирование системы, обучение пользователей и разработка
            эксплуатационной документации
          </h4>
          <p>
            На всех этапах внедрения обязательно производится тестирование
            конфигурации Заказчика с целью отладки алгоритмов взаимодействия
            модулей внедряемой системы.
          </p>
          <p>
            Перед обучением пользователей выполняется разработка{" "}
            <Link className="text-links" to={AppRoute.OperationalDocumentation}>
              эксплуатационной документации
            </Link>{" "}
          </p>
          <p>
            Основные положения эксплуатационной документации и{" "}
            <Link className="text-links" to={AppRoute.Edu}>
              {" "}
              видеоуроки
            </Link>{" "}
            размещаются в разделе «Помощь» внедряемой системы.{" "}
          </p>
          <h5 className="description__title">
            Обучение пользователей программы производится в несколько этапов:
          </h5>
          <ul className="description__list">
            {descriptionLi4.map((i) => (
              <li className="mb-1 description__circle-li" key={i}>
                {i}
              </li>
            ))}
          </ul>
          <h4 className="mt-5 mb-3" ref={data}>
            Перенос данных из существующих систем
          </h4>
          <p>
            Перед проведением приемочных испытаний осуществляется первичный
            импорт данных из существующих систем, а также осуществляется
            первичный экспорт данных в системы, для которых внедряемая система
            будет являться мастер-системой.
          </p>
          <h4 className="mt-5 mb-3" ref={acceptance}>
            Проведение приемочных испытаний по разработанной программе и
            методике испытаний
          </h4>
          <p>
            Для ввода системы в эксплуатацию проводятся приемочные испытания по
            разработанной{" "}
            <Link className="text-links" to={AppRoute.TestProgramsAndMethods}>
              программе и методике испытаний.
            </Link>
          </p>
          <p>
            На этом этапе происходит уточнение требований Заказчика не
            глобального характера (подправка печатных форм, диалогов документов,
            удобства ввода информации и т.д.).
          </p>
          <h4 className="mt-5 mb-3" ref={integration}>
            Системная интеграция и ввод системы в эксплуатацию
          </h4>
          <p>
            Ввод системы в эксплуатацию производится в соответствии с
            утвержденным планом-графиком и включает в себя методологическую и
            техническую помощь специалистов в момент запуска системы.
          </p>
          <p>
            На этапе ввода в эксплуатацию осуществляется интеграция системы в
            контур Заказчика и запускается интеграция системы с другими
            информационными системами Заказчика.
          </p>
          <p>
            По каждому этапу внедрения сторонами подписывается акт приемки-сдачи
            работ.
          </p>
          <h4 className="mt-5 mb-3" ref={guarantee}>
            Гарантийное обслуживание
          </h4>
          <p>
            После ввода в систему в эксплуатацию осуществляется гарантийное
            обслуживание, срок которого устанавливается индивидуально для
            каждого Заказчика по договору внедрения. После завершения срока
            гарантийного обслуживания по отдельному договору могут
            предоставляться услуги{" "}
            <Link className="text-links" to={AppRoute.TechnicalSupport}>
              технической поддержки.
            </Link>
          </p>
        </div>
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}
export default Implementation;
