import React from "react";
import { AppRoute } from "../../../constants";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import manyYears from "../../../images/technicalSolutions/TOP-many-years.png";
import years from "../../../images/technicalSolutions/TOP-years.png";
import months from "../../../images/technicalSolutions/TOP-months.png";

const functions = [
  { id: 1, title: "Создание многолетнего/годового/месячного графика ТОиР, представленного в табличном виде" },
  { id: 2, title: "Добавление работ в многолетний/годовой/месячный график ТОиР" },
  { id: 3, title: "Контроль и перенос сроков работ в многолетнем/годовом/месячном графике ТОиР с использованием диаграммы Ганта" },
  { id: 4, title: "Согласование годового/месячного графика ТОиР" },
  { id: 5, title: "Закрытие работ в месячном графике ТОиР" },
  { id: 6, title: "Отслеживание сроков и результатов выполнения работ" }
];

const extensions = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

const images = [
  {
    title: "Многолетний график ТО РЗА",
    img: manyYears,
    type: "image",
  },
  {
    title: "Годовой график ТО РЗА",
    img: years,
    type: "image",
  },
  {
    title: "Месячный график ТО РЗА",
    img: months,
    type: "image",
  },
];

function Maintanance() {
  return (
    <TechnicalSolutionsContent
    functions={functions}
    images={images}
    route={window.location.pathname}
    sliderTitle={'Варианты применения:'}
    extensions={extensions}
    title="Техническое решение по управлению мероприятиями ТОиР первичного
    оборудования"
    description="Техническое решение предназначено для управления техническим
    обслуживанием и ремонтом объектов электроэнергетики: генерирующего и
    электротехнического оборудования электростанций, электротехнического
    оборудования подстанций, воздушных линий электропередач, зданий. Оно
    позволяет отслеживать сроки и результаты выполнения работ на
    техническое обслуживание первичного оборудования"
  />
  );
}

export default Maintanance;
